$color_1: #74788D;
$color_2: #2A313D;
$color_3: #12151C;
$color_4: #9597A9;
$color_5: #009ce7;
$color_6: #FC1313;
$color_7: #28283C;
$color_8: #333;
$color_9: #4C40ED;
$color_10: #888;
$color_11: #474648;
$color_12: #979797;
$color_13: #5E5873;
$color_14: #E4E4E9;
$color_15: #fff;
$color_16: #23ad44;
$color_17: #f05050;
$color_18: #009efb;
$color_19: #ffbc34;
$color_20: #7460ee;
$color_21: #757575;
$color_22: #2c1fe5;
$color_23: #27c24c;
$color_24: #212529;
$color_25: #ababab;
$color_26: #000000;
$color_27: #FF0000;
$color_28: #131523;
$color_29: #ff0000;
$color_30: #a6a6a6;
$color_31: #1db9aa;
$color_32: #2196f3;
$color_33: #e63c3c;
$color_34: #f39c12;
$color_35: #26af48;
$color_36: #c580ff;
$color_37: #283447;
$color_38: #9E9E9E;
$color_39: #a3a3a3;
$color_40: #F0142F;
$color_41: #FFFFFF;
$color_42: #9e9e9e;
$color_43: #ff9b44;
$color_44: #009AF4;
$color_45: #3F3F3F;
$color_46: #6c757d;
$color_47: #71717A;
$color_48: #040404;
$color_49: #0E82FD;
$color_50: #4c40ed;
$color_51: #FFC107;
$color_52: #007BFF;
$color_53: #1BA345;
$color_54: #FF4949;
$color_55: #bdbdbd;
$color_56: #989c9e;
$color_57: #f83f37;
$color_58: #222;
$color_59: #272751;
$color_60: #9b9b9b;
$color_61: #526484;
$color_62: red;
$color_63: #F44336;
$color_64: #272b41;
$color_65: #00e65b;
$color_66: #858585;
$color_67: #dedfe0;
$color_68: #FFCC08;
$color_69: #bbb;
$color_70: #5F648A;
$color_71: #797979;
$color_72: #00CC45;
$color_73: #a1a1a1;
$color_74: #838383;
$color_75: #333333;
$color_76: #7E84A3;
$color_77: #1B5A90;
$color_78: #C1CCDB;
$color_79: #8291A5;
$color_80: #4169E1;
$color_81: #8B8D97;
$color_82: #607D8B;
$color_83: #263238;
$color_84: #494545;
$color_85: #818181;
$color_86: #4F4F4F;
$color_87: #3C3C3C;
$color_88: #637381;
$color_89: #fabbdb;
$color_90: #2469EF;
$color_91: #e60073;
$color_92: #242424;
$color_93: #E0E0E0;
$color_94: #1B2559;
$color_95: #ffffff;
$color_96: #3F4254;
$color_97: #878A99;
$color_98: #A5A5A5;
$color_99: #000;
$color_100: #C4C4C4;
$color_101: #f09e9e;
$color_102: #18aefa;
$color_103: #EC1361;
$color_104: #A1A1A1;
$color_105: #8D8D8D;
$color_106: #8F9BBA;
$color_107: #929292;
$color_108: #455560;
$color_109: #878787;
$color_110: #9A9A9A;
$color_111: #848484;
$color_112: #34444C;
$color_113: #828282;
$color_114: #B1B1B1;
$color_115: #575757;
$color_116: #2c3038;
$color_117: #48E100;
$color_118: #c23616;
$color_119: #ABABAB;
$color_120: #A8A8A8;
$color_121: #9f9f9f;
$color_122: rgba(50, 65, 72, 0.4);
$color_123: #777;
$color_124: #2c80ff;
$color_125: #00d285;
$color_126: #495463;
$color_127: #7A7F9A;
$color_128: #888888;
$color_129: #959494;
$color_130: #585858;
$color_131: #8B8B8B;
$color_132: #8C8C8C;
$color_133: #FFBC35;
$color_134: #58C27D;
$color_135: #C2C9D1;
$color_136: #F82424;
$font-family_1: 'Roboto', sans-serif;
$font-family_2: "FontAwesome";
$font-family_3: "Font Awesome 5 Free";
$font-family_4: 'Font Awesome 5 Free';
$font-family_5: 'Poppins', sans-serif;
$font-family_6: "Font Awesome 6 Free";
$font-family_7: "Fontawesome";
$background-color_1: #fff;
$background-color_2: unset;
$background-color_3: rgba(0, 0, 0, 0.1);
$background-color_4: #f8f9fa;
$background-color_5: #f7f7f7;
$background-color_6: rgba(255, 255, 255, 0.3);
$background-color_7: rgba(235, 235, 235, 0.4);
$background-color_8: #4C40ED;
$background-color_9: #e60073;
$background-color_10: #27c24c;
$background-color_11: #23ad44;
$background-color_12: #009efb;
$background-color_13: #028ee1;
$background-color_14: #ffbc34;
$background-color_15: #e9ab2e;
$background-color_16: #f05050;
$background-color_17: #343a40;
$background-color_18: #ee3939;
$background-color_19: #9368e9;
$background-color_20: #2c1fe5;
$background-color_21: #f62d51;
$background-color_22: transparent;
$background-color_23: #007bff;
$background-color_24: #eee;
$background-color_25: rgba(2, 182, 179,0.12);
$background-color_26: rgba(17, 148, 247,0.12);
$background-color_27: rgba(242, 17, 54,0.12);
$background-color_28: rgba(255, 152, 0,0.12);
$background-color_29: rgba(15, 183, 107,0.12);
$background-color_30: rgba(197, 128, 255,0.12);
$background-color_31: rgba(40, 52, 71,0.12);
$background-color_32: #F9F9FB;
$background-color_33: #FFFFFF;
$background-color_34: #fafafa;
$background-color_35: #f5f5f5;
$background-color_36: #923564;
$background-color_37: #f9f9f9;
$background-color_38: rgba(0, 0, 0, 0.6);
$background-color_39: #080C18;
$background-color_40: white;
$background-color_41: #e0001a;
$background-color_42: #55ce63;
$background-color_43: #ccc;
$background-color_44: #f43f3f;
$background-color_45: #000000;
$background-color_46: #85084c;
$background-color_47: #e5e5e5;
$background-color_48: #00CC45;
$background-color_49: #d19eb7;
$background-color_50: #4169e1;
$background-color_51: #4c40ed;
$background-color_52: #0E82FD;
$background-color_53: #e4e4e4;
$background-color_54: #C4C4C4;
$background-color_55: #F3F3F5;
$background-color_56: rgba(0,0,0,0.1);
$background-color_57: #c23616;
$background-color_58: #47BC00;
$background-color_59: #EDEEF7;
$background-color_60: #f5f5f6;
$background-color_61: #0de0fe;
$border-color_1: #4C40ED;
$border-color_2: rgba(0, 0, 0, 0.1);
$border-color_3: transparent;
$border-color_4: rgba(0, 0, 0, 0.05);
$border-color_5: #23ad44;
$border-color_6: #27c24c;
$border-color_7: #028ee1;
$border-color_8: #009efb;
$border-color_9: #e9ab2e;
$border-color_10: #ffbc34;
$border-color_11: #ee3939;
$border-color_12: #f62d51;
$border-color_13: #f05050;
$border-color_14: #e6e6e6;
$border-color_15: #007bff;
$border-color_16: #ddd transparent transparent;
$border-color_17: transparent transparent #ddd;
$border-color_18: #9597A9;
$border-color_19: #131523;
$border-color_20: #00CC45;
$border-color_21: #fabbdb;
$border-color_22: transparent transparent #ffffff #ffffff;
$border-color_23: #e60073;
$border-color_24: #E5E5E5;
$border-color_25: #F3F3F3;
$border-color_26: #0de0fe;
$border-color_27: rgba(220, 220, 220, 0.3);
$border-color_28: #ccc;
$border-top-color_1: #ddd;
$border-top-color_2: #4C40ED;
$border-top-color_3: rgb(221, 221, 221);
$border-right-color_1: rgb(221, 221, 221);
$border-right-color_2: #4C40ED;
$border-bottom-color_1: #4C40ED;
$border-bottom-color_2: #ddd;
$border-bottom-color_3: rgb(221, 221, 221);
$border-left-color_1: rgb(221, 221, 221);

/*-----------------
	1. General
-----------------------*/
/*-----------------
	2. Table
-----------------------*/
/*-----------------
	3. Bootstrap Classes
-----------------------*/
/*-----------------
	4. Select2
-----------------------*/
/*-----------------
	5. Nav Tabs
-----------------------*/
/*-----------------
	6. Components
-----------------------*/
/*-----------------
	7. Avatar
-----------------------*/
/*-----------------
	8. Header
-----------------------*/

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;0,900;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');
@keyframes heartbit {
	0% {
		transform: scale(0);
		opacity: 0;
	}
	25% {
		transform: scale(0.1);
		opacity: .1;
	}
	50% {
		transform: scale(0.5);
		opacity: .3;
	}
	75% {
		transform: scale(0.8);
		opacity: .5;
	}
	100% {
		transform: scale(1);
		opacity: 0;
	}
}
/*-----------------
	9. Sidebar
-----------------------*/
/*-----------------
	10. Content
-----------------------*/
/*-----------------
	11. Toggle Button
-----------------------*/
/*-----------------
	12. Login
-----------------------*/
/*-----------------
	13. Notifications
-----------------------*/
/*-----------------
	14. Dashboard
-----------------------*/
/*-----------------
	15. Profile
-----------------------*/
/*-----------------
	16. Blog
-----------------------*/
/*-----------------
	17. Blog Details
-----------------------*/
/*-----------------
	18. Reports
-----------------------*/
/*-----------------
	19. Invoice
-----------------------*/
/*-----------------
	20. Pricing
-----------------------*/
/*-----------------
	21. Preloading
-----------------------*/
@keyframes loader {
	0% {
		transform: rotate(0deg);
	}
	25% {
		transform: rotate(180deg);
	}
	50% {
		transform: rotate(180deg);
	}
	75% {
		transform: rotate(360deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
@keyframes loader-inner {
	0% {
		height: 0%;
	}
	25% {
		height: 0%;
	}
	50% {
		height: 100%;
	}
	75% {
		height: 100%;
	}
	100% {
		height: 0%;
	}
}
/*-----------------
    22. Chat
-----------------------*/
@keyframes blink {
	50% {
		opacity: 1;
	}
}
/*-----------------
	23. View Service
-----------------------*/
/*-----------------
	24. Add Service
-----------------------*/
/*-----------------
	25. Bank  Transfer
-----------------------*/
/*-----------------
	26. Responsive
-----------------------*/
html {
	height: 100%;
	background-color: $background-color_1;
}
body {
	background-color: $background-color_1;
	color: $color_1;
	font-family: $font-family_1;
	font-size: 14px;
	height: 100%;
	overflow-x: hidden;
}
.main-wrapper {
	background-color: $background-color_1;
	color: $color_1;
	font-family: $font-family_1;
	font-size: 14px;
	height: 100%;
	overflow-x: hidden;
}
h1 {
	color: $color_2;
	font-family: $font-family_1;
	margin-top: 0;
}
h2 {
	color: $color_2;
	font-family: $font-family_1;
	margin-top: 0;
}
h3 {
	color: $color_2;
	font-family: $font-family_1;
	margin-top: 0;
}
h4 {
	color: $color_2;
	font-family: $font-family_1;
	margin-top: 0;
}
h5 {
	color: $color_2;
	font-family: $font-family_1;
	margin-top: 0;
}
h6 {
	color: $color_2;
	font-family: $font-family_1;
	margin-top: 0;
}
a {
	outline: none;
	text-decoration: none;
	color: $color_5;
	transition: all 0.4s ease;
	-moz-transition: all 0.4s ease;
	-o-transition: all 0.4s ease;
	-ms-transition: all 0.4s ease;
	-webkit-transition: all 0.4s ease;
	&:hover {
		outline: none;
		text-decoration: none;
	}
	&:active {
		outline: none;
		text-decoration: none;
	}
	&:focus {
		outline: none;
		text-decoration: none;
	}
}
input {
	&:-webkit-autofill {
		-webkit-box-shadow: 0 0 0px 1000px white inset !important;
		&:hover {
			-webkit-box-shadow: 0 0 0px 1000px white inset !important;
		}
		&:focus {
			-webkit-box-shadow: 0 0 0px 1000px white inset !important;
		}
	}
	transition: all 0.4s ease;
	-moz-transition: all 0.4s ease;
	-o-transition: all 0.4s ease;
	-ms-transition: all 0.4s ease;
	-webkit-transition: all 0.4s ease;
	outline: none;
	&:focus {
		outline: none;
	}
}
img {
	max-width: 100%;
}
ul {
	list-style: none;
	margin: 0;
	padding: 0;
}
.table-responsive {
	white-space: nowrap;
}

.table {
	color: $color_8;
	max-width: 100%;
	margin-bottom: 0;
	width: 100%;
	tbody {
		border: 1px solid #F1F1F1;
		tr {
			border-bottom: 1px solid #F1F1F1;
			&:hover {
				background: #eee;
			}
		}
	}
	>tbody {
		>tr {
			>th {
				border-top: 0;
				padding: 10px 8px;
			}
		}
	}
	th {
		white-space: nowrap;
		vertical-align: middle;
	}
	td {
		white-space: nowrap;
		vertical-align: middle;
		color: $color_1;
		font-size: 14px;
		font-weight: 400;
	}
	thead {
		tr {
			th {
				&:first-child {
					border-radius: 10px 0 0 0;
				}
				&:last-child {
					border-radius: 0 10px 0 0;
				}
				font-weight: 600;
				border: 0;
			}
		}
		th {
			padding: 16px;
			font-size: 16px;
			font-weight: 500;
			color: $color_7;
			background: #EDF3F9;
			vertical-align: bottom;
			border-bottom: 1px solid #dee2e6;
		}
	}
	.invoice-delete {
		a {
			color: $color_17;
		}
	}
	.bg-success-dark {
		background: #008F64;
	}
	.bg-danger-dark {
		background: #FF0000;
	}
}
.table>:not(caption)>*>* {
	padding: 15px;
	box-shadow: unset;
	background-color: unset;
}
.table>:not(:first-child) {
    border-top: 0;
}
label {
	display: inline-block;
	margin-bottom: 0.5rem;
}
.form-control {
	border: 1px solid #F3F3F3;
	box-shadow: none;
	color: $color_2;
	height: 46px;
	padding: 0 15px;
	border-radius: 8px;
	transition: all 0.5s;
	font-size: 14px;
	background: #F9F9FB;
	&:focus {
		border-color: $border-color_1;
		box-shadow: none;
		outline: 0 none;
	}
	&::-webkit-input-placeholder {
		color: $color_14;
	}
	&:-ms-input-placeholder {
		color: $color_14;
	}
	&::placeholder {
		color: $color_14;
	}
}
.form-grouphead {
	margin-bottom: 25px;
	h2 {
		font-size: 16px;
		font-weight: 500;
		color: $color_3;
	}
}
textarea.form-control {
	border: 1px solid #F3F3F3;
	box-shadow: none;
	color: $color_2;
	height: 135px;
	padding: 15px;
	border-radius: 8px;
	background: #F9F9FB;
	resize: vertical;
	height: 150px;
}
.table-content {
	h5 {
		font-size: 16px;
		font-weight: 500;
	}
	h6 {
		color: $color_4;
		font-size: 13px;
		margin: 0;
	}
}
.form-control.form-control-sm {
	height: calc(1.5em + .5rem + 2px);
}
.form-control.form-control-lg {
	height: calc(1.5em + 1rem + 2px);
}
button {
	transition: all 0.4s ease;
	-moz-transition: all 0.4s ease;
	-o-transition: all 0.4s ease;
	-ms-transition: all 0.4s ease;
	-webkit-transition: all 0.4s ease;
	outline: none;
	&:focus {
		outline: none;
	}
}
input[type="file"] {
	height: auto;
	min-height: calc(1.5em + .75rem + 2px);
}
input[type=text] {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}
input[type=password] {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}
.navbar-nav {
	>li {
		float: left;
	}
	.open {
		.dropdown-menu {
			border: 0;
			box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
			background-color: $background-color_1;
		}
	}
}
.btn {
	font-size: 14px;
	font-weight: 500;
	border-radius: 8px;
	padding: 10px 18px;
	transition: all 0.5s;
	&:focus {
		box-shadow: unset;
	}
}
.form-group {
	margin-bottom: 20px;
	label {
		span.manitory {
			color: $color_6;
			margin-left: 5px;
		}
		color: $color_7;
		font-size: 14px;
		margin-bottom: 8px;
		font-weight: 400;
		span {
			color: $color_1;
			font-size: 13px;
			margin-left: 0;
			font-weight: 400;
		}
	}
	.select2-container--default {
		.select2-selection--single {
			.select2-selection__rendered {
				padding-left: 35px;
				padding-left: 15px;
			}
		}
	}
	p {
		font-size: 14px;
		margin: 5px 0 0;
	}
	.group-image {
		.select2-container--default {
			.select2-selection--single {
				.select2-selection__rendered {
					padding-left: 35px;
				}
			}
		}
	}
	.contentpage {
		background: #EDF3F9;
		padding: 11px;
		font-size: 13px;
		font-weight: 400;
		border-radius: 8px;
		color: $color_1;
	}
	.ck.ck-editor__main {
		>.ck-editor__editable {
			&:not(.ck-focused) {
				border-color: $border-color_25;
				background: #F9F9FB;
			}
		}
	}
	.ck.ck-toolbar {
		border: 1px solid #f3f3f3;
		.ck.ck-toolbar__separator {
			background: transparent;
		}
	}
}
.input-group {
	.form-control {
		height: 40px;
	}
}
.nav {
	.open {
		>a {
			background-color: $background-color_3;
			border-color: $border-color_2;
			&:focus {
				background-color: $background-color_3;
				border-color: $border-color_2;
			}
			&:hover {
				background-color: $background-color_3;
				border-color: $border-color_2;
			}
		}
	}
}
.font-weight-600 {
	font-weight: 600;
}
div.dataTables_wrapper {
	div.dataTables_length {
		select {
			-webkit-appearance: auto;
			-moz-appearance: auto;
			appearance: auto;
		}
	}
	div.dataTables_paginate {
		ul.pagination {
			margin-top: 15px;
		}
	}
}
.table-striped {
	>tbody {
		>tr {
			&:nth-of-type(2n+1) {
				background-color: $background-color_4;
			}
		}
	}
	thead {
		tr {
			border-color: $border-color_3;
		}
	}
	tbody {
		tr {
			border-color: $border-color_3;
			&:nth-of-type(even) {
				background-color: $background-color_6;
			}
			&:nth-of-type(odd) {
				background-color: $background-color_7;
			}
		}
	}
}
.table.no-border {
	>tbody {
		>tr {
			>td {
				border-top: 0;
				padding: 10px 8px;
			}
		}
	}
	>tfoot {
		>tr {
			>td {
				border-top: 0;
				padding: 10px 8px;
			}
			>th {
				border-top: 0;
				padding: 10px 8px;
			}
		}
	}
	>thead {
		>tr {
			>td {
				border-top: 0;
				padding: 10px 8px;
			}
			>th {
				border-top: 0;
				padding: 10px 8px;
			}
		}
	}
}
.table-resposnive {
	overflow-x: auto;
}
.table-nowrap {
	td {
		white-space: nowrap;
	}
	th {
		white-space: nowrap;
	}
}
.table.dataTable {
	border-collapse: collapse !important;
	margin: 0 !important;
}
table.table {
	td {
		h2 {
			display: inline-block;
			font-size: inherit;
			font-weight: 400;
			margin: 0;
			padding: 0;
			vertical-align: middle;
			a {
				color: $color_8;
				&:hover {
					color: $color_9;
				}
			}
			span {
				color: $color_10;
				display: block;
				font-size: 12px;
				margin-top: 3px;
			}
		}
		h2.table-avatar {
			align-items: center;
			display: inline-flex;
			font-size: inherit;
			font-weight: 400;
			margin: 0;
			padding: 0;
			vertical-align: middle;
			white-space: nowrap;
		}
	}
}
.action-language {
	ul {
		display: flex;
		align-items: center;
		li {
			margin: 0 5px;
		}
	}
	.btn {
		padding: 5px 10px;
		min-width: 90px;
	}
	.btn-primary {
		&:hover {
			box-shadow: 0 0px 0 0 inset;
		}
	}
}
.btn-grey {
	background: #f5f5f5;
}
.table.table-center {
	td {
		vertical-align: middle;
	}
	th {
		vertical-align: middle;
	}
}
.table-bottom {
	margin-bottom: 40px;
}
.table-hover {
	tbody {
		tr {
			&:hover {
				background-color: $background-color_5;
				td {
					color: $color_11;
				}
			}
		}
	}
}
.table-bordered {
	border: 1px solid rgba(0, 0, 0, 0.05) !important;
	th {
		border-color: $border-color_4;
	}
	td {
		border-color: $border-color_4;
	}
}
.card-table {
	.card-body {
		padding: 0;
		.table {
			>thead {
				>tr {
					>th {
						border-top: 0;
					}
				}
			}
			tr {
				td {
					&:first-child {
						padding-left: 1.5rem;
					}
					&:last-child {
						padding-right: 1.5rem;
					}
				}
				th {
					&:first-child {
						padding-left: 1.5rem;
					}
					&:last-child {
						padding-right: 1.5rem;
					}
				}
			}
		}
	}
	.table {
		td {
			border-top: 1px solid #e2e5e8;
			padding: 1rem 0.75rem;
			white-space: nowrap;
		}
		th {
			border-top: 1px solid #e2e5e8;
			padding: 1rem 0.75rem;
			white-space: nowrap;
		}
	}
}
.actions {
	a {
		width: 85px;
	}
}
.cal-icon {
	position: relative;
	width: 100%;
	position: relative;
	width: 100%;
	&:after {
		color: $color_12;
		content: "\f073";
		display: block;
		font-family: $font-family_2;
		font-size: 15px;
		margin: auto;
		position: absolute;
		right: 15px;
		top: 10px;
		color: $color_12;
		content: "\f073";
		display: block;
		font-family: $font-family_3;
		font-weight: 500;
		font-size: 15px;
		margin: auto;
		position: absolute;
		right: 15px;
		top: 10px;
	}
}
.invoice-total-inner {
	.add-links {
		&:hover {
			color: $color_9;
		}
	}
	padding: 15px;
	.checktoggle {
		background-color: $background-color_54;
		border: 1px solid #C4C4C4;
		border-radius: 12px;
		cursor: pointer;
		font-size: 0;
		height: 15px;
		margin-left: 10px;
		top: 3px;
		margin-bottom: 0;
		position: absolute;
		width: 30px;
		display: inline-block;
		&:after {
			content: ' ';
			display: block;
			position: absolute;
			top: 50%;
			left: 0;
			transform: translate(5px, -50%);
			width: 8px;
			height: 8px;
			background-color: $background-color_1;
			border-radius: 50%;
			transition: left 300ms ease, transform 300ms ease;
		}
	}
}
.dataTables_info {
	float: right;
	padding-right: 15px;
	font-size: 12px;
	color: $color_13;
	font-weight: 600;
}
.dataTables_length {
	label {
		&:before {
			content: "Show";
			margin-right: 5px;
		}
	}
	margin-top: 15px !important;
	float: left;
	font-size: 12px;
}
.paging_numbers {
	float: right;
}
.login-content {
	padding-left: 50px;
}
.btn-block {
	width: 100%;
}
.btn-link {
	text-decoration: none;
	&:hover {
		text-decoration: underline;
	}
}
.btn.focus {
	box-shadow: unset;
}
.btn-white {
	background-color: $background-color_1;
	border: 1px solid #ccc;
	color: $color_8;
}
.btn.btn-rounded {
	border-radius: 50px;
}
.bg-primary {
	background-color: $background-color_8 !important;
}
.badge-primary {
	background-color: $background-color_8 !important;
}
a.bg-primary {
	&:focus {
		background-color: $background-color_9 !important;
	}
	&:hover {
		background-color: $background-color_9 !important;
	}
}
button.bg-primary {
	&:focus {
		background-color: $background-color_9 !important;
	}
	&:hover {
		background-color: $background-color_9 !important;
	}
}
.bg-success {
	background-color: $background-color_10 !important;
}
.badge-success {
	background-color: $background-color_10 !important;
}
a.bg-success {
	&:focus {
		background-color: $background-color_11 !important;
	}
	&:hover {
		background-color: $background-color_11 !important;
	}
}
button.bg-success {
	&:focus {
		background-color: $background-color_11 !important;
	}
	&:hover {
		background-color: $background-color_11 !important;
	}
}
.bg-info {
	background-color: $background-color_12 !important;
}
.badge-info {
	background-color: $background-color_12 !important;
}
a.bg-info {
	&:focus {
		background-color: $background-color_13 !important;
	}
	&:hover {
		background-color: $background-color_13 !important;
	}
}
button.bg-info {
	&:focus {
		background-color: $background-color_13 !important;
	}
	&:hover {
		background-color: $background-color_13 !important;
	}
}
.bg-warning {
	background-color: $background-color_14 !important;
}
.badge-warning {
	background-color: $background-color_14 !important;
}
a.bg-warning {
	&:focus {
		background-color: $background-color_15 !important;
	}
	&:hover {
		background-color: $background-color_15 !important;
	}
}
button.bg-warning {
	&:focus {
		background-color: $background-color_15 !important;
	}
	&:hover {
		background-color: $background-color_15 !important;
	}
}
.badge {
	display: inline-block;
	padding: 0.25em 0.4em;
	font-size: 75%;
	font-weight: 700;
	line-height: 1;
	text-align: center;
	white-space: nowrap;
	vertical-align: baseline;
	border-radius: 0.25rem;
}
.bg-danger {
	background-color: $background-color_16 !important;
	color: $color_8;
}
.badge-danger {
	background-color: $background-color_16 !important;
	color: $color_8;
}
.badge-dark {
	color: $color_15;
	background-color: $background-color_17;
}
a.bg-danger {
	&:focus {
		background-color: $background-color_18 !important;
	}
	&:hover {
		background-color: $background-color_18 !important;
	}
}
button.bg-danger {
	&:focus {
		background-color: $background-color_18 !important;
	}
	&:hover {
		background-color: $background-color_18 !important;
	}
}
.bg-white {
	background-color: $background-color_1;
}
.bg-purple {
	background-color: $background-color_19 !important;
}
.badge-purple {
	background-color: $background-color_19 !important;
}
.text-primary {
	color: $color_9 !important;
}
.dropdown-menu {
	>li {
		>a.text-primary {
			color: $color_9 !important;
		}
		>a.text-success {
			color: $color_16 !important;
		}
		>a.text-danger {
			color: $color_17 !important;
		}
		>a.text-info {
			color: $color_18 !important;
		}
		>a.text-warning {
			color: $color_19 !important;
		}
		>a.text-purple {
			color: $color_20 !important;
		}
	}
	border: 1px solid rgba(0, 0, 0, 0.1);
	border-radius: 3px;
	transform-origin: left top 0;
	box-shadow: inherit;
	background-color: $background-color_1;
	font-size: 14px;
}
.text-success {
	color: $color_16 !important;
}
.text-danger {
	color: $color_17 !important;
}
.text-info {
	color: $color_18 !important;
}
.text-warning {
	color: $color_19 !important;
}
.text-purple {
	color: $color_20 !important;
}
.text-muted {
	color: $color_21 !important;
}
.btn-primary {
	background-color: $background-color_8;
	border-color: $border-color_1;
	&:hover {
		background-color: $background-color_20;
		border: 2px solid #2c1fe5;
		box-shadow: 0 50px 0 0 #fff inset;
		color: $color_22;
	}
	&:focus {
		background-color: $background-color_20;
		border: 2px solid #2c1fe5;
	}
	&:active {
		background-color: $background-color_20;
		border: 2px solid #2c1fe5;
		&:focus {
			background: #4C40ED;
			border: 1px solid #4C40ED;
			&:not(:disabled) {
				&:not(.disabled) {
					box-shadow: unset;
				}
			}
		}
		&:hover {
			background: #4C40ED;
			border: 1px solid #4C40ED;
		}
		&:not(:disabled) {
			&:not(.disabled) {
				background: #4C40ED;
				border-color: $border-color_1;
				color: $color_15;
			}
		}
	}
	&:disabled {
		background-color: $background-color_8;
		border-color: $border-color_1;
		color: $color_15;
	}
}
.fs-14 {
	font-size: 14px !important;
}
.btn-primary.active {
	background-color: $background-color_20;
	border: 2px solid #2c1fe5;
	&:focus {
		background: #4C40ED;
		border: 1px solid #4C40ED;
		&:not(:disabled) {
			&:not(.disabled) {
				box-shadow: unset;
			}
		}
	}
	&:hover {
		background: #4C40ED;
		border: 1px solid #4C40ED;
	}
	&:not(:disabled) {
		&:not(.disabled) {
			background: #4C40ED;
			border-color: $border-color_1;
			color: $color_15;
		}
	}
}
.open {
	>.dropdown-toggle.btn-primary {
		background-color: $background-color_20;
		border: 2px solid #2c1fe5;
		&:focus {
			background: #4C40ED;
			border: 1px solid #4C40ED;
		}
		&:hover {
			background: #4C40ED;
			border: 1px solid #4C40ED;
		}
	}
	>.dropdown-toggle.btn-primary.focus {
		background: #4C40ED;
		border: 1px solid #4C40ED;
	}
	>.dropdown-toggle.btn-success {
		background-color: $background-color_11;
		border: 1px solid #23ad44;
		color: $color_15;
		&:focus {
			background-color: $background-color_11;
			border: 1px solid #23ad44;
		}
		&:hover {
			background-color: $background-color_11;
			border: 1px solid #23ad44;
		}
	}
	>.dropdown-toggle.btn-success.focus {
		background-color: $background-color_11;
		border: 1px solid #23ad44;
	}
	>.dropdown-toggle.btn-info {
		background-color: $background-color_13;
		border: 1px solid #028ee1;
		&:focus {
			background-color: $background-color_13;
			border: 1px solid #028ee1;
		}
		&:hover {
			background-color: $background-color_13;
			border: 1px solid #028ee1;
		}
	}
	>.dropdown-toggle.btn-info.focus {
		background-color: $background-color_13;
		border: 1px solid #028ee1;
	}
	>.dropdown-toggle.btn-warning {
		background-color: $background-color_15;
		border: 1px solid #e9ab2e;
		&:focus {
			background-color: $background-color_15;
			border: 1px solid #e9ab2e;
		}
		&:hover {
			background-color: $background-color_15;
			border: 1px solid #e9ab2e;
		}
	}
	>.dropdown-toggle.btn-warning.focus {
		background-color: $background-color_15;
		border: 1px solid #e9ab2e;
	}
	>.dropdown-toggle.btn-danger {
		background-color: $background-color_18;
		border: 1px solid #ee3939;
		&:focus {
			background-color: $background-color_18;
			border: 1px solid #ee3939;
		}
		&:hover {
			background-color: $background-color_18;
			border: 1px solid #ee3939;
		}
	}
	>.dropdown-toggle.btn-danger.focus {
		background-color: $background-color_18;
		border: 1px solid #ee3939;
	}
}
.btn-primary.active.focus {
	background: #4C40ED;
	border: 1px solid #4C40ED;
}
.btn-primary.focus {
	&:active {
		background: #4C40ED;
		border: 1px solid #4C40ED;
	}
}
.show {
	>.btn-primary.dropdown-toggle {
		background: #4C40ED;
		border-color: $border-color_1;
		color: $color_15;
		&:focus {
			box-shadow: unset;
		}
	}
	>.btn-secondary.dropdown-toggle {
		&:focus {
			box-shadow: unset;
		}
	}
	>.btn-success.dropdown-toggle {
		background-color: $background-color_11;
		border-color: $border-color_5;
		color: $color_15;
		&:focus {
			box-shadow: unset;
		}
	}
	>.btn-info.dropdown-toggle {
		background-color: $background-color_13;
		border-color: $border-color_7;
		color: $color_15;
		&:focus {
			box-shadow: unset;
		}
	}
	>.btn-warning.dropdown-toggle {
		background-color: $background-color_15;
		border-color: $border-color_9;
		color: $color_15;
		&:focus {
			box-shadow: unset;
		}
	}
	>.btn-danger.dropdown-toggle {
		background-color: $background-color_18;
		border-color: $border-color_11;
		color: $color_15;
		&:focus {
			box-shadow: unset;
		}
	}
	>.btn-light.dropdown-toggle {
		&:focus {
			box-shadow: unset;
		}
	}
	>.btn-dark.dropdown-toggle {
		&:focus {
			box-shadow: unset;
		}
	}
	>.btn-outline-primary.dropdown-toggle {
		background-color: $background-color_8;
		border-color: $border-color_1;
		&:focus {
			box-shadow: none;
		}
	}
	>.btn-outline-success.dropdown-toggle {
		background-color: $background-color_10;
		border-color: $border-color_6;
		&:focus {
			box-shadow: none;
		}
	}
	>.btn-outline-info.dropdown-toggle {
		background-color: $background-color_12;
		border-color: $border-color_8;
		&:focus {
			box-shadow: none;
		}
	}
	>.btn-outline-warning.dropdown-toggle {
		color: $color_24;
		background-color: $background-color_14;
		border-color: $border-color_10;
		&:focus {
			box-shadow: none;
		}
	}
	>.btn-outline-danger.dropdown-toggle {
		background-color: $background-color_16;
		border-color: $border-color_13;
		&:focus {
			box-shadow: none;
		}
	}
}
.btn-primary.disabled {
	background-color: $background-color_8;
	border-color: $border-color_1;
	color: $color_15;
}
.btn-secondary.active {
	&:focus {
		&:not(:disabled) {
			&:not(.disabled) {
				box-shadow: unset;
			}
		}
	}
}
.btn-secondary {
	&:active {
		&:focus {
			&:not(:disabled) {
				&:not(.disabled) {
					box-shadow: unset;
				}
			}
		}
	}
}
.btn-success {
	background-color: $background-color_10;
	border: 1px solid #27c24c;
	background: #26AF48;
	color: $color_15 !important;
	&:hover {
		background-color: $background-color_11;
		border: 1px solid #23ad44;
		color: $color_15;
	}
	&:focus {
		background-color: $background-color_11;
		border: 1px solid #23ad44;
		color: $color_15;
	}
	&:active {
		background-color: $background-color_11;
		border: 1px solid #23ad44;
		color: $color_15;
		&:focus {
			background-color: $background-color_11;
			border: 1px solid #23ad44;
			&:not(:disabled) {
				&:not(.disabled) {
					box-shadow: unset;
				}
			}
		}
		&:hover {
			background-color: $background-color_11;
			border: 1px solid #23ad44;
		}
		&:not(:disabled) {
			&:not(.disabled) {
				background-color: $background-color_11;
				border-color: $border-color_5;
				color: $color_15;
			}
		}
	}
	&:disabled {
		background-color: $background-color_10;
		border-color: $border-color_6;
		color: $color_15;
	}
}
.btn-success.active {
	background-color: $background-color_11;
	border: 1px solid #23ad44;
	color: $color_15;
	&:focus {
		background-color: $background-color_11;
		border: 1px solid #23ad44;
		&:not(:disabled) {
			&:not(.disabled) {
				box-shadow: unset;
			}
		}
	}
	&:hover {
		background-color: $background-color_11;
		border: 1px solid #23ad44;
	}
	&:not(:disabled) {
		&:not(.disabled) {
			background-color: $background-color_11;
			border-color: $border-color_5;
			color: $color_15;
		}
	}
}
.btn-success.active.focus {
	background-color: $background-color_11;
	border: 1px solid #23ad44;
}
.btn-success.focus {
	&:active {
		background-color: $background-color_11;
		border: 1px solid #23ad44;
	}
}
.btn-success.disabled {
	background-color: $background-color_10;
	border-color: $border-color_6;
	color: $color_15;
}
.btn-info {
	background-color: $background-color_12;
	border: 1px solid #009efb;
	&:hover {
		background-color: $background-color_13;
		border: 1px solid #028ee1;
	}
	&:focus {
		background-color: $background-color_13;
		border: 1px solid #028ee1;
	}
	&:active {
		background-color: $background-color_13;
		border: 1px solid #028ee1;
		&:focus {
			background-color: $background-color_13;
			border: 1px solid #028ee1;
			&:not(:disabled) {
				&:not(.disabled) {
					box-shadow: unset;
				}
			}
		}
		&:hover {
			background-color: $background-color_13;
			border: 1px solid #028ee1;
		}
		&:not(:disabled) {
			&:not(.disabled) {
				background-color: $background-color_13;
				border-color: $border-color_7;
				color: $color_15;
			}
		}
	}
	&:disabled {
		background-color: $background-color_12;
		border-color: $border-color_8;
		color: $color_15;
	}
}
.btn-info.active {
	background-color: $background-color_13;
	border: 1px solid #028ee1;
	&:focus {
		background-color: $background-color_13;
		border: 1px solid #028ee1;
		&:not(:disabled) {
			&:not(.disabled) {
				box-shadow: unset;
			}
		}
	}
	&:hover {
		background-color: $background-color_13;
		border: 1px solid #028ee1;
	}
	&:not(:disabled) {
		&:not(.disabled) {
			background-color: $background-color_13;
			border-color: $border-color_7;
			color: $color_15;
		}
	}
}
.btn-info.active.focus {
	background-color: $background-color_13;
	border: 1px solid #028ee1;
}
.btn-info.focus {
	&:active {
		background-color: $background-color_13;
		border: 1px solid #028ee1;
	}
}
.btn-info.disabled {
	background-color: $background-color_12;
	border-color: $border-color_8;
	color: $color_15;
}
.btn-warning {
	background-color: $background-color_14;
	border: 1px solid #ffbc34;
	&:hover {
		background-color: $background-color_15;
		border: 1px solid #e9ab2e;
	}
	&:focus {
		background-color: $background-color_15;
		border: 1px solid #e9ab2e;
	}
	&:active {
		background-color: $background-color_15;
		border: 1px solid #e9ab2e;
		&:focus {
			background-color: $background-color_15;
			border: 1px solid #e9ab2e;
			&:not(:disabled) {
				&:not(.disabled) {
					box-shadow: unset;
				}
			}
		}
		&:hover {
			background-color: $background-color_15;
			border: 1px solid #e9ab2e;
		}
		&:not(:disabled) {
			&:not(.disabled) {
				background-color: $background-color_15;
				border-color: $border-color_9;
				color: $color_15;
			}
		}
	}
	&:disabled {
		background-color: $background-color_14;
		border-color: $border-color_10;
		color: $color_15;
	}
}
.btn-warning.active {
	background-color: $background-color_15;
	border: 1px solid #e9ab2e;
	&:focus {
		background-color: $background-color_15;
		border: 1px solid #e9ab2e;
		&:not(:disabled) {
			&:not(.disabled) {
				box-shadow: unset;
			}
		}
	}
	&:hover {
		background-color: $background-color_15;
		border: 1px solid #e9ab2e;
	}
	&:not(:disabled) {
		&:not(.disabled) {
			background-color: $background-color_15;
			border-color: $border-color_9;
			color: $color_15;
		}
	}
}
.btn-warning.active.focus {
	background-color: $background-color_15;
	border: 1px solid #e9ab2e;
}
.btn-warning.focus {
	&:active {
		background-color: $background-color_15;
		border: 1px solid #e9ab2e;
	}
}
.btn-warning.disabled {
	background-color: $background-color_14;
	border-color: $border-color_10;
	color: $color_15;
}
.btn-danger {
	background-color: $background-color_16;
	border: 1px solid #f05050;
	&:hover {
		background-color: $background-color_18;
		border: 1px solid #ee3939;
	}
	&:focus {
		background-color: $background-color_18;
		border: 1px solid #ee3939;
	}
	&:active {
		background-color: $background-color_18;
		border: 1px solid #ee3939;
		&:focus {
			background-color: $background-color_18;
			border: 1px solid #ee3939;
			&:not(:disabled) {
				&:not(.disabled) {
					box-shadow: unset;
				}
			}
		}
		&:hover {
			background-color: $background-color_18;
			border: 1px solid #ee3939;
		}
		&:not(:disabled) {
			&:not(.disabled) {
				background-color: $background-color_18;
				border-color: $border-color_11;
				color: $color_15;
			}
		}
	}
	&:disabled {
		background-color: $background-color_21;
		border-color: $border-color_12;
		color: $color_15;
	}
}
.btn-danger.active {
	background-color: $background-color_18;
	border: 1px solid #ee3939;
	&:focus {
		background-color: $background-color_18;
		border: 1px solid #ee3939;
		&:not(:disabled) {
			&:not(.disabled) {
				box-shadow: unset;
			}
		}
	}
	&:hover {
		background-color: $background-color_18;
		border: 1px solid #ee3939;
	}
	&:not(:disabled) {
		&:not(.disabled) {
			background-color: $background-color_18;
			border-color: $border-color_11;
			color: $color_15;
		}
	}
}
.btn-danger.active.focus {
	background-color: $background-color_18;
	border: 1px solid #ee3939;
}
.btn-danger.focus {
	&:active {
		background-color: $background-color_18;
		border: 1px solid #ee3939;
	}
}
.btn-danger.disabled {
	background-color: $background-color_21;
	border-color: $border-color_12;
	color: $color_15;
}
.btn-light.active {
	&:focus {
		&:not(:disabled) {
			&:not(.disabled) {
				box-shadow: unset;
			}
		}
	}
}
.btn-light {
	&:active {
		&:focus {
			&:not(:disabled) {
				&:not(.disabled) {
					box-shadow: unset;
				}
			}
		}
	}
	border-color: $border-color_14;
	color: $color_30;
}
.btn-dark.active {
	&:focus {
		&:not(:disabled) {
			&:not(.disabled) {
				box-shadow: unset;
			}
		}
	}
}
.btn-dark {
	&:active {
		&:focus {
			&:not(:disabled) {
				&:not(.disabled) {
					box-shadow: unset;
				}
			}
		}
	}
}
.btn-outline-primary {
	color: $color_9;
	border-color: $border-color_1;
	&:hover {
		background-color: $background-color_8;
		border-color: $border-color_1;
	}
	&:focus {
		box-shadow: none;
	}
	&:disabled {
		color: $color_9;
		background-color: $background-color_22;
	}
	&:not(:disabled) {
		&:not(.disabled) {
			&:active {
				background-color: $background-color_8;
				border-color: $border-color_1;
				&:focus {
					box-shadow: none;
				}
			}
		}
		&:not(.disabled).active {
			background-color: $background-color_8;
			border-color: $border-color_1;
			&:focus {
				box-shadow: none;
			}
		}
	}
}
.btn-outline-primary.focus {
	box-shadow: none;
}
.btn-outline-primary.disabled {
	color: $color_9;
	background-color: $background-color_22;
}
.btn-outline-success {
	color: $color_23;
	border-color: $border-color_6;
	&:hover {
		background-color: $background-color_10;
		border-color: $border-color_6;
	}
	&:focus {
		box-shadow: none;
	}
	&:disabled {
		color: $color_23;
		background-color: $background-color_22;
	}
	&:not(:disabled) {
		&:not(.disabled) {
			&:active {
				background-color: $background-color_10;
				border-color: $border-color_6;
				&:focus {
					box-shadow: none;
				}
			}
		}
		&:not(.disabled).active {
			background-color: $background-color_10;
			border-color: $border-color_6;
			&:focus {
				box-shadow: none;
			}
		}
	}
}
.btn-outline-success.focus {
	box-shadow: none;
}
.btn-outline-success.disabled {
	color: $color_23;
	background-color: $background-color_22;
}
.btn-outline-info {
	color: $color_18;
	border-color: $border-color_8;
	&:hover {
		color: $color_15;
		background-color: $background-color_12;
		border-color: $border-color_8;
	}
	&:focus {
		box-shadow: none;
	}
	&:disabled {
		background-color: $background-color_22;
		color: $color_18;
	}
	&:not(:disabled) {
		&:not(.disabled) {
			&:active {
				background-color: $background-color_12;
				border-color: $border-color_8;
				&:focus {
					box-shadow: none;
				}
			}
		}
		&:not(.disabled).active {
			background-color: $background-color_12;
			border-color: $border-color_8;
			&:focus {
				box-shadow: none;
			}
		}
	}
}
.btn-outline-info.focus {
	box-shadow: none;
}
.btn-outline-info.disabled {
	background-color: $background-color_22;
	color: $color_18;
}
.btn-outline-warning {
	color: $color_19;
	border-color: $border-color_10;
	&:hover {
		color: $color_24;
		background-color: $background-color_14;
		border-color: $border-color_10;
	}
	&:focus {
		box-shadow: none;
	}
	&:disabled {
		background-color: $background-color_22;
		color: $color_19;
	}
	&:not(:disabled) {
		&:not(.disabled) {
			&:active {
				color: $color_24;
				background-color: $background-color_14;
				border-color: $border-color_10;
				&:focus {
					box-shadow: none;
				}
			}
		}
		&:not(.disabled).active {
			color: $color_24;
			background-color: $background-color_14;
			border-color: $border-color_10;
			&:focus {
				box-shadow: none;
			}
		}
	}
}
.btn-outline-warning.focus {
	box-shadow: none;
}
.btn-outline-warning.disabled {
	background-color: $background-color_22;
	color: $color_19;
}
.btn-outline-danger {
	color: $color_17;
	border-color: $border-color_13;
	&:hover {
		color: $color_15;
		background-color: $background-color_16;
		border-color: $border-color_13;
	}
	&:focus {
		box-shadow: none;
	}
	&:disabled {
		background-color: $background-color_22;
		color: $color_17;
	}
	&:not(:disabled) {
		&:not(.disabled) {
			&:active {
				background-color: $background-color_16;
				border-color: $border-color_13;
				&:focus {
					box-shadow: none;
				}
			}
		}
		&:not(.disabled).active {
			background-color: $background-color_16;
			border-color: $border-color_13;
			&:focus {
				box-shadow: none;
			}
		}
	}
}
.btn-outline-danger.focus {
	box-shadow: none;
}
.btn-outline-danger.disabled {
	background-color: $background-color_22;
	color: $color_17;
}
.btn-outline-light {
	color: $color_25;
	border-color: $border-color_14;
	&:disabled {
		color: $color_25;
	}
}
.btn-outline-light.disabled {
	color: $color_25;
}
.pagination {
	>.active {
		>a {
			background-color: $background-color_8;
			border-color: $border-color_1;
			color: $color_15;
			&:focus {
				background-color: $background-color_8;
				border-color: $border-color_1;
				color: $color_15;
			}
			&:hover {
				background-color: $background-color_8;
				border-color: $border-color_1;
				color: $color_15;
			}
		}
		>span {
			background-color: $background-color_8;
			border-color: $border-color_1;
			color: $color_15;
			&:focus {
				background-color: $background-color_8;
				border-color: $border-color_1;
				color: $color_15;
			}
			&:hover {
				background-color: $background-color_8;
				border-color: $border-color_1;
				color: $color_15;
			}
		}
	}
	>li {
		>a {
			color: $color_9;
		}
		>span {
			color: $color_9;
		}
	}
}
.page-link {
	&:hover {
		color: $color_9;
	}
	&:focus {
		box-shadow: unset;
	}
}
.page-item {
	&:first-child {
		.page-link {
			margin-left: 0 !important;
		}
	}
	.page-link {
		width: 25px;
		height: 25px;
		font-size: 14px;
		font-weight: 500;
		display: flex;
		align-items: center;
		justify-content: center;
		background: #F5F5F5;
		border-radius: 5px;
		margin-left: 10px !important;
		color: $color_1;
	}
}
.page-item.active {
	.page-link {
		background-color: $background-color_8;
		border-color: $border-color_1;
	}
}
.dropdown-item.active {
	background-color: $background-color_8;
}
.dropdown-item {
	&:active {
		background-color: $background-color_8;
	}
	font-size: 13px;
	color: $color_1;
}
.settings-tab {
	.nav {
		.nav-link {
			margin-bottom: 15px;
			color: $color_8;
			&:hover {
				background: #e8e8e8;
				color: $color_8;
			}
		}
		.nav-link.active {
			background: #4C40ED;
			color: $color_15;
		}
		.show {
			>.nav-link {
				background: #4C40ED;
				color: $color_15;
			}
		}
	}
}
.card-body {
	padding: 1.5rem;
	padding: 25px;
	position: relative;
	padding: 25px;
}
.card-header {
	border-bottom: 1px solid #e6e6e6;
	padding: 1rem 1.5rem;
}
.card-footer {
	background-color: $background-color_1;
	border-top: 1px solid #e6e6e6;
	padding: 1rem 1.5rem;
	background-color: $background-color_1;
	border-radius: 0 0 15px 15px;
	border-top: 0;
}
.card {
	.card-header {
		background-color: $background-color_1;
		border-bottom: 1px solid #eaeaea;
		.card-title {
			margin-bottom: 0;
			font-size: 18px;
		}
	}
	border-radius: 8px;
	border: 2px solid rgb(220 220 220 / 30%);
	box-shadow: none;
	margin-bottom: 25px;
	width: 100%;
}
.btn-update {
	&:hover {
		background: transparent;
		color: $color_9;
	}
	border: 1px solid #4C40ED;
	color: $color_15;
	background: #4C40ED;
}
.custom-control-input {
	&:checked~.custom-control-label {
		&::before {
			color: $color_15;
			border-color: $border-color_15;
			background-color: $background-color_23;
		}
	}
	position: absolute;
	left: 0;
	z-index: -1;
	width: 1rem;
	height: 1.25rem;
	opacity: 0;
}
.custom-control-label {
	&::after {
		position: absolute;
		top: 0.25rem;
		left: -1.5rem;
		display: block;
		width: 1rem;
		height: 1rem;
		content: "";
		background: no-repeat 50%/50% 50%;
	}
	position: relative;
	margin-bottom: 0;
	vertical-align: top;
}
.custom-radios {
	padding-left: 25px;
	input[type="radio"] {
		+ {
			label {
				position: relative;
				&::after {
					position: absolute;
					content: "";
					background: #C9C9C9;
					width: 10px;
					height: 10px;
					border-radius: 50%;
					left: -18px;
					top: 6px;
				}
				&::before {
					position: absolute;
					content: "";
					width: 18px;
					height: 18px;
					border: 1px solid #C9C9C9;
					left: -22px;
					top: 2px;
					border-radius: 50%;
					background: #fff !important;
				}
			}
		}
	}
	.custom-control-input {
		&:checked~.custom-control-label {
			&::before {
				border: 1px solid #4C40ED;
			}
			&::after {
				background: #4C40ED;
			}
		}
	}
}
.radiossets {
	display: block;
	position: relative;
	padding-left: 29px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 22px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	margin-bottom: 0 !important;
	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		&:checked {
			~ {
				.checkmark-radio {
					background-color: $background-color_1;
					border: 1px solid #4C40ED;
					&:after {
						display: block;
					}
				}
			}
		}
	}
	&:hover {
		input {
			~ {
				.checkmark-radio {
					background-color: $background-color_24;
				}
			}
		}
	}
	.checkmark-radio {
		&:after {
			top: 3px;
			left: 3px;
			width: 12px;
			height: 12px;
			border-radius: 50%;
			background: #4C40ED;
		}
	}
}
.checkmark-radio {
	position: absolute;
	top: 0;
	left: 0;
	height: 20px;
	width: 20px;
	border-radius: 50%;
	border: 1px solid #D1D1D6;
	&:after {
		content: "";
		position: absolute;
		display: none;
	}
}
ul.custom-radiosbtn {
	display: flex;
	li {
		margin-right: 20px;
	}
}
.input-tags.form-control {
	display: block;
	border-radius: 5px;
	background: #FFFFFF;
	border: 1px solid #E5E5E5;
	min-height: 50px;
	padding-top: 5px;
	padding-left: 5px;
	padding-right: 10px;
}
.bootstrap-tagsinput {
	display: block;
	border-radius: 8px;
	background: #F9F9FB;
	border: 1px solid #F3F3F3;
	min-height: 46px;
	padding-top: 5px;
	padding-left: 5px;
	padding-right: 10px;
	width: 100%;
	box-shadow: none;
	.tag {
		margin-right: 5px;
		margin-bottom: 2px;
		color: $color_4;
		background: #F2F2F4 !important;
		font-size: 14px;
		font-weight: 500;
		padding: 10px 20px 10px 15px;
		border-radius: 5px;
		[data-role="remove"] {
			position: relative;
			left: 7px;
			cursor: pointer;
			&:after {
				content: "x";
				padding: 0px 2px;
			}
		}
	}
}
.manidory {
	color: $color_9;
	margin-left: 5px;
}
.settings-label {
	margin-bottom: 10px;
}
.settings-btn {
	border: 1px solid #D7D7D7;
	border-radius: 5px;
	color: $color_26;
	cursor: pointer;
	font-weight: 400;
	padding: 15px 15px;
	position: relative;
	width: 100%;
	height: 50px;
	.hide-input {
		position: relative;
		z-index: 1;
		cursor: pointer;
		min-height: auto;
		padding-left: 4px;
		padding-top: 0;
		line-height: 10px;
		width: 100%;
		opacity: 0;
	}
	.upload {
		position: absolute;
		z-index: 11;
		background: #4C40ED;
		border-radius: 5px;
		right: 2px;
		top: 1.5px;
		width: 44px;
		height: 45px;
		font-size: 20px;
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;
		color: $color_15;
	}
}
.btn-reset {
	background: #858585;
	color: $color_15;
	&:hover {
		background: #4C40ED;
		color: $color_15;
	}
}
.form-head {
	span {
		color: $color_27;
		font-size: 10px;
		margin-left: 10px;
	}
}
.form-heads {
	font-size: 18px;
	font-weight: 500;
	color: $color_9;
}
.socail-links-set {
	ul {
		padding: 0;
		list-style: none;
		margin: 0;
	}
	a.dropdown-toggle.nav-link {
		background: #858585;
		color: $color_15;
		border-radius: 5px;
	}
	.dropdown-toggle {
		&::after {
			display: inline-block;
			margin-left: 0.255em;
			vertical-align: 0.255em;
			content: "";
			border-top: 0.3em solid;
			border-right: 0.3em solid transparent;
			border-bottom: 0;
			border-left: 0.3em solid transparent;
			position: absolute;
			right: 10px;
			top: 17px;
		}
	}
}
.cards {
	border: 1px solid #EEF1FE;
	box-shadow: 0px 0px 12px 0px #37494805;
	padding: 15px;
	border-radius: 5px;
	margin-bottom: 20px;
}
.f-14 {
	font-size: 14px !important;
}
.upload-images {
	background: rgba(255, 255, 255, 0.8);
	border: 1px solid #E4E4E4;
	border-radius: 5px;
	display: flex;
	margin-top: 20px;
	width: 196px;
	height: auto;
	justify-content: space-around;
	align-items: center;
	position: relative;
	padding: 5px;
	img {
		max-height: 195px;
		width: 100%;
	}
	.btn-icon {
		color: $color_29;
		font-size: 16px;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		text-align: center;
		position: absolute;
		padding: 0;
		width: 15px;
		height: 15px;
		display: -ms-inline-flexbox;
		display: inline-flex;
		right: 10px;
		top: 10px;
		justify-content: center;
		-webkit-justify-content: center;
		background: #fff;
		border-radius: 50%;
		font-size: 9px;
		border: 1px solid #ff0000;
		&:hover {
			background: #ff0000;
			color: $color_15;
		}
	}
	h6 {
		font-size: 12px;
		font-weight: 600;
		margin-top: 10px;
	}
	h5 {
		font-size: 10px;
		font-weight: 400;
	}
}
.settings-size {
	font-size: 14px;
	color: $color_28;
	margin-top: 10px;
	margin-bottom: 0;
	font-weight: 400;
}
.color-set {
	width: 25px;
	height: 25px;
	border-radius: 5px;
	display: block;
	margin-left: 25px;
	margin-right: 15px;
}
.clr-default {
	background: #C4C4C4;
}
.clr-orange {
	background: #FC8543;
}
.clr-blue {
	background: #0F6FDE;
}
.clr-red {
	background: #F43F3F;
}
.clr-green {
	background: #17D053;
}
.slidecontainer {
	width: 100%;
}
.slider {
	-webkit-appearance: none;
	width: 100%;
	height: 10px;
	background: linear-gradient(to right, #4C40ED 0%, #4C40ED 50%, #c4c4c4 50%, #c4c4c4 100%);
	outline: none;
	-webkit-transition: .2s;
	transition: opacity .2s;
	border-radius: 10px;
	&::-webkit-slider-thumb {
		&:hover {
			background: #000000;
		}
		-webkit-appearance: none;
		appearance: none;
		width: 25px;
		height: 25px;
		border-radius: 50%;
		cursor: pointer;
		background: #4C40ED;
		border: 2px solid #fff;
		box-shadow: 0px 4px 14px rgba(154, 154, 154, 0.25);
	}
	&::-moz-range-thumb {
		&:hover {
			background: #000000;
		}
		width: 38px;
		height: 38px;
		border-radius: 50%;
		background: #FFFFFF;
		box-shadow: 0px 4px 14px rgba(154, 154, 154, 0.25);
	}
}
.upload-images.upload-imagesprofile {
	img {
		width: 68px;
		height: 68px;
		background: #c4c4c4;
		padding: 10px;
		border-radius: 5px;
	}
	width: 80px;
	height: 80px;
	padding: 5px;
	display: block;
}
.loan-set {
	h3 {
		font-size: 12px;
		color: $color_28;
		font-weight: 400;
	}
}
.hidden-web {
	display: block;
}
.custom-control-inline {
	display: -ms-inline-flexbox;
	display: inline-flex;
	margin-right: 1rem;
}
.modal-footer.text-left {
	text-align: left;
}
.modal-footer.text-center {
	text-align: center;
}
.bootstrap-datetimepicker-widget {
	table {
		td.active {
			background-color: $background-color_8;
			text-shadow: unset;
			&:hover {
				background-color: $background-color_8;
				text-shadow: unset;
			}
		}
		td.today {
			&:before {
				border-bottom-color: $border-bottom-color_1;
			}
		}
	}
}
.bg-info-light {
	background-color: $background-color_25 !important;
	color: $color_31 !important;
}
.bg-primary-light {
	background-color: $background-color_26 !important;
	color: $color_32 !important;
}
.bg-danger-light {
	background-color: $background-color_27 !important;
	color: $color_33 !important;
}
.bg-warning-light {
	background-color: $background-color_28 !important;
	color: $color_34 !important;
}
.bg-success-light {
	background-color: $background-color_29 !important;
	color: $color_35 !important;
}
.bg-purple-light {
	background-color: $background-color_30 !important;
	color: $color_36 !important;
}
.bg-default-light {
	background-color: $background-color_31 !important;
	color: $color_37 !important;
}
.select2-container {
	z-index: unset;
	.select2-selection--single {
		border: 1px solid #F8F8F8;
		height: 46px;
		background-color: $background-color_32;
		border-radius: 8px;
		.select2-selection__rendered {
			padding-right: 30px;
			padding-left: 15px;
		}
	}
}
.select2-container--default {
	.select2-selection--single {
		.select2-selection__arrow {
			height: 38px;
			right: 7px;
			b {
				border-color: $border-color_16;
				border-style: solid;
				border-width: 6px 6px 0;
				height: 0;
				left: 50%;
				margin-left: -10px;
				margin-top: -2px;
				position: absolute;
				top: 50%;
				width: 0;
				border-color: $border-color_18;
				border-style: solid;
				border-width: 0 2px 2px 0;
				padding: 3px;
				height: 0;
				left: 50%;
				margin-left: -5px;
				margin-top: -5px;
				position: absolute;
				top: 50%;
				width: 0;
				transform: rotate(45deg);
				-webkit-transform: rotate(45deg);
			}
		}
		.select2-selection__rendered {
			color: $color_1;
			line-height: 46px;
		}
	}
	.select2-results__option--highlighted[aria-selected] {
		background-color: $background-color_8;
	}
	.select2-selection--multiple {
		border: 1px solid #F8F8F8;
		min-height: 46px;
		background-color: $background-color_32;
		padding: 4px 8px;
		border-radius: 8px;
		.select2-selection__choice {
			background-color: $background-color_33;
			border: 0;
			font-size: 13px;
			margin-top: 6px;
			padding: 3px 5px;
		}
		.select2-selection__choice__remove {
			color: $color_15;
			cursor: pointer;
			font-weight: bold;
			margin-right: auto;
			float: right;
			margin-left: 15px;
			width: 15px;
			height: 15px;
			background: #9597A9;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 30px;
			position: relative;
			top: 3px;
			padding: 2px;
		}
	}
}
.select2-container--default.select2-container--open {
	.select2-selection--single {
		.select2-selection__arrow {
			b {
				border-color: $border-color_17;
				border-width: 0 6px 6px;
				border-color: $border-color_18;
				border-width: 2px 0 0 2px;
				padding: 3px;
				margin-top: -2px;
			}
		}
	}
}
.select2-dropdown {
	border: 1px solid #E6E9E8;
}
.nav-tabs {
	border-bottom: 1px solid #e6e6e6;
	>li {
		>a {
			margin-right: 0;
			color: $color_10;
			border-radius: 0;
			&:hover {
				border-color: $border-color_3;
				color: $color_8;
			}
			&:focus {
				border-color: $border-color_3;
				color: $color_8;
			}
		}
	}
	.nav-link {
		border-radius: 0;
		&:focus {
			background-color: $background-color_24;
			border-color: $border-color_3;
			color: $color_8;
		}
		&:hover {
			background-color: $background-color_24;
			border-color: $border-color_3;
			color: $color_8;
			background: transparent;
		}
		&::after {
			position: absolute;
			content: '';
		}
	}
}
.card-header-tabs {
	border-bottom: 0;
}
.nav-tabs.nav-tabs-solid {
	>li {
		>a {
			color: $color_8;
			color: $color_8;
			border-color: $border-color_3;
			&:hover {
				background-color: $background-color_35;
			}
			&:focus {
				background-color: $background-color_35;
			}
		}
		>a.active {
			background-color: $background-color_8;
			border-color: $border-color_1;
			color: $color_15;
			&:hover {
				background-color: $background-color_8;
				border-color: $border-color_1;
				color: $color_15;
			}
			&:focus {
				background-color: $background-color_8;
				border-color: $border-color_1;
				color: $color_15;
			}
		}
		margin-bottom: 0;
	}
	>.active {
		>a {
			background-color: $background-color_8;
			border-color: $border-color_1;
			color: $color_15;
			&:hover {
				background-color: $background-color_8;
				border-color: $border-color_1;
				color: $color_15;
			}
			&:focus {
				background-color: $background-color_8;
				border-color: $border-color_1;
				color: $color_15;
			}
		}
	}
	background-color: $background-color_34;
	border: 0;
	>.open {
		&:not(.active) {
			>a {
				background-color: $background-color_35;
				border-color: $border-color_3;
			}
		}
	}
}
.tab-content {
	padding-top: 20px;
}
.nav-tabs.nav-justified {
	>li {
		>a {
			border-radius: 0;
			margin-bottom: 0;
			&:hover {
				border-bottom-color: $border-bottom-color_2;
			}
			&:focus {
				border-bottom-color: $border-bottom-color_2;
			}
		}
	}
}
.nav-tabs.nav-justified.nav-tabs-solid {
	>li {
		>a {
			border-color: $border-color_3;
		}
	}
}
.nav-tabs.nav-tabs-solid.nav-tabs-rounded {
	border-radius: 50px;
	>li {
		>a {
			border-radius: 50px;
		}
		>a.active {
			border-radius: 50px;
			&:hover {
				border-radius: 50px;
			}
			&:focus {
				border-radius: 50px;
			}
		}
	}
}
.nav-tabs-justified {
	>li {
		>a {
			border-radius: 0;
			margin-bottom: 0;
			&:hover {
				border-bottom-color: $border-bottom-color_2;
			}
			&:focus {
				border-bottom-color: $border-bottom-color_2;
			}
		}
	}
}
.nav-tabs-justified.nav-tabs-solid {
	>li {
		>a {
			border-color: $border-color_3;
		}
	}
}
.nav-tabs.nav-justified.nav-tabs-top {
	border-bottom: 1px solid #ddd;
	>li {
		>a {
			border-width: 2px 0 0 0;
			&:hover {
				border-width: 2px 0 0 0;
			}
			&:focus {
				border-width: 2px 0 0 0;
			}
		}
	}
}
.nav-tabs.nav-tabs-top {
	>li {
		margin-bottom: 0;
		>a {
			border-width: 2px 0 0 0;
			&:hover {
				border-width: 2px 0 0 0;
				border-top-color: $border-top-color_1;
			}
			&:focus {
				border-width: 2px 0 0 0;
				border-top-color: $border-top-color_1;
			}
		}
		>a.active {
			border-top-color: $border-top-color_2;
			&:hover {
				border-top-color: $border-top-color_2;
			}
			&:focus {
				border-top-color: $border-top-color_2;
			}
		}
	}
	>li.open {
		>a {
			border-top-color: $border-top-color_1;
		}
	}
	>li+li {
		>a {
			margin-left: 1px;
		}
	}
}
.nav-tabs.nav-tabs-bottom {
	>li {
		margin-bottom: -1px;
		>a.active {
			border-bottom-width: 2px;
			border-color: $border-color_3;
			border-bottom-color: $border-bottom-color_1;
			background-color: $background-color_22;
			transition: none 0s ease 0s;
			-moz-transition: none 0s ease 0s;
			-o-transition: none 0s ease 0s;
			-ms-transition: none 0s ease 0s;
			-webkit-transition: none 0s ease 0s;
			&:hover {
				border-bottom-width: 2px;
				border-color: $border-color_3;
				border-bottom-color: $border-bottom-color_1;
				background-color: $background-color_22;
				transition: none 0s ease 0s;
				-moz-transition: none 0s ease 0s;
				-o-transition: none 0s ease 0s;
				-ms-transition: none 0s ease 0s;
				-webkit-transition: none 0s ease 0s;
			}
			&:focus {
				border-bottom-width: 2px;
				border-color: $border-color_3;
				border-bottom-color: $border-bottom-color_1;
				background-color: $background-color_22;
				transition: none 0s ease 0s;
				-moz-transition: none 0s ease 0s;
				-o-transition: none 0s ease 0s;
				-ms-transition: none 0s ease 0s;
				-webkit-transition: none 0s ease 0s;
			}
		}
	}
}
.nav-tabs-justified.nav-tabs-top {
	border-bottom: 1px solid #ddd;
	>li {
		>a {
			border-width: 2px 0 0 0;
			&:hover {
				border-width: 2px 0 0 0;
			}
			&:focus {
				border-width: 2px 0 0 0;
			}
		}
	}
}
.progress-xs {
	height: 4px;
}
.progress-sm {
	height: 15px;
}
.progress.progress-sm {
	height: 6px;
}
.progress.progress-md {
	height: 8px;
}
.progress.progress-lg {
	height: 18px;
}
.row.row-sm {
	margin-left: -3px;
	margin-right: -3px;
	>div {
		padding-left: 3px;
		padding-right: 3px;
	}
}
.swal2-title {
	font-size: 22px !important;
	font-weight: 500;
	color: $color_7 !important;
}
.swal2-content {
	font-size: 14px !important;
	color: $color_1 !important;
}
.swal2-styled.swal2-confirm {
	font-size: 14px !important;
}
.avatar {
	position: relative;
	display: inline-block;
	width: 3rem;
	height: 3rem;
	>img {
		width: 100%;
		height: 100%;
		-o-object-fit: cover;
		object-fit: cover;
	}
	.border {
		border-width: 3px !important;
	}
	.rounded {
		border-radius: 6px !important;
	}
	.avatar-title {
		font-size: 18px;
	}
}
.avatar-title {
	width: 100%;
	height: 100%;
	background-color: $background-color_8;
	color: $color_15;
	display: flex;
	align-items: center;
	justify-content: center;
}
.avatar-away {
	&:before {
		position: absolute;
		right: 0;
		bottom: 0;
		width: 25%;
		height: 25%;
		border-radius: 50%;
		content: '';
		border: 2px solid #fff;
		background-color: $background-color_14;
	}
}
.avatar-offline {
	&:before {
		position: absolute;
		right: 0;
		bottom: 0;
		width: 25%;
		height: 25%;
		border-radius: 50%;
		content: '';
		border: 2px solid #fff;
		background-color: $background-color_16;
	}
}
.avatar-online {
	&:before {
		position: absolute;
		right: 0;
		bottom: 0;
		width: 25%;
		height: 25%;
		border-radius: 50%;
		content: '';
		border: 2px solid #fff;
		background-color: $background-color_10;
	}
}
.avatar-xs {
	width: 1.65rem;
	height: 1.65rem;
	.border {
		border-width: 2px !important;
	}
	.rounded {
		border-radius: 4px !important;
	}
	.avatar-title {
		font-size: 12px;
	}
}
.avatar-xs.avatar-away {
	&:before {
		border-width: 1px;
	}
}
.avatar-xs.avatar-offline {
	&:before {
		border-width: 1px;
	}
}
.avatar-xs.avatar-online {
	&:before {
		border-width: 1px;
	}
}
.avatar-sm {
	width: 2.5rem;
	height: 2.5rem;
	.border {
		border-width: 3px !important;
	}
	.rounded {
		border-radius: 4px !important;
	}
	.avatar-title {
		font-size: 15px;
	}
}
.avatar-sm.avatar-away {
	&:before {
		border-width: 2px;
	}
}
.avatar-sm.avatar-offline {
	&:before {
		border-width: 2px;
	}
}
.avatar-sm.avatar-online {
	&:before {
		border-width: 2px;
	}
}
.header {
	background: #3b3b3b;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 999;
	height: 76px;
	transition: all 0.45s ease 0s;
	.header-left {
		float: left;
		height: 76px;
		padding: 0 20px;
		position: relative;
		text-align: center;
		width: 270px;
		z-index: 1;
		transition: all 0.2s ease-in-out;
		display: none;
		.logo {
			display: inline-block;
			line-height: 60px;
			img {
				max-height: 24px;
				width: auto;
			}
		}
	}
	.dropdown-menu {
		>li {
			>a {
				position: relative;
				padding: 10px 18px;
				&:focus {
					background-color: $background-color_8;
					color: $color_15;
					i {
						color: $color_15;
					}
				}
				&:hover {
					background-color: $background-color_8;
					color: $color_15;
					i {
						color: $color_15;
					}
				}
				i {
					color: $color_9;
					margin-right: 10px;
					text-align: center;
					width: 18px;
				}
			}
		}
	}
	.dropdown-toggle {
		&:after {
			display: none;
		}
	}
	.has-arrow {
		.dropdown-toggle {
			&:after {
				border-top: 0;
				border-left: 0;
				border-bottom: 2px solid #fff;
				border-right: 2px solid #fff;
				content: '';
				height: 8px;
				display: inline-block;
				pointer-events: none;
				-webkit-transform-origin: 66% 66%;
				-ms-transform-origin: 66% 66%;
				transform-origin: 66% 66%;
				-webkit-transform: rotate(45deg);
				-ms-transform: rotate(45deg);
				transform: rotate(45deg);
				-webkit-transition: all 0.15s ease-in-out;
				transition: all 0.15s ease-in-out;
				width: 8px;
				vertical-align: 2px;
			}
		}
		.dropdown-toggle[aria-expanded="true"] {
			&:after {
				-webkit-transform: rotate(-135deg);
				-ms-transform: rotate(-135deg);
				transform: rotate(-135deg);
			}
		}
	}
	.user-menu {
		.dropdown-menu {
			>li {
				>a {
					i {
						color: $color_9;
						font-size: 16px;
						margin-right: 10px;
						min-width: 18px;
						text-align: center;
					}
					&:focus {
						i {
							color: $color_15;
						}
					}
					&:hover {
						i {
							color: $color_15;
						}
					}
				}
			}
		}
	}
}
.page-headers {
	h6 {
		margin: 0;
		font-size: 22px;
		color: $color_7;
		font-weight: 500;
	}
	.search-bar {
		.form-control {
			border: 1px solid #C2C9D1;
			border-radius: 8px;
			padding: 9px 9px 9px 36px;
			background-color: $background-color_1;
			color: $color_1;
			font-weight: 400;
			font-size: 15px;
			&::placeholder {
				color: $color_1;
			}
		}
		position: relative;
		width: 250px;
		span {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			left: 15px;
			color: $color_1;
		}
	}
}
.header-left {
	.logo.logo-small {
		display: none;
	}
}
.header-split {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.animate-circle {
	-webkit-animation: heartbit 1s ease-out;
	animation: heartbit 3s ease-out;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
	border: 5px solid #4C40ED;
	border-radius: 70px;
	height: 65px;
	position: absolute;
	left: -7px;
	top: -7px;
	width: 65px;
	z-index: -1;
}
.user-menu {
	float: right;
	margin: 0;
	position: relative;
	z-index: 99;
	align-items: center;
	.dropdown-menu {
		min-width: 200px;
		padding: 0;
		margin-top: 12px !important;
		transition: all 0.5s;
		transform: translate(0px, 100px);
		.dropdown-item {
			padding: 7px 15px;
			display: flex;
			align-items: center;
			border-top: 1px solid #e3e3e3;
			padding: 10px 15px;
			&:hover {
				color: $color_9;
			}
		}
	}
	.dropdown-menu.menu-drop-user {
		margin-top: 6px !important;
	}
}
.dropdown-heads {
	>a {
		background: #F7F7FA;
		width: 40px;
		height: 40px;
		display: flex;
		align-items: center;
		border-radius: 50px;
		justify-content: center;
		&:hover {
			background: #4C40ED !important;
			color: $color_15 !important;
			i {
				color: $color_15 !important;
			}
		}
	}
	margin: 0 5px;
}
.user-menu.nav {
	>li {
		>a {
			color: $color_1;
			font-size: 14px;
			padding: 0 15px;
			&:hover {
				background-color: $background-color_22;
				i {
					color: $color_8;
				}
			}
			&:focus {
				background-color: $background-color_22;
				i {
					color: $color_8;
				}
			}
			.badge {
				background-color: $background-color_44;
				display: block;
				font-size: 10px;
				font-weight: bold;
				height: 10px;
				width: 10px;
				position: absolute;
				right: 7px;
				top: 7px;
				border: 2px solid #fff;
				line-height: unset;
				padding: 0;
			}
			>i {
				font-size: 18px;
			}
		}
		>a.mobile_btn {
			border: 0;
			position: relative;
			padding: 0;
			margin: 0;
			cursor: pointer;
		}
		>a.viewsite {
			font-size: 13px;
			font-weight: 400;
			color: $color_1;
			display: flex;
			align-items: center;
			&:hover {
				color: $color_9;
				i {
					color: $color_9;
				}
			}
		}
	}
	>li.view-btn {
		>a {
			&:hover {
				i {
					color: $color_15;
				}
				background-color: $background-color_9;
				color: $color_15;
			}
			&:focus {
				i {
					color: $color_15;
				}
				background-color: $background-color_36 !important;
			}
			display: flex;
			align-items: center;
			justify-content: center;
			color: $color_15;
			padding: 9px 15px;
			border-radius: 6px;
			background-color: $background-color_8;
			i {
				font-size: 17px;
				position: relative;
			}
		}
	}
}
.user-img {
	margin-right: 3px;
	position: relative;
	width: 52px;
	height: 52px;
	background: #D9D9D9;
	border-radius: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	img {
		width: 40px;
		height: 40px;
		-o-object-fit: cover;
		object-fit: cover;
		border: 3px solid #FFFFFF;
		background: #D9D9D9;
		padding: 3px;
	}
}
.user-link {
	display: flex;
	align-items: center;
}
span.user-content {
	padding-left: 8px;
}
span.user-name {
	display: block;
	color: $color_7;
	font-size: 15px;
	font-weight: 600;
}
span.user-details {
	color: $color_38;
	font-size: 14px;
	font-weight: 400;
}
.mobile_btn {
	display: none;
	float: left;
	display: none;
	float: left;
}
.slide-nav {
	.sidebar {
		margin-left: 0;
	}
}
.user-header {
	background-color: $background-color_37;
	display: flex;
	padding: 10px 15px;
	.user-text {
		margin-left: 10px;
		h6 {
			margin-bottom: 2px;
		}
	}
}
.menu-title {
	color: $color_39;
	display: block;
	font-size: 14px;
	margin-bottom: 5px;
	padding: 0 25px;
	color: $color_42;
	display: flex;
	font-size: 14px;
	opacity: 1;
	padding: 5px 10px 5px 20px;
	white-space: nowrap;
	margin-top: 30px;
	text-transform: uppercase;
	h6 {
		color: $color_1;
		margin: 0;
		font-size: 12px;
	}
	>i {
		float: right;
		line-height: 40px;
	}
}
.sidebar-overlay {
	background-color: $background-color_38;
	display: none;
	height: 100%;
	left: 0;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 1000;
}
.sidebar-overlay.opened {
	display: block;
}
html.menu-opened {
	overflow: hidden;
	body {
		overflow: hidden;
	}
}
.view-btn {
	margin-right: 15px;
}
.select-by {
	min-width: 134px;
	margin-right: 25px;
	.select2-container--default {
		.select2-selection--single {
			.select2-selection__arrow {
				b {
					border-color: $border-color_19;
					border-style: solid;
					border-width: 0 2px 2px 0;
					padding: 4px;
					height: 0;
					left: 50%;
					margin-left: -5px;
					margin-top: -5px;
					position: absolute;
					top: 45%;
					width: 0;
					transform: rotate(45deg);
					-webkit-transform: rotate(45deg);
				}
			}
		}
	}
	.select2-container--default.select2-container--open {
		.select2-selection--single {
			.select2-selection__arrow {
				b {
					border-color: $border-color_19;
					border-width: 2px 0 0 2px;
					padding: 3px;
					margin-top: -2px;
				}
			}
		}
	}
}
.modal.contentmodal {
	.modal-content {
		h3 {
			font-size: 24px;
		}
		.submit-section {
			.btn {
				padding: 7px 12px;
				border-radius: 8px;
				font-size: 16px;
				font-weight: 600;
				color: $color_41;
				min-width: 150px;
				margin-top: 5px;
			}
			.btn.btn-download {
				min-width: 200px;
			}
		}
	}
}
.delete-wrap {
	margin-bottom: 30px;
	h2 {
		font-size: 24px;
		margin-bottom: 25px;
	}
	p {
		font-size: 24px;
		margin-bottom: 20px;
	}
}
.modal-content {
	.close-btn {
		i {
			font-size: 20px;
			color: $color_40;
		}
	}
	border-radius: 10px;
}
.modal-content.doctor-profile {
	border-radius: 10px;
}
.del-icon {
	margin-bottom: 23px;
	margin-bottom: 23px;
	i {
		font-size: 60px;
		color: $color_40;
		margin-bottom: 23px;
	}
}
.submit-section {
	text-align: center;
	margin-top: 25px;
	text-align: center;
	margin-top: 30px;
}
.sidebar {
	background-color: $background-color_39;
	bottom: 0;
	left: 0;
	margin-top: 0;
	position: fixed;
	top: 0;
	transition: all 0.2s ease-in-out 0s;
	width: 270px;
	z-index: 1001;
	border-radius: 0 15px 0 0;
	.sidebar-menu {
		>ul {
			>li {
				>a {
					span {
						transition: all 0.2s ease-in-out 0s;
						display: inline-block;
						margin-left: 10px;
						white-space: nowrap;
					}
					span.chat-user {
						margin-left: 0;
						overflow: hidden;
						text-overflow: ellipsis;
					}
					span.badge {
						margin-left: auto;
					}
				}
			}
		}
	}
}
.sidebar.opened {
	-webkit-transition: all 0.4s ease;
	-moz-transition: all 0.4s ease;
	transition: all 0.4s ease;
}
.sidebar-inner {
	height: calc(100% - 90px);
	transition: all 0.2s ease-in-out 0s;
}
.sidebar-menu {
	padding: 10px;
	padding-bottom: 35px;
	ul {
		font-size: 15px;
		list-style-type: none;
		margin: 0;
		padding: 0;
		position: relative;
		ul {
			a.active {
				color: $color_9;
				text-decoration: underline;
			}
			a {
				display: block;
				font-size: 15px;
				padding: 7px 10px 7px 50px;
				position: relative;
				.menu-arrow {
					top: 10px;
				}
			}
			display: block;
			ul {
				a {
					padding-left: 65px;
				}
				ul {
					a {
						padding-left: 85px;
					}
				}
			}
		}
		li {
			a {
				i {
					display: inline-block;
					font-size: 18px;
					text-align: left;
					vertical-align: middle;
					width: 18px;
					transition: all 0.2s ease-in-out 0s;
				}
			}
		}
		li.menu-title {
			a {
				i {
					font-size: 16px !important;
					margin-right: 0;
					text-align: right;
					width: auto;
				}
			}
		}
	}
	li {
		a {
			color: $color_15;
			display: block;
			font-size: 15px;
			height: auto;
			padding: 0 20px;
			&:hover {
				color: $color_9;
			}
			>.badge {
				color: $color_15;
			}
		}
		a.subdrop {
			.menu-arrow {
				i {
					-ms-transform: rotate(90deg);
					-webkit-transform: rotate(90deg);
					-o-transform: rotate(90deg);
					transform: rotate(90deg);
				}
			}
		}
	}
	>ul {
		>li {
			>a {
				&:hover {
					background-color: $background-color_8;
					color: $color_15;
				}
				align-items: center;
				border-radius: 5px;
				display: flex;
				justify-content: flex-start;
				padding: 10px 20px;
				position: relative;
				transition: all 0.2s ease-in-out 0s;
			}
			>a.active {
				position: relative;
				color: $color_9;
				&:hover {
					color: $color_15;
				}
				&:after {
					background: #4C40ED;
					width: 6px;
					height: 40px;
					left: -10px;
					z-index: 9999;
					position: absolute;
					content: "";
					border-radius: 0 5px 5px 0;
				}
			}
			margin-bottom: 5px;
			position: relative;
			&:last-child {
				margin-bottom: 0;
			}
			>a.subdrop {
				background: transparent;
				color: $color_15;
			}
		}
		>li.active {
			>a {
				&:hover {
					background-color: $background-color_8;
					color: $color_15;
				}
			}
		}
		
	}
	li.active {
		>a {
			background-color: $background-color_8;
			color: $color_15;
		}
	}
	li.menu-title {
		a {
			color: $color_43;
			display: inline-block;
			margin-left: auto;
			padding: 0;
		}
		a.btn {
			color: $color_15;
			display: block;
			float: none;
			font-size: 15px;
			margin-bottom: 15px;
			padding: 10px 15px;
		}
	}
	.menu-arrow {
		i {
			-webkit-transition: -webkit-transform 0.15s;
			-o-transition: -o-transform 0.15s;
			transition: transform .15s;
			position: absolute;
			right: 15px;
			display: inline-block;
			text-rendering: auto;
			font-size: 18px;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			-webkit-transform: translate(0, 0);
			-ms-transform: translate(0, 0);
			-o-transform: translate(0, 0);
			transform: translate(0, 0);
			top: 12px;
			width: auto;
		}
	}
}
.siderbar-toggle {
	.switch {
		position: relative;
		display: inline-block;
		margin-bottom: 0;
		input {
			opacity: 0;
			width: 0;
			height: 0;
		}
	}
	position: relative;
	.slider {
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: #CCCFE0;
		-webkit-transition: .4s;
		transition: .4s;
		width: 41px;
		height: 21px;
		&:before {
			position: absolute;
			content: "";
			height: 17px;
			width: 17px;
			left: 4px;
			bottom: 2px;
			background-color: $background-color_40;
			-webkit-transition: .4s;
			transition: .4s;
		}
	}
	input {
		&:checked {
			+ {
				.slider {
					background-color: $background-color_8;
					&:before {
						-webkit-transform: translateX(26px);
						-ms-transform: translateX(26px);
						transform: translateX(26px);
						left: -5px;
					}
				}
			}
		}
		&:focus {
			+ {
				.slider {
					box-shadow: 0 0 1px #4C40ED;
				}
			}
		}
	}
	.slider.round {
		border-radius: 34px;
		&:before {
			border-radius: 50%;
		}
	}
}
.sidebar-header {
	display: flex;
	align-items: center;
	width: 100%;
	padding: 27px;
	justify-content: space-between;
}
.form-links {
	a {
		color: $color_44;
		text-decoration: underline;
		font-size: 14px;
	}
}
.form-groupbtn {
	ul {
		padding: 0;
		margin: 0;
		list-style: none;
		display: flex;
		align-items: center;
		li {
			margin-right: 12px;
		}
	}
	.btn {
		min-width: 104px;
		padding: 7px 15px;
		font-size: 16px;
	}
}
.btn-cancel {
	border: 1px solid #F3F3F3;
	color: $color_45;
	background: #F3F3F3;
	&:hover {
		background: #242424;
		color: $color_15;
	}
}
.card-heads {
	display: flex;
	align-items: center;
	justify-content: space-between;
	display: flex;
}
.mini-sidebar {
	.siderbar-toggle {
		opacity: 0;
		width: 1px;
		.slider {
			background: #4C40ED;
			&:before {
				transform: translateX(26px);
				left: -5px;
			}
		}
	}
	.logo-small {
		display: block;
		min-width: 30px;
	}
	.sidebar-header {
		padding: 15px 0;
		justify-content: center;
	}
	.logo {
		display: none;
	}
	
}
.mini-sidebar.expand-menu {
	.sidebar-header {
		padding: 27px;
		justify-content: space-between;
	}
	.logo {
		display: block;
	}
	.logo-small {
		display: none;
	}
	.siderbar-toggle {
		opacity: 1;
		width: auto;
	}
}
.logo-small {
	display: none;
}
.main-wrapper {
	width: 100%;
	height: 100vh;
	min-height: 100vh;
	background-color: $background-color_1;
	height: auto;
	.header {
		background: #fff;
		border-bottom: 1px solid #E5E9F2;
		left: 270px;
		position: fixed;
		right: 0;
		top: 0;
		border-radius: 0;
		padding: 15px;
	}
}
.page-wrapper {
	margin-left: 270px;
	padding-top: 60px;
	position: relative;
	transition: all 0.4s ease;
	background-color: $background-color_1;
	padding-top: 76px;
	min-height: calc(100vh - 40px);
	>.content {
		padding: 30px;
		width: 100%;
	}
}
.page-header {
	margin-bottom: 1.875rem;
	.breadcrumb {
		background-color: $background-color_22;
		color: $color_46;
		font-size: 1rem;
		font-weight: 500;
		margin-bottom: 0;
		padding: 0;
		a {
			color: $color_8;
		}
	}
}
.page-title {
	color: $color_8;
	margin-bottom: 5px;
}
.login-pages {
	padding: 40px 70px;
}
.login-logo {
	margin-bottom: 80px;
	text-align: center;
}
.login-input {
	.form-group {
		label {
			color: $color_26;
			font-size: 12px;
		}
	}
	.form-control {
		background: #fff;
		border: 1px solid #E4E4E8;
		&:focus {
			border: 1px solid #4C40ED;
			border-left: 5px solid #4C40ED;
		}
	}
}
.login-contenthead {
	h5 {
		font-size: 30px;
		font-weight: 700;
		margin-bottom: 18px;
	}
	h6 {
		font-size: 14px;
		color: $color_47;
		font-weight: 400;
		margin-bottom: 18px;
	}
	p {
		margin: 0;
		color: $color_48;
		font-weight: 400;
		a {
			color: $color_49;
			text-decoration: underline;
		}
	}
	margin-bottom: 60px;
}
.flag-nav {
	img {
		min-width: 18px;
		height: 18px;
		width: 18px;
	}
}
.forgetpassword-link {
	color: $color_49;
	text-decoration: underline;
}
.tab-sets {
	border-bottom: 2px solid #E7E7E7;
	padding-bottom: 13px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.tab-contents-sets {
	ul {
		display: flex;
		align-items: center;
		li {
			+li {
				padding-left: 35px;
			}
			a {
				color: $color_1;
				font-weight: 500;
				position: relative;
				top: 4px;
				&:hover {
					&::after {
						position: absolute;
						content: "";
						background: #4C40ED;
						width: 100%;
						height: 2px;
						left: 0;
						top: 29px;
					}
					color: $color_50;
				}
			}
			a.active {
				color: $color_9;
				&::after {
					position: absolute;
					content: "";
					background: #4C40ED;
					width: 100%;
					height: 2px;
					left: 0;
					top: 29px;
				}
			}
		}
	}
}
.table-div {
	padding-top: 40px;
}
.tab-contents-count {
	h6 {
		margin: 0;
		font-size: 14px;
		color: $color_1;
		font-weight: normal;
	}
}
.table-imgname {
	align-items: center;
	display: inline-flex;
	font-size: inherit;
	font-weight: 400;
	margin: 0;
	padding: 0;
	vertical-align: middle;
	white-space: nowrap;
	padding-right: 30px;
	img {
		width: 40px;
		height: 40px;
		border-radius: 5px;
	}
	span {
		color: $color_1;
	}
	&:hover {
		span {
			color: $color_9;
		}
	}
}
.badge-pending {
	background: #FFF9E8;
	padding: 6px;
	min-width: 80px;
	margin: 0;
	font-size: 13px;
	color: $color_51;
	border-radius: 50px;
	display: inline-block;
	text-align: center;
}
.delete-table {
	&:hover {
		img {
			filter: brightness(0) invert(1);
		}
		background: #4C40ED;
		color: $color_15;
	}
	background: #F5F5F5;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 36px;
	height: 36px;
	border-radius: 50px;
	color: $color_1;
}
.badge-inactive {
	background: #F1F8FF;
	padding: 6px;
	min-width: 80px;
	margin: 0;
	font-size: 13px;
	color: $color_52;
	border-radius: 50px;
	display: inline-block;
	text-align: center;
}
.badge-active {
	background: #F2FFF6;
	padding: 6px;
	min-width: 80px;
	margin: 0;
	font-size: 13px;
	color: $color_53;
	border-radius: 50px;
	display: inline-block;
	text-align: center;
	&:hover {
		background: #F2FFF6;
		padding: 6px;
		min-width: 80px;
		margin: 0;
		font-size: 13px;
		color: $color_53;
		border-radius: 50px;
		display: inline-block;
		text-align: center;
	}
}
.badge-delete {
	background: #FFF3F3;
	padding: 6px;
	min-width: 80px;
	margin: 0;
	font-size: 13px;
	color: $color_54;
	border-radius: 50px;
	display: inline-block;
	text-align: center;
}
.check {
	display: block;
	margin: 0;
	padding: 0;
	width: 0;
	height: 0;
	visibility: hidden;
	opacity: 0;
	pointer-events: none;
	position: absolute;
	display: block;
	margin: 0;
	padding: 0;
	width: 0;
	height: 0;
	visibility: hidden;
	opacity: 0;
	pointer-events: none;
	position: absolute;
	&:checked {
		+ {
			.checktoggle {
				background-color: $background-color_42;
				background-color: $background-color_42;
				&:after {
					left: 100%;
					transform: translate(calc(-100% - 5px), -50%);
					left: 100%;
					transform: translate(calc(-100% - 5px), -50%);
				}
			}
			.checktoggle.checkbox-bg {
				&::after {
					background-color: $background-color_1;
				}
			}
		}
	}
}
.checktoggle {
	background-color: $background-color_41;
	border-radius: 12px;
	cursor: pointer;
	display: block;
	font-size: 0;
	height: 24px;
	margin-bottom: 0;
	position: relative;
	width: 48px;
	background-color: $background-color_41;
	border-radius: 12px;
	cursor: pointer;
	display: block;
	font-size: 0;
	height: 24px;
	margin-bottom: 0;
	position: relative;
	width: 48px;
	&:after {
		content: ' ';
		display: block;
		position: absolute;
		top: 50%;
		left: 0;
		transform: translate(5px, -50%);
		width: 16px;
		height: 16px;
		background-color: $background-color_1;
		border-radius: 50%;
		transition: left 300ms ease, transform 300ms ease;
		content: ' ';
		display: block;
		position: absolute;
		top: 50%;
		left: 0;
		transform: translate(5px, -50%);
		width: 16px;
		height: 16px;
		background-color: $background-color_1;
		border-radius: 50%;
		transition: left 300ms ease, transform 300ms ease;
	}
}
.onoffswitch {
	margin-left: auto;
	position: relative;
	width: 73px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	margin-left: auto;
	position: relative;
	width: 73px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
}
.onoffswitch-checkbox {
	display: none;
	display: none;
	&:checked {
		+ {
			.onoffswitch-label {
				.onoffswitch-inner {
					margin-left: 0;
				}
				.onoffswitch-switch {
					right: 0px;
				}
			}
		}
	}
}
.onoffswitch-label {
	display: block;
	overflow: hidden;
	cursor: pointer;
	border-radius: 20px;
	margin-bottom: 0;
	display: block;
	overflow: hidden;
	cursor: pointer;
	border-radius: 20px;
	margin-bottom: 0;
}
.onoffswitch-inner {
	display: block;
	margin-left: -100%;
	transition: margin 0.3s ease-in 0s;
	width: 200%;
	&:before {
		box-sizing: border-box;
		color: $color_15;
		display: block;
		float: left;
		font-size: 16px;
		height: 30px;
		line-height: 32px;
		padding: 0;
		width: 50%;
		background-color: $background-color_42;
		color: $color_15;
		content: "ON";
		padding-left: 14px;
	}
	&:after {
		box-sizing: border-box;
		color: $color_15;
		display: block;
		float: left;
		font-size: 16px;
		height: 30px;
		line-height: 32px;
		padding: 0;
		width: 50%;
		content: "OFF";
		padding-right: 14px;
		background-color: $background-color_43;
		color: $color_15;
		text-align: right;
	}
}
.onoffswitch-switch {
	background: #fff;
	border-radius: 20px;
	bottom: 0;
	display: block;
	height: 20px;
	margin: 5px;
	position: absolute;
	right: 43px;
	top: 0;
	transition: all 0.3s ease-in 0s;
	width: 20px;
}
.password-strength {
	display: flex;
	margin-top: 15px;
	span {
		width: 100%;
		height: 5px;
		background: #E3E3E3;
		margin-right: 10px;
		border-radius: 5px;
		&:last-child {
			margin-right: 0;
		}
	}
}
.weak-pass {
	.password-strength {
		span {
			background: #FF0000;
		}
	}
}
.custom-modal {
	.modal-body {
		padding: 20px 20px 0px 20px;
	}
	.modal-content {
		border: 0;
		border-radius: 10px;
	}
	.modal-footer {
		border: 0;
		justify-content: center;
		padding: 0 30px 30px;
	}
}
.login-wrapper {
	width: 100%;
	height: 100%;
	display: table-cell;
	vertical-align: middle;
}
.loginbox {
	background-color: $background-color_1;
	border-radius: 10px;
	box-shadow: 0px 0px 12px 0px rgba(55, 73, 72, 0.02);
	max-width: 500px;
	padding: 40px;
	width: 100%;
}
.account-logo {
	img {
		max-height: 80px;
		width: auto;
	}
}
.notifications {
	padding: 0;
	.notification-time {
		font-size: 12px;
		line-height: 1.35;
		color: $color_55;
	}
	.media {
		margin-top: 0;
		border-bottom: 1px solid #f5f5f5;
		&:last-child {
			border-bottom: none;
		}
		a {
			display: block;
			padding: 10px 15px;
			border-radius: 2px;
			&:hover {
				background-color: $background-color_34;
			}
		}
		>.avatar {
			margin-right: 10px;
		}
	}
	.media-list {
		.media-left {
			padding-right: 8px;
		}
	}
	.noti-content {
		height: 290px;
		width: 350px;
		overflow-y: auto;
		position: relative;
	}
	ul.notification-list {
		>li {
			margin-top: 0;
			border-bottom: 1px solid #f5f5f5;
			&:last-child {
				border-bottom: none;
			}
			a {
				display: block;
				padding: 20px 15px;
				border-radius: 2px;
				&:hover {
					background-color: $background-color_34;
				}
			}
			.list-item {
				border: 0;
				padding: 0;
				position: relative;
			}
		}
	}
}
.topnav-dropdown-header {
	border-bottom: 1px solid #eee;
	text-align: center;
	font-size: 14px;
	height: 40px;
	line-height: 40px;
	padding-left: 15px;
	padding-right: 15px;
	.notification-title {
		color: $color_8;
		display: block;
		float: left;
		font-size: 14px;
	}
	.clear-noti {
		color: $color_57;
		float: right;
		font-size: 12px;
		text-transform: uppercase;
	}
}
.topnav-dropdown-footer {
	font-size: 14px;
	height: 40px;
	line-height: 40px;
	padding-left: 15px;
	padding-right: 15px;
	border-top: 1px solid #eee;
	a {
		display: block;
		text-align: center;
		color: $color_8;
	}
}
.noti-details {
	color: $color_56;
	margin-bottom: 0;
}
.noti-title {
	color: $color_8;
}
.notification-list {
	list-style: none;
	padding: 0;
	margin: 0;
}
.noti-time {
	margin: 0;
}
.dash-widget-icon {
	align-items: center;
	border-radius: 10px;
	color: $color_15;
	display: inline-flex;
	font-size: 40px;
	height: 80px;
	justify-content: center;
	text-align: center;
	width: 80px;
	background-color: $background-color_8;
}
.dash-count {
	font-size: 18px;
	margin-left: auto;
}
.dash-widget-info {
	margin-left: auto;
	text-align: right;
	h3 {
		margin-bottom: 10px;
		font-size: 1.5rem;
	}
	h6 {
		margin-bottom: 0;
	}
}
.dash-widget-header {
	align-items: center;
	display: flex;
}
.back-btn {
	&:hover {
		color: $color_26;
	}
	font-weight: 600;
	font-size: 16px;
	color: $color_9;
	display: inline-block;
	margin-bottom: 18px;
}
.submit-btn {
	background-color: $background-color_45;
	color: $color_15;
	border-radius: 5px;
	font-size: 16px;
	font-weight: 600;
	min-width: 200px;
	padding: 10px 20px;
	&:hover {
		background-color: $background-color_46;
	}
}
.profile-menu {
	background-color: $background-color_1;
	box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
	padding: 0.9375rem 1.5rem;
	.nav-tabs.nav-tabs-solid {
		background-color: $background-color_22;
	}
}
.profilemenu {
	background: #ffff;
	border-radius: 10px;
	min-width: 210px;
	a {
		&:hover {
			color: $color_59;
		}
	}
}
.user-detials {
	a {
		display: flex;
		color: $color_58;
		align-items: center;
		text-align: left;
		padding: 15px 10px;
		border-bottom: 1px solid #d5d5d5d5;
		span.profile-image {
			img {
				width: 40px;
				height: 40px;
				margin-right: 10px;
				display: block;
				border-radius: 50px;
			}
		}
		span.profile-content {
			flex-direction: column;
			align-items: start;
			padding-left: 15px;
			h5 {
				font-size: 16px;
				margin: 0;
			}
			h6 {
				margin: 0;
			}
		}
	}
}
.subscription-detials {
	padding: 15px 10px;
	border-bottom: 1px solid #d5d5d5;
}
.subscription-menu {
	ul {
		padding: 10px;
		margin: 0;
		list-style: none;
		border-bottom: 1px solid #d5d5d5;
		li {
			margin: 5px 0;
			a {
				color: $color_8;
				&:hover {
					color: $color_9;
				}
			}
		}
	}
}
.subscription-logout {
	a {
		padding: 10px;
		color: $color_8;
		display: block;
	}
}
.property-listset {
	padding: 20px;
}
.user-view {
	border: 1px solid #d5d5d5;
	margin-bottom: 25px;
}
.user-viewhead {
	padding: 15px;
	border-bottom: 1px solid #d5d5d5;
	display: flex;
}
.product-help {
	background: #fff;
	position: fixed;
	left: -500px;
	top: 0;
	height: 100vh;
	width: 400px;
	z-index: 10000;
	transition: all 0.5s;
}
.sidebar-logo {
	display: flex;
	justify-content: center;
	img {
		max-height: 46px;
		width: auto;
	}
}
.location-set {
	margin-bottom: 25px;
}
.location-setcontent {
	h5 {
		font-size: 16px;
		color: $color_7;
		font-weight: 500;
		margin: 0 0 5px;
	}
	h6 {
		font-size: 14px;
		color: $color_1;
		font-weight: 400;
		margin: 0;
	}
}
.pricing-box {
	.pricing-selected {
		.card {
			background-color: $background-color_8;
			border-color: $border-color_1;
			color: $color_15;
		}
		.pricing-header {
			h2 {
				color: $color_15;
			}
			p {
				color: $color_15;
			}
		}
		.pricing-card-price {
			.heading2 {
				color: $color_15;
			}
			p {
				color: $color_15;
			}
		}
		.btn {
			background-color: $background-color_1;
			color: $color_9;
		}
		.pricing-options {
			li {
				color: $color_15;
			}
		}
	}
	.pricing-header {
		margin-bottom: 2rem;
		h2 {
			margin-bottom: 0;
			font-size: 1.25rem;
			font-weight: 500;
			line-height: 1.2;
		}
		p {
			color: $color_60;
			font-size: .875rem;
			line-height: 1.43;
		}
	}
	.pricing-card-price {
		margin-bottom: 1.563rem;
		.heading2 {
			margin-bottom: 5px;
		}
		p {
			color: $color_60;
			+ {
				p {
					margin: 0;
				}
			}
		}
	}
	.pricing-options {
		list-style: none;
		padding: 0;
		margin-bottom: 1.875rem;
		li {
			margin-bottom: 12px;
			font-size: 0.875rem;
			line-height: 1.43;
			position: relative;
		}
	}
}
.content-sidelink {
	min-width: 289px;
	background: #FBFDFF;
	padding: 25px;
	float: left;
}
.content-sidelinkheading {
	margin-bottom: 20px;
	h6 {
		font-size: 18px;
		color: $color_7;
		font-weight: 500;
	}
}
.content-sidelinkmenu {
	ul {
		list-style: none;
		padding: 0;
		margin: 0 0 20px;
		li {
			h5 {
				font-size: 15px;
				color: $color_61;
				font-weight: 500;
				margin-bottom: 15px;
				a {
					color: $color_61;
					font-weight: 500;
					margin-bottom: 15px;
					padding: 0;
				}
			}
			a {
				color: $color_4;
				font-size: 14px;
				padding-left: 20px;
				font-weight: 400;
				&:hover {
					color: $color_9;
				}
			}
			h4 {
				color: $color_4;
				font-size: 13px;
				padding-left: 20px;
				margin-bottom: 0;
				font-weight: 500;
			}
			margin-bottom: 10px;
			&:last-child {
				margin: 0;
			}
			a.active {
				color: $color_9;
			}
		}
		li.submenu-sidelink {
			a {
				padding-left: 40px;
			}
		}
	}
}
.symbol-input {
	position: relative;
	>span {
		position: absolute;
		background: #F2F2F2;
		border-radius: 6px;
		width: 36px;
		height: 36px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 20px;
		top: 5px;
		right: 5px;
	}
}
.group-image {
	.form-control {
		padding-left: 30px;
	}
	img {
		position: absolute;
		z-index: 99;
		top: 15px;
		left: 10px;
	}
	position: relative;
}
.page-settings {
	display: flex;
}
.content-page-header {
	margin-bottom: 15px;
	margin-bottom: 30px;
	h5 {
		font-size: 20px;
		font-weight: 500;
		margin: 0;
	}
}
.add-button {
	box-shadow: 0px 0px 12px 0px rgba(255, 0, 128, 0.2);
	width: 45px;
	height: 45px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	border-radius: 10px;
}
.filter-btn {
	box-shadow: 0px 0px 12px 0px rgba(55, 73, 72, 0.02);
	border: 0;
	width: 45px;
	height: 45px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	color: $color_9;
	border-radius: 10px;
	&:hover {
		background-color: $background-color_8;
		color: $color_15;
	}
	&:focus {
		background-color: $background-color_8;
		color: $color_15;
	}
}
.filter-card {
	.form-group {
		margin-bottom: 1.5rem;
		margin-bottom: 1.5rem;
		position: relative;
		label {
			position: absolute;
			background: white;
			font-size: 12px;
			left: 10px;
			top: -7px;
			padding: 0 7px;
			color: $color_66;
			z-index: 10009;
		}
	}
	.form-control {
		height: 48px;
	}
	.btn {
		height: 48px;
	}
	.select2-container {
		.select2-selection--single {
			height: 48px;
		}
	}
	.select2-container--default {
		.select2-selection--single {
			.select2-selection__rendered {
				line-height: 46px;
			}
			.select2-selection__arrow {
				height: 46px;
			}
		}
	}
}
.has-error {
	.help-block {
		color: $color_62;
	}
}
small.help-block {
	color: $color_63 !important;
}
.toggle.ios {
	border-radius: 20px;
	.toggle-handle {
		border-radius: 20px;
	}
}
.toggle-on.ios {
	border-radius: 20px;
}
.toggle-off.ios {
	border-radius: 20px;
}
.service-header {
	margin-bottom: 30px;
	display: flex;
	align-items: center;
	h2 {
		font-weight: bold;
	}
}
.service-cate {
	a {
		color: $color_15;
		padding: 2px 10px;
		text-transform: uppercase;
		background: #d9c505;
		border-radius: 4px;
		font-size: 13px;
		display: inline-block;
	}
}
.service-images {
	margin-bottom: 30px;
	position: relative;
	margin-bottom: 45px;
	img {
		width: 100%;
		border-radius: 10px;
	}
}
.widget {
	margin-bottom: 30px;
}
.service-amount {
	color: $color_9;
	font-size: 36px;
	font-weight: 700;
	line-height: 40px;
	margin-left: auto;
	p {
		i {
			color: $color_133;
		}
	}
	h5 {
		span {
			color: $color_1;
			font-size: 20px;
			margin-left: 5px;
			text-decoration: line-through;
		}
	}
	.serv-review {
		font-size: 12px;
		font-weight: 500;
		color: $color_1;
		margin-bottom: 0;
		span {
			font-size: 16px;
			font-weight: 600;
			color: $color_7;
		}
	}
}
.about-author {
	min-height: 80px;
	display: flex;
	display: -ms-flexbox;
	align-items: center;
}
.about-provider-img {
	background-color: $background-color_1;
	height: 80px;
	overflow: hidden;
	position: absolute;
	width: 80px;
}
.provider-details {
	margin-left: 100px;
}
.ser-provider-name {
	display: inline-block;
	margin-bottom: 5px;
	color: $color_64;
	font-size: 16px;
	font-weight: 600;
}
.last-seen {
	display: flex;
	align-items: center;
	margin-bottom: 5px;
	i {
		color: $color_19;
		margin-right: 5px;
		font-size: 12px;
	}
	i.online {
		color: $color_65;
	}
}
.provider-info {
	background-color: $background-color_37;
	padding: 15px;
	border-radius: 4px;
}
.available-widget {
	ul {
		padding-left: 0;
		margin-bottom: 0;
		list-style-type: none;
		li {
			color: $color_66;
			overflow: hidden;
			text-align: right;
			padding-top: 10px;
			padding-bottom: 10px;
			span {
				float: left;
			}
			&:first-child {
				padding-top: 0;
			}
			&:last-child {
				border-bottom: none;
				padding-bottom: 0;
			}
		}
	}
}
.rating {
	list-style: none;
	margin: 0 0 7px;
	padding: 0;
	width: 100%;
	i {
		color: $color_67;
	}
	i.filled {
		color: $color_68;
	}
}
.service-widget {
	.rating {
		color: $color_21;
		font-size: 14px;
		margin-bottom: 15px;
		i {
			font-size: 14px;
		}
	}
	background-color: $background-color_1;
	border: 2px solid rgba(102, 102, 102, 0.09);
	border-radius: 10px;
	margin-bottom: 30px;
	position: relative;
	-webkit-transition: all .3s ease 0s;
	-moz-transition: all .3s ease 0s;
	-o-transition: all .3s ease 0s;
	transition: all .3s ease 0s;
	&:hover {
		.service-img {
			.serv-img {
				-webkit-transform: scale(1.15);
				-moz-transform: scale(1.15);
				transform: scale(1.15);
			}
		}
	}
	.serv-info {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		justify-content: space-between;
		-webkit-justify-content: space-between;
		-ms-flex-pack: space-between;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		justify-content: space-between;
		-webkit-justify-content: space-between;
		-ms-flex-pack: space-between;
		h6 {
			font-weight: 700;
			font-size: 22px;
			margin-bottom: 0;
			font-weight: 700;
			font-size: 22px;
			margin-bottom: 0;
		}
		.serv-users {
			h6 {
				font-size: 16px;
				color: $color_7;
				margin-bottom: 16px;
				span {
					color: $color_1;
					font-size: 13px;
					margin-left: 15px;
					font-weight: 400;
				}
			}
		}
	}
	.service-content {
		.title {
			a {
				&:hover {
					color: $color_9;
					color: $color_9;
				}
			}
		}
	}
	.btn-book {
		background: #F7F7FF;
		border-radius: 8px;
		font-weight: 500;
		padding: 6px 15px;
		box-shadow: inset 0 0 0 0 #ffffff;
		-webkit-transition: all 0.7s;
		-moz-transition: all 0.7s;
		-o-transition: all 0.7s;
		transition: all 0.7s;
		&:hover {
			background: #fff;
			border-radius: 8px;
			border: 1px solid #4c40ed;
			box-shadow: inset 0 70px 0 0 #fff;
			color: $color_50;
			-webkit-transition: all 0.7s;
			-moz-transition: all 0.7s;
			-o-transition: all 0.7s;
			transition: all 0.7s;
		}
	}
	.fav-item {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		padding: 15px 15px;
		z-index: 1;
	}
}
.nav-tabs.menu-tabs {
	margin-bottom: 30px;
	.nav-item.active {
		.nav-link {
			color: $color_9;
			&::after {
				opacity: 1;
			}
		}
	}
	.nav-item {
		padding-right: 2.25rem;
	}
	.nav-link {
		padding: 0 0 1rem;
		font-weight: 700;
		position: relative;
		border: none;
		outline: none;
		display: inline-flex;
		align-items: center;
		background: transparent;
		&::before {
			position: absolute;
			content: '';
		}
		&::after {
			left: 0;
			right: 0;
			bottom: 0;
			height: 3px;
			background: #4C40ED;
			opacity: 0;
		}
		&:focus {
			background: transparent;
			color: $color_9;
		}
		.badge {
			margin-left: 0.25rem;
		}
	}
}
.noti-dropdown {
	>a {
		display: inline-flex;
		position: relative;
		z-index: 1;
		padding: 6px !important;
		min-width: 36px;
		&::before {
			position: absolute;
			z-index: -1;
			height: 20px;
			width: 20px;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			transform-origin: 50% 50%;
			content: '';
			background-color: $background-color_47;
			border-radius: 50%;
			opacity: 0;
			transition: all .3s;
		}
		&:hover {
			&::before {
				opacity: 1;
				height: 40px;
				width: 40px;
			}
		}
	}
}
.login-page {
	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		z-index: -1;
		pointer-events: none;
		height: 50vh;
		background-color: $background-color_8;
		border-radius: 0 0 2.5rem 2.5rem;
		z-index: 0;
	}
	background-color: $background-color_35;
	position: relative;
	padding: 0;
	margin: -20px -20px -20px 0;
}
.login-body {
	z-index: 10;
	position: relative;
	align-items: center;
	justify-content: center;
	padding: 15px;
	min-height: 100vh;
	display: flex;
	.form-control {
		height: 50px;
		&::placeholder {
			color: $color_69;
			opacity: 1;
		}
	}
}
.login-header {
	margin-bottom: 20px;
	p {
		margin-bottom: 0;
	}
	h3 {
		font-size: 20px;
		margin-bottom: 0;
		text-transform: uppercase;
		span {
			color: $color_9;
		}
	}
}
.account-btn {
	font-size: 20px;
	font-weight: 500;
	display: block;
	width: 100%;
	color: $color_15;
	padding: 10px 20px;
	border-radius: 5px;
	text-align: center;
}
#toggle_btn {
	padding: 0 20px;
}
.service-carousel {
	.owl-nav {
		width: 100%;
		opacity: 0;
		visibility: hidden;
		-webkit-transition: all 0.3s ease;
		-moz-transition: all 0.3s ease;
		transition: all 0.3s ease;
		margin: 0;
	}
	&:hover {
		.owl-nav {
			opacity: 1;
			visibility: visible;
		}
	}
	.owl-carousel {
		.owl-nav {
			div {
				height: 48px;
				width: 48px;
				line-height: 48px;
				top: 50%;
				background: #666;
				color: $color_15;
				font-size: 25px;
				position: absolute;
				border: none;
				margin: 0;
				padding: 0;
				-webkit-transition: all 0.4s ease;
				-moz-transition: all 0.4s ease;
				transition: all 0.4s ease;
				border-radius: 100%;
				margin-top: -24px;
				&:hover {
					color: $color_15;
				}
			}
			.owl-prev {
				left: -75px;
				&:hover {
					left: -65px;
				}
			}
			.owl-next {
				right: -75px;
				&:hover {
					right: -65px;
				}
			}
		}
	}
	.owl-dots {
		margin-top: 50px;
		.owl-dot {
			margin-right: 5px;
			&:last-child {
				margin-right: 0px;
			}
			span {
				width: 12px;
				height: 12px;
				line-height: 12px;
				margin: 0;
				-webkit-transition: all 0.4s ease;
				-moz-transition: all 0.4s ease;
				transition: all 0.4s ease;
			}
		}
		.owl-dot.active {
			span {
				border-color: $border-color_3;
				width: 30px;
				height: 12px;
			}
		}
	}
}
.active-switch {
	.switch {
		position: relative;
		display: inline-block;
		width: 41px;
		height: 21px;
		margin: 0;
		input {
			opacity: 0;
			width: 0;
			height: 0;
		}
	}
	.sliders {
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: $background-color_43;
		-webkit-transition: .4s;
		transition: .4s;
		&:before {
			position: absolute;
			content: "";
			height: 17px;
			width: 17px;
			left: 3px;
			bottom: 2px;
			background-color: $background-color_40;
			-webkit-transition: .4s;
			transition: .4s;
		}
	}
	input {
		&:checked {
			+ {
				.sliders {
					&:before {
						left: -5px;
						-webkit-transform: translateX(26px);
						-ms-transform: translateX(26px);
						transform: translateX(26px);
					}
					background-color: $background-color_48;
				}
			}
		}
		&:focus {
			+ {
				.sliders {
					box-shadow: 0 0 1px #00CC45;
				}
			}
		}
	}
	.sliders.round {
		border-radius: 34px;
		&:before {
			border-radius: 50%;
		}
	}
	.onoffswitch-inner {
		display: block;
		margin-left: -100%;
		transition: margin 0.3s ease-in 0s;
		width: 200%;
		&:before {
			box-sizing: border-box;
			color: $color_15;
			display: block;
			float: left;
			font-size: 16px;
			height: 30px;
			line-height: 32px;
			padding: 0;
			width: 50%;
			background-color: $background-color_42;
			color: $color_15;
			content: "ON";
			padding-left: 14px;
		}
		&:after {
			box-sizing: border-box;
			color: $color_15;
			display: block;
			float: left;
			font-size: 16px;
			height: 30px;
			line-height: 32px;
			padding: 0;
			width: 50%;
			content: "OFF";
			padding-right: 14px;
			background-color: $background-color_43;
			color: $color_15;
			text-align: right;
		}
	}
	.onoffswitch-switch {
		background: #fff;
		border-radius: 20px;
		bottom: 0;
		display: block;
		height: 20px;
		margin: 5px;
		position: absolute;
		right: 43px;
		top: 0;
		transition: all 0.3s ease-in 0s;
		width: 20px;
	}
	.onoffswitch-checkbox {
		&:checked {
			+ {
				.onoffswitch-label {
					.onoffswitch-inner {
						margin-left: 0;
					}
					.onoffswitch-switch {
						right: 0px;
					}
				}
			}
		}
	}
	h6 {
		font-size: 12px;
		font-weight: 500;
		margin: 0 10px 0 0;
	}
}
.connectetapps {
	margin-bottom: 30px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.pass-group {
	position: relative;
	.toggle-password {
		transform: translateY(-50%);
		cursor: pointer;
		width: 30px;
		height: 30px;
		color: $color_71;
		font-size: 14px;
		position: absolute;
		top: 50%;
		right: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.toggle-passworda {
		transform: translateY(-50%);
		cursor: pointer;
		width: 30px;
		height: 30px;
		color: $color_71;
		font-size: 14px;
		position: absolute;
		top: 50%;
		right: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.toggle-passwords {
		transform: translateY(-50%);
		cursor: pointer;
		width: 30px;
		height: 30px;
		color: $color_71;
		font-size: 14px;
		position: absolute;
		top: 50%;
		right: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
.change-passwordback {
	a {
		margin-bottom: 40px;
		display: block;
		color: $color_70;
		font-size: 15px;
	}
}
.modal-header {
	h5 {
		font-size: 20px;
		font-weight: 500;
	}
	border-bottom: 0;
	padding: 24px;
	.close-modal {
		background: #F1F1F1;
		border: 0;
		border-radius: 50%;
		color: $color_86;
		font-size: 15px;
		width: 29px;
		height: 29px;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		justify-content: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		margin: 0;
		opacity: 1;
		padding: 0;
		position: absolute;
		right: 20px;
		top: 20px;
		padding: 0;
		z-index: 99;
		&:hover {
			background: #4C40ED;
			color: $color_15 !important;
		}
	}
}
.change-password {
	display: flex;
	flex-direction: column;
	max-width: 360px;
	margin: auto;
	padding: 30px 15px;
	height: 100vh;
	justify-content: center;
	overflow: auto;
}
.change-passwordhead {
	h3 {
		font-size: 20px;
		font-weight: 500;
	}
	p {
		font-size: 14px;
		color: $color_4;
	}
	margin-bottom: 35px;
}
.connectetappsimg {
	display: flex;
	align-items: center;
}
.connectet-img {
	width: 46px;
	height: 46px;
	background: #eee;
	padding: 10px;
	border-radius: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.connectet-content {
	margin-left: 15px;
	h3 {
		font-size: 16px;
		font-weight: 500;
	}
	p {
		font-size: 13px;
		margin: 0;
		color: $color_4;
		span {
			color: $color_7;
		}
	}
}
.connectetappscontent {
	display: flex;
	align-items: center;
	.active-switch {
		margin-left: 90px;
	}
	ul {
		display: flex;
		align-items: center;
		list-style: none;
		li {
			margin: 0 5px;
			&:first-child {
				margin-right: 25px;
			}
		}
	}
	.btn-linedark {
		min-width: 150px;
		border: 1px solid #AAACB8;
		font-size: 14px;
		color: $color_7;
		&:hover {
			background: #28283C;
			color: $color_15;
		}
	}
}
.transfer-lists {
	.theme-image-content {
		display: none;
		position: absolute;
		top: 0;
		right: 0;
		i {
			color: $color_72;
		}
	}
	&::after {
		position: absolute;
		content: "";
		background: rgba(76, 64, 237, 0.03);
		width: 149px;
		height: 149px;
		top: 0;
		border-radius: 50%;
		left: -110px;
	}
	&::before {
		position: absolute;
		content: "";
		background: rgba(76, 64, 237, 0.03);
		width: 149px;
		height: 149px;
		top: -60px;
		border-radius: 50%;
		right: 0px;
	}
	background: #F8F8FE;
	padding: 20px;
	border-radius: 8px;
	margin-bottom: 15px;
	position: relative;
	overflow: hidden;
	border: 2px solid #F8F8FE;
}
.transfer-lists.active {
	.theme-image-content {
		display: block;
	}
	border-color: $border-color_20;
}
.success-labels {
	background: #F2FFF6;
	padding: 10px 20px;
	margin: 0;
	font-size: 14px;
	color: $color_53;
	border-radius: 50px;
	display: inline-block;
}
.delete-labels {
	background: #FFF3F3;
	padding: 7px 23px;
	margin: 0;
	font-size: 14px;
	color: $color_54;
	border-radius: 50px;
	display: inline-block;
}
.noti-contents {
	display: flex;
	flex-direction: column;
	margin-left: 15px;
	color: $color_73;
	justify-content: center;
}
#filter_inputs {
	display: none;
}
textarea.form-control.content-textarea {
	height: 100px;
	min-height: auto;
}
.service-img {
	position: relative;
	overflow: hidden;
	z-index: 1;
	border-radius: 10px 10px 0 0;
	.serv-img {
		border-radius: 10px 10px 0 0;
		-webkit-transform: translateZ(0);
		-moz-transform: translateZ(0);
		transform: translateZ(0);
		-moz-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
		-ms-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
		-o-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
		-webkit-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
		transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
		width: 100%;
	}
}
.action-search {
	a {
		color: $color_74;
		font-weight: 500;
		&:hover {
			color: $color_9;
		}
	}
}
.serv-info {
	i {
		margin-right: 5px;
	}
}
a.serv-edit {
	margin-right: 30px;
}
.service-widget-image {
	display: flex;
	align-items: center;
	margin-bottom: 10px;
	h6 {
		margin-left: 20px;
		font-size: 13px;
		font-weight: 400;
		margin-bottom: 0;
		img {
			margin-right: 6px;
		}
	}
}
.service-content {
	padding: 15px;
	.title {
		font-weight: 500;
		font-size: 16px;
		margin-bottom: 10px;
		a {
			color: $color_7;
		}
	}
	p {
		color: $color_1;
	}
}
.item-info {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	padding: 0 15px 15px;
	z-index: 1;
	span {
		background: #F7F7FF;
		border-radius: 6px;
		font-size: 14px;
		padding: 6px 15px;
		color: $color_7;
	}
}
.fav-icon {
	width: 32px;
}
.site-logo {
	max-height: 32px;
	width: auto;
}
.admin-noti-wrapper {
	.noti-list {
		padding: 7px 10px;
		-webkit-transition: 0.2s ease;
		-moz-transition: 0.2s ease;
		transition: 0.2s ease;
		background-color: $background-color_1;
		border: 1px solid #e5e5e5;
		margin-bottom: 5px;
		position: relative;
		.noti-avatar {
			left: auto;
			height: 40px;
			width: 40px;
			position: absolute;
			img {
				height: 40px;
				width: 40px;
			}
		}
		.noti-contents {
			margin-left: 50px;
			font-size: 13px;
			h3 {
				color: $color_8;
				font-size: 16px;
				margin-bottom: 0;
			}
		}
	}
}
.blog {
	background-color: $background-color_1;
	border: 2px solid rgba(102, 102, 102, 0.09);
	border-radius: 10px;
	margin-bottom: 30px;
	position: relative;
}
.grid-blog {
	.blog-image {
		position: relative;
		z-index: 1;
		border-radius: 10px 10px 0 0;
	}
	.fav-item {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		padding: 15px 15px;
		z-index: 1;
	}
	.entry-meta {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
		margin-bottom: 0;
		padding: 0;
		li {
			margin-bottom: 15px;
		}
	}
	.post-author {
		width: 189px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
	.blog-title {
		font-size: 16px;
		margin: 0 0 6px;
		a {
			color: $color_75;
			transition: all 0.5s;
			&:hover {
				color: $color_9;
			}
		}
	}
	.blog-content {
		p {
			color: $color_1;
		}
	}
	.blog-info {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		justify-content: space-between;
		-webkit-justify-content: space-between;
		-ms-flex-pack: space-between;
		h6 {
			font-weight: 700;
			font-size: 22px;
			margin-bottom: 0;
		}
	}
}
.blog-image {
	display: block;
	position: relative;
	width: 100%;
	height: auto;
	overflow: hidden;
	>a {
		display: block;
		position: relative;
		width: 100%;
		height: auto;
	}
	img {
		display: block;
		position: relative;
		width: 100%;
		height: auto;
		border-radius: 10px 10px 0 0;
		-webkit-transform: translateZ(0);
		-moz-transform: translateZ(0);
		transform: translateZ(0);
		-moz-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
		-ms-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
		-o-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
		-webkit-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
		transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	}
	&:hover {
		img {
			transform: scale(1.1);
		}
	}
}
.blog-widget-image {
	display: flex;
	align-items: center;
	margin-bottom: 10px;
	h6 {
		margin-left: 20px;
		font-size: 13px;
		font-weight: 400;
		margin-bottom: 0;
		display: flex;
		align-items: center;
		img {
			margin-right: 6px;
		}
	}
}
.blog-content {
	padding: 15px;
	.title {
		font-weight: 500;
		font-size: 16px;
		margin-bottom: 10px;
		a {
			color: $color_7;
			&:hover {
				color: $color_9;
			}
		}
	}
	p {
		font-size: 14px;
	}
}
.entry-meta {
	li {
		display: inline-block;
		margin-right: 15px;
	}
}
.post-author {
	span {
		&:nth-child(1) {
			&:hover {
				color: $color_9;
			}
			font-size: 14px;
			font-weight: 500;
			color: $color_28;
			transition: all 0.5s;
		}
		&:nth-child(2) {
			font-size: 12px;
			font-weight: 400;
			color: $color_76;
			transition: all 0.5s;
		}
	}
	a {
		display: flex;
		display: -ms-flexbox;
		align-items: center;
	}
	img {
		border-radius: 5px;
		width: 42px;
		height: 42px;
		margin-right: 10px;
		border-radius: 5px;
		width: 36px;
		height: 36px;
		margin-right: 10px;
	}
}
.post-title {
	display: block;
	color: $color_28;
	font-weight: 600;
}
.post-date {
	font-size: 12px;
	color: $color_76;
}
.blog-views {
	position: absolute;
	top: 20px;
	left: 15px;
	background: #F5F6FA;
	border-radius: 6px;
	padding: 2px 5px;
	display: flex;
	display: -ms-flexbox;
	align-items: center;
	font-size: 12px;
	img {
		width: auto;
		margin-right: 5px;
	}
}
.blog-catagories {
	position: absolute;
	top: 15px;
	right: 15px;
	background: #FC8543;
	border-radius: 6px;
	min-width: 92px;
	padding: 6px 10px;
	display: flex;
	display: -ms-flexbox;
	align-items: center;
	justify-content: center;
	p {
		color: $color_15;
		font-weight: 600;
		font-size: 14px;
		margin-bottom: 0px;
	}
}
.edit-delete-btn {
	a {
		margin-right: 6px;
		font-size: 12px;
		border-radius: 5px;
		padding: 6px 13px;
		&:nth-child(1) {
			margin-right: 6px;
			background: rgba(23, 208, 83, 0.21);
		}
		&:nth-child(2) {
			background: rgba(255, 0, 0, 0.1);
		}
	}
}
.btn-blog {
	padding: 10px 22px;
}
.edit-options {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}
.settings-view {
	display: flex;
	align-items: center;
	>a {
		color: $color_4;
		margin-right: 15px;
	}
}
.content-table {
	padding: 30px;
	padding-right: 0;
}
.language-set {
	img {
		width: 18px;
		border-radius: 50px;
		margin-right: 5px;
	}
}
.active-text {
	color: $color_77;
}
.list-links {
	display: flex;
	display: -ms-flexbox;
	flex-wrap: wrap;
	padding: 0;
	li {
		margin: 0 15px 10px 0;
		list-style: none;
		a {
			background: #FFFFFF;
			border-radius: 5px;
			padding: 12px 30px;
			font-size: 14px;
			color: $color_28;
			border: 1px solid #F5F6FA;
			display: inline-block;
			&:hover {
				background: #e5e5e8;
			}
			span {
				font-size: 14px;
			}
		}
	}
	li.active {
		a {
			background: #FFFFFF;
			border: 1px solid #4C40ED;
			color: $color_9;
		}
	}
}
.inactive-style {
	font-size: 14px;
}
.modal-select-box {
	.select2-container--default {
		.select2-selection--single {
			.select2-selection__arrow {
				b {
					border-color: $border-color_19;
					border-style: solid;
					border-width: 0 2px 2px 0;
					padding: 4px;
					height: 0;
					left: 50%;
					margin-left: -5px;
					margin-top: -5px;
					position: absolute;
					top: 45%;
					width: 0;
					transform: rotate(224deg);
					-webkit-transform: rotate(224deg);
					border-color: $border-color_19;
					border-width: 2px 0 0 2px;
					padding: 3px;
					margin-top: -2px;
				}
			}
		}
	}
}
.pagination-tab {
	padding: 30px;
}
.header.fixed-header {
	.user-menu.nav {
		>li.view-btn {
			>a {
				background-color: $background-color_1;
				color: $color_26;
				&:hover {
					background-color: $background-color_49;
					color: $color_15;
				}
			}
		}
	}
}
.transfer-liststop {
	&::before {
		position: absolute;
		content: "";
		background: rgba(76, 64, 237, 0.03);
		width: 149px;
		height: 149px;
		top: -20px;
		border-radius: 50%;
		right: -100px;
	}
	display: flex;
	align-items: center;
	margin-bottom: 40px;
	justify-content: space-between;
	position: relative;
}
.transfer-listbank {
	h3 {
		font-size: 18px;
		font-weight: 500;
		color: $color_7;
	}
	h4 {
		font-size: 14px;
		color: $color_1;
		margin: 0;
	}
}
.transfer-listsname {
	h5 {
		font-size: 14px;
		color: $color_1;
		font-weight: 400;
	}
	h6 {
		font-size: 14px;
		font-weight: 500;
		margin: 0;
	}
}
.transfer-listsbottom {
	display: flex;
	align-items: center;
	justify-content: space-between;
	a {
		color: $color_9;
		font-weight: 500;
	}
}
.status-toggle {
	display: flex;
	align-items: center;
	span {
		margin-left: 8px;
		font-weight: 400;
		font-size: 14px;
	}
}
::selection {
	background: #4C40ED;
	color: $color_15;
}
table.dataTable {
	>thead {
		.sorting {
			&:after {
				right: 0.5em;
				content: "\f0d7";
				font-family: $font-family_3;
				top: 24px;
				color: $color_78;
				font-size: 12px;
				opacity: 1;
			}
			&:hover {
				&:after {
					color: $color_79;
				}
				&:before {
					color: $color_79;
				}
			}
			&:focus {
				&:after {
					color: $color_79;
				}
				&:before {
					color: $color_79;
				}
			}
			&:before {
				right: 0.5em;
				content: "\f0d8";
				font-family: $font-family_3;
				top: 13px;
				color: $color_78;
				font-size: 12px;
				opacity: 1;
			}
		}
		.sorting_asc {
			&:after {
				right: 0.5em;
				content: "\f0d7";
				font-family: $font-family_3;
				top: 24px;
				color: $color_78;
				font-size: 12px;
				opacity: 1;
			}
			&:hover {
				&:after {
					color: $color_79;
				}
				&:before {
					color: $color_79;
				}
			}
			&:focus {
				&:after {
					color: $color_79;
				}
				&:before {
					color: $color_79;
				}
			}
			&:before {
				right: 0.5em;
				content: "\f0d8";
				font-family: $font-family_3;
				top: 13px;
				color: $color_78;
				font-size: 12px;
				opacity: 1;
			}
		}
		.sorting_desc {
			&:after {
				right: 0.5em;
				content: "\f0d7";
				font-family: $font-family_3;
				top: 24px;
				color: $color_78;
				font-size: 12px;
				opacity: 1;
			}
			&:hover {
				&:after {
					color: $color_79;
				}
				&:before {
					color: $color_79;
				}
			}
			&:focus {
				&:after {
					color: $color_79;
				}
				&:before {
					color: $color_79;
				}
			}
			&:before {
				right: 0.5em;
				content: "\f0d8";
				font-family: $font-family_3;
				top: 13px;
				color: $color_78;
				font-size: 12px;
				opacity: 1;
			}
		}
		.sorting_asc_disabled {
			&:after {
				right: 0.5em;
				content: "\f0d7";
				font-family: $font-family_3;
				top: 24px;
				color: $color_78;
				font-size: 12px;
				opacity: 1;
			}
			&:hover {
				&:after {
					color: $color_79;
				}
				&:before {
					color: $color_79;
				}
			}
			&:focus {
				&:after {
					color: $color_79;
				}
				&:before {
					color: $color_79;
				}
			}
			&:before {
				right: 0.5em;
				content: "\f0d8";
				font-family: $font-family_3;
				top: 13px;
				color: $color_78;
				font-size: 12px;
				opacity: 1;
			}
		}
		.sorting_desc_disabled {
			&:after {
				right: 0.5em;
				content: "\f0d7";
				font-family: $font-family_3;
				top: 24px;
				color: $color_78;
				font-size: 12px;
				opacity: 1;
			}
			&:hover {
				&:after {
					color: $color_79;
				}
				&:before {
					color: $color_79;
				}
			}
			&:focus {
				&:after {
					color: $color_79;
				}
				&:before {
					color: $color_79;
				}
			}
			&:before {
				right: 0.5em;
				content: "\f0d8";
				font-family: $font-family_3;
				top: 13px;
				color: $color_78;
				font-size: 12px;
				opacity: 1;
			}
		}
	}
}
.btn-linegrey {
	border: 1px solid #EDF1F1;
	padding: 3px 10px;
	font-size: 22px;
	margin-right: 5px;
	&:hover {
		background-color: $background-color_20;
		color: $color_15;
	}
}
.active-switch.toogle-primary {
	input {
		&:checked {
			+ {
				.sliders {
					background-color: $background-color_8;
				}
			}
		}
	}
}
.chartgraph {
	.apexcharts-toolbar {
		display: none;
	}
	.apexcharts-legend {
		display: none;
	}
}
.filter-checkbox {
	margin-top: 20px;
	li {
		margin-bottom: 15px;
		&:last-child {
			margin: 0;
		}
	}
}
.datatable-nofooter {
	.dataTables_length {
		display: none;
	}
	.paging_numbers {
		display: none;
	}
	.dataTables_info {
		display: none;
	}
}
.home-user {
	border-radius: 8px;
	width: 100%;
}
.widget-path {
	.card-body {
		padding: 15px;
	}
}
.home-graph-header {
	padding-bottom: 15px;
}
.btn-viewall {
	border: 1px solid rgba(133, 134, 136, 0.16);
	color: $color_80 !important;
	padding: 7px;
	min-width: 100px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	img {
		width: 14px;
	}
	&:hover {
		box-shadow: 0 50px 0 0 #4169E1 inset;
		color: $color_15 !important;
		img {
			filter: brightness(0) invert(1);
		}
	}
}
.btn-check {
	&:checked+.btn.btn-viewall {
		color: $color_80 !important;
		background-color: $background-color_50;
		box-shadow: none;
	}
}
.btn.btn-viewall.active {
	color: $color_80 !important;
	background-color: $background-color_50;
	box-shadow: none;
}
.home-provider {
	.home-usercount {
		span {
			background: rgba(240, 160, 160, 0.12);
		}
	}
}
.home-subscription {
	.home-usercount {
		span {
			background: rgba(255, 184, 73, 0.12);
		}
	}
}
.home-select {
	.delete-table {
		&:hover {
			background: #d5d5d5;
			color: $color_8;
		}
	}
	display: flex;
	align-items: center;
	.btn-action {
		border: 1px solid rgba(133, 134, 136, 0.16);
		padding: 9px 10px;
		color: $color_1;
	}
}
.home-head-user {
	display: flex;
	align-items: center;
	justify-content: space-between;
	h2 {
		font-size: 18px;
		margin: 0;
	}
}
.bookingstatus {
	li {
		span {
			position: absolute;
			width: 14px;
			height: 14px;
			background: #1BA345;
			border-radius: 50px;
			&::after {
				position: absolute;
				content: "";
				background: #fff;
				width: 6px;
				height: 6px;
				border-radius: 50px;
				top: 4px;
				left: 4px;
			}
		}
		position: relative;
		h6 {
			padding-left: 20px;
			font-size: 14px;
			font-weight: 500;
			color: $color_1;
		}
	}
	li.process-status {
		span {
			background: #0081FF;
		}
	}
	li.process-pending {
		span {
			background: #FEC001;
		}
	}
	li+li {
		margin-top: 20px;
	}
}
.bookingmap {
	li {
		+ {
			li {
				margin-top: 25px;
			}
		}
		display: flex;
		align-items: center;
		justify-content: space-between;
		img {
			width: 16px;
			border-radius: 50px;
		}
		span {
			color: $color_1;
			display: flex;
			align-items: center;
		}
		h6 {
			font-size: 14px;
			margin: 0;
			font-weight: 400;
		}
	}
}
.home-userhead {
	margin-bottom: 12px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.home-usercount {
	display: flex;
	align-items: center;
	span {
		width: 36px;
		height: 36px;
		background: rgb(76 64 237 / 12%);
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 10px;
		border-radius: 8px;
	}
	h6 {
		font-size: 16px;
		font-weight: 500;
		margin: 0;
	}
}
.home-useraction {
	a.delete-table {
		background: #fff;
		color: $color_7;
	}
}
.home-usercontent {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.home-usercontentcount {
	span {
		font-size: 22px;
		font-weight: 500;
		margin: 0;
	}
	display: flex;
	align-items: center;
	margin-bottom: 3px;
	h5 {
		font-size: 13px;
		font-weight: 400;
		color: $color_81;
	}
}
.home-usercontents {
	h5 {
		color: $color_81;
		font-size: 13px;
		font-weight: 400;
		margin: 0;
	}
}
.role-checkset {
	h6 {
		color: $color_7;
		font-size: 14px;
		font-weight: 400;
	}
	li {
		margin-right: 67px;
	}
	.checkboxs {
		justify-content: center;
	}
}
.more-option {
	color: $color_9;
}
.swal2-actions {
	.btn {
		margin: 0 5px;
	}
}
.checkboxs {
	cursor: pointer;
	display: flex;
	min-height: 20px;
	align-items: center;
	line-height: 1;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	color: $color_82;
	font-weight: normal;
	span {
		i {
			&:before {
				content: "";
				position: absolute;
				background-color: $background-color_40;
				border-radius: 20px;
				height: 0%;
				top: 50%;
				left: 0;
				width: 2px;
			}
			&:after {
				content: "";
				position: absolute;
				background-color: $background-color_40;
				border-radius: 20px;
				width: 0%;
				bottom: 0;
				left: 0;
				height: 2px;
			}
			position: relative;
			width: 20px;
			height: 20px;
			margin-top: -1px;
			margin-left: 3px;
			transform: rotate(-45deg) translateX(0%) translateY(0%) scale(0.7);
			transform-origin: center left;
		}
		position: relative;
		display: flex;
		width: 19px;
		height: 19px;
		border: 1px solid #D4D4D4;
		box-sizing: border-box;
		overflow: hidden;
		margin-right: 8px;
		background: #fff;
		border-radius: 4px;
	}
	input {
		&:checked {
			~ {
				span {
					border: none;
					background-color: $background-color_51;
					i {
						&:before {
							height: 50%;
							transition: height 300ms cubic-bezier(0.895, 0.03, 0.685, 0.22);
						}
						&:after {
							width: 100%;
							transition: width 300ms 300ms cubic-bezier(0.165, 0.84, 0.44, 1);
						}
					}
				}
				b {
					color: $color_83;
				}
			}
		}
		display: none;
	}
	.check-content {
		color: $color_84;
		font-weight: 400;
		font-size: 13px;
		margin: 0;
	}
}
.btn-login {
	background-color: $background-color_52;
	width: 100%;
	color: $color_15;
	padding: 8px;
	border: 2px solid #0E82FD;
	transition: all 0.5s;
	&:hover {
		box-shadow: 0 50px 0 0 #fff inset;
		color: $color_49;
	}
}
.login-button {
	margin-bottom: 20px;
}
.login-or {
	margin-bottom: 20px;
	text-align: center;
	position: relative;
	&::after {
		position: absolute;
		content: "";
		background: #EFEFEF;
		width: 100%;
		height: 1px;
		left: 0;
		top: 10px;
	}
	span {
		background: #fff;
		position: relative;
		z-index: 9;
		padding: 0 10px;
		color: $color_85;
	}
}
.btn-linepath {
	border: 1px solid #E4E4E8;
	width: 100%;
	padding: 11px;
	color: $color_26;
	&:hover {
		background: #eee;
	}
}
.close-btn {
	border: 0;
	outline: 0;
	background: transparent;
	font-size: 18px;
}
.blog-single-post {
	.blog-image {
		margin-bottom: 20px;
		border-radius: 25px;
	}
	.blog-title {
		font-weight: 700;
		color: $color_26;
		margin-bottom: 25px;
		font-size: 20px;
	}
}
.about-author-img {
	background-color: $background-color_1;
	height: 62px;
	width: 62px;
	img {
		border-radius: 5px;
		height: 62px;
		width: 62px;
	}
}
.author-details {
	margin-left: 20px;
	flex: 1;
	.blog-author-name {
		display: inline-block;
		color: $color_28;
		font-weight: 600;
		margin-bottom: 5px;
		span {
			font-size: 12px;
			font-weight: 400;
			color: $color_76;
			padding-left: 3px;
		}
	}
	p {
		font-size: 12px;
	}
}
.blog-comments {
	.comments-list {
		list-style: none;
		margin: 0;
		padding: 0;
		position: relative;
		li {
			clear: both;
			padding-left: 57px;
			.comment {
				margin-bottom: 20px;
			}
			.comment-author {
				left: 0;
				position: absolute;
			}
			img.avatar {
				height: 42px;
				width: 42px;
				border-radius: 5px;
			}
		}
	}
	.comment-btn {
		color: $color_26;
		display: inline-block;
		font-size: 12px;
		img {
			width: auto !important;
		}
	}
}
.blog-author-name {
	font-size: 14px;
	font-weight: 600;
	margin-bottom: 5px;
}
.blog-date {
	color: $color_76;
	font-size: 12px;
	padding-left: 15px;
	font-weight: 400;
}
.modal-body {
	padding: 24px;
}
.modal-footer {
	border: 0;
	padding: 24px;
}
.content-page-headersplit {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
}
.btn-primaryline {
	border: 2px solid #4C40ED;
	color: $color_9;
	transition: all 0.3s;
	&:hover {
		box-shadow: 0 50px 0 0 #4c40ed inset;
		color: $color_15;
	}
}
.review-sort {
	.select2-container {
		width: auto !important;
		min-width: 150px;
		color: $color_87;
		font-weight: 500;
		font-size: 14px;
		text-align: left;
	}
	.select2-container--default {
		.select2-selection--single {
			border: 1px solid #EDF1F1;
			border-radius: 5px;
			height: 36px;
			background: #fff;
			.select2-selection__rendered {
				line-height: 36px;
			}
			.select2-selection__arrow {
				height: 36px;
			}
		}
	}
	display: flex;
	align-items: center;
	p {
		margin: 0;
		margin-right: 10px;
	}
}
.review-entries {
	select {
		margin: 0 10px 0 20px;
		border: 0.5px solid #CCCCCC;
		border-radius: 5px;
		padding: 5px 30px 5px 10px;
		color: $color_88;
		font-weight: 500;
		font-size: 14px;
	}
}
.close-modal {
	i {
		font-size: 15px;
	}
}
.comments-list {
	.comment-block {
		p {
			font-size: 12px;
			margin-bottom: 10px;
		}
	}
}
.new-comment {
	.form-floating {
		>.form-control {
			height: 50px;
		}
		>label {
			padding: 0.8rem 0.6rem;
		}
	}
}
.form-floating {
	>.form-select {
		height: 50px;
	}
}
.social-share {
	float: left;
	list-style: none;
	margin: 0;
	padding: 0;
	>li {
		display: inline-block;
		float: left;
		margin-left: 10px;
		text-align: center;
		&:first-child {
			margin-left: 0;
		}
		>a {
			border: 1px solid #4C40ED;
			border-radius: 6px;
			color: $color_9;
			display: flex;
			display: -ms-flexbox;
			align-items: center;
			justify-content: center;
			font-size: 20px;
			height: 40px;
			width: 40px;
			&:hover {
				background-color: $background-color_8;
				color: $color_89;
				border-color: $border-color_21;
			}
		}
	}
}
.post-list {
	ul {
		margin: 0 0 20px;
		padding-left: 0;
		list-style: none;
		display: flex;
		display: -ms-flexbox;
		align-items: center;
		flex-wrap: wrap;
		li {
			font-size: 12px;
			color: $color_76;
			margin-bottom: 5px;
			margin-right: 30px;
			i {
				padding-right: 5px;
			}
			&:last-child {
				margin-right: 0;
			}
		}
	}
}
.profile-upload {
	display: flex;
	align-items: center;
	margin-bottom: 25px;
}
.profile-upload-img {
	padding-right: 15px;
	img {
		width: 50px;
		border-radius: 50px;
	}
}
.profile-upload-btn {
	display: flex;
}
.profile-upload-file {
	position: relative;
	margin-right: 15px;
	input {
		position: absolute;
		opacity: 0;
		width: 75px;
		left: 8px;
	}
}
.profile-upload-para {
	p {
		margin: 10px 0 0;
		font-size: 13px;
	}
}
.btn-upload {
	background: #EFF1F7;
	color: $color_90;
	padding: 4px 15px;
	&:hover {
		background: #2469ef;
		color: $color_15;
	}
}
.btn-remove {
	border: 2px solid #FF4949;
	color: $color_54;
	padding: 3px 15px;
	&:hover {
		background: #FF4949;
		color: $color_15;
	}
}
a.blog-author-name {
	&:hover {
		color: $color_9;
	}
}
a.comment-btn {
	&:hover {
		color: $color_9;
	}
}
.save-btn {
	font-weight: 600;
	font-size: 16px;
	padding: 6px 75px;
}
.upload-file {
	margin-bottom: 20px;
	h6 {
		font-weight: 400;
		margin-bottom: 15px;
	}
	img {
		border-radius: 5px;
		margin-bottom: 6px;
	}
	p {
		font-size: 12px;
		color: $color_76;
	}
}
.up-img {
	position: relative;
	padding-left: 11px;
	.close-icon {
		position: absolute;
		top: -9px;
	}
}
.table-action-btn {
	width: 79px;
	height: 27px;
}
.bank-details {
	.modal-footer.blog-categories-btn {
		justify-content: start;
	}
	.close {
		background: transparent;
		border: 0;
		color: $color_94;
		font-size: 28px;
		line-height: normal;
		top: 20px;
		width: auto;
		height: auto;
		right: 20px;
	}
	.modal-footer {
		justify-content: flex-end;
		padding: 20px;
	}
}
.blog-categories-btn {
	padding: 20px;
}
.modal-footer.blog-categories-btn {
	>.bank-details-btn {
		.btn {
			border-radius: 4px;
		}
	}
}
.checktoggle.checkbox-bg {
	background-color: $background-color_1;
	border: 1px solid #D7D7D7;
	&::after {
		background: rgba(0, 0, 0, 0.25);
	}
}
.categories-table {
	.pagination {
		display: none;
	}
	.dataTables_info {
		display: none;
	}
	tr {
		&:last-child {
			td {
				padding-bottom: 0px;
			}
		}
	}
}
.blog-view {
	.card-header {
		padding: 15px;
	}
}
.report-list {
	.app-listing {
		justify-content: flex-end;
	}
	.multiple-selection {
		#checkboxes {
			right: 0;
			left: auto;
			right: 0;
			left: auto !important;
			&::before {
				right: 0;
				left: auto;
				right: 0;
				left: auto;
			}
		}
	}
	#checkboxes {
		.form-custom {
			position: relative;
			.form-control {
				padding-left: 30px;
			}
			i {
				top: 50%;
				transform: translateY(-50%);
				position: absolute;
				left: 10px;
				font-size: 14px;
			}
		}
	}
	.selectbox-cont {
		height: auto;
	}
	.sortby {
		#checkbox {
			&::before {
				right: 0;
				left: auto;
			}
		}
	}
}
.sortby {
	#checkbox {
		right: 0;
		left: auto;
		right: 0;
		left: auto !important;
		display: none;
		position: absolute;
		width: 300px;
		left: 0;
		z-index: 1;
		margin-top: 10px;
		padding: 20px;
		background: #FFFFFF;
		border: 1px solid #F2ECFF;
		box-shadow: 0px 4px 44px rgb(231 234 252 / 75%);
		border-radius: 6px;
		&::before {
			right: 0;
			left: auto;
			border: 7px solid #F2ECFF;
			border-color: $border-color_22;
			box-shadow: -2px 2px 2px -1px rgb(0 0 0 / 10%);
			content: "";
			left: 30px;
			position: absolute;
			top: 0;
			-webkit-transform-origin: 0 0;
			transform-origin: 0 0;
			-webkit-transform: rotate(135deg);
			transform: rotate(135deg);
		}
	}
	.selectboxes {
		position: relative;
		background: #fff;
		width: 100%;
		padding: 13px 10px;
		font-weight: 400;
		background: #FFFFFF;
		border: 1px solid #F7F8F9;
		border-radius: 7px;
		display: flex;
		display: -ms-flexbox;
		align-items: center;
		justify-content: space-between;
		cursor: pointer;
	}
}
.order-by {
	p {
		i {
			color: $color_91;
			width: 20px;
			height: 20px;
		}
	}
}
.custom_radio {
	display: block;
	position: relative;
	padding-left: 27px;
	margin-bottom: 15px;
	cursor: pointer;
	font-size: 14px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
		&:checked {
			~ {
				.checkmark {
					&::after {
						-webkit-transform: scale(1);
						-moz-transform: scale(1);
						-ms-transform: scale(1);
						-o-transform: scale(1);
						transform: scale(1);
						opacity: 1;
						visibility: visible;
					}
				}
			}
		}
	}
	.checkmark {
		position: absolute;
		top: 0;
		left: 0;
		width: 18px;
		height: 18px;
		margin: 1px 0 0 0;
		border: 2px solid #EEF1FE;
		border-top-color: $border-top-color_3;
		border-right-color: $border-right-color_1;
		border-bottom-color: $border-bottom-color_3;
		border-left-color: $border-left-color_1;
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;
		border-radius: 50%;
		-webkit-transition: all .3s;
		-moz-transition: all .3s;
		-ms-transition: all .3s;
		-o-transition: all .3s;
		transition: all .3s;
		&::after {
			position: absolute;
			left: 2px;
			top: 2px;
			content: '';
			width: 10px;
			height: 10px;
			background-color: $background-color_8;
			opacity: 0;
			visibility: hidden;
			-webkit-transform: scale(0.1);
			-moz-transform: scale(0.1);
			-ms-transform: scale(0.1);
			-o-transform: scale(0.1);
			transform: scale(0.1);
			-webkit-border-radius: 50%;
			-moz-border-radius: 50%;
			border-radius: 50%;
			-webkit-transition: all .3s;
			-moz-transition: all .3s;
			-ms-transition: all .3s;
			-o-transition: all .3s;
			transition: all .3s;
		}
	}
}
.lab-title {
	margin-bottom: 10px;
}
.service-report-btn {
	margin-bottom: 20px;
	.btn {
		background: #FFFFFF;
		border: 1px solid #F7F8F9;
		padding: 13px 10px;
		border-radius: 7px;
		width: 100%;
		&:hover {
			background: #4C40ED;
			border: 1px solid #4C40ED;
			color: $color_15;
		}
	}
}
.btn-path {
	text-align: right;
}
.fw-500 {
	font-weight: 500 !important;
}
.account-status-toggle {
	justify-content: flex-end;
	.checktoggle {
		background-color: $background-color_53;
		border: 1px solid #e4e4e4;
	}
	.check {
		&:checked {
			+ {
				.checktoggle {
					background-color: $background-color_8;
					border: 1px solid #4C40ED;
				}
			}
		}
	}
}
.sms-group {
	ul {
		li {
			color: $color_7;
			font-weight: 500;
			margin-bottom: 15px;
			span {
				.avatar-sm {
					width: 27px;
					height: 27px;
					border-radius: 50%;
				}
				float: right;
				color: $color_1;
				font-weight: 400;
			}
		}
	}
}
.del-action {
	width: 28px;
	height: 28px;
	background: #F5F5F5;
	color: $color_1;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	border-radius: 50%;
}
.con-list {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
	flex-wrap: wrap;
	margin-bottom: 20px;
	li {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		margin: 0 20px 20px 0;
		&:last-child {
			margin-right: 0;
		}
		span {
			margin-right: 10px;
		}
	}
}
.con-img {
	img {
		width: 38px;
		height: 38px;
		border-radius: 50%;
	}
}
.con-icon {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	width: 38px;
	height: 38px;
	background: #F7F7FF;
	border-radius: 50%;
}
.con-info {
	h6 {
		font-size: 13px;
		font-weight: 400;
		margin-bottom: 8px;
	}
	p {
		font-size: 13px;
		color: $color_1;
		margin-bottom: 0;
	}
}
.abuse-list {
	li {
		margin-bottom: 20px;
		h6 {
			font-size: 13px;
			font-weight: 400;
			margin-bottom: 10px;
		}
		p {
			font-size: 13px;
			color: $color_1;
			margin-bottom: 0;
		}
	}
}
.upload-size {
	width: 90px;
	height: 110px;
}
.settings-btns {
	.btn-orange {
		font-size: 16px;
		font-weight: 700;
		padding: 10px 26px;
		margin-right: 15px;
		background: #4C40ED;
		border: 1px solid #4C40ED;
		color: $color_15;
		border-radius: 4px;
	}
	.btn-grey {
		font-size: 16px;
		font-weight: 700;
		padding: 10px 26px;
		background: #fff;
		color: $color_92;
		border: 1px solid #242424;
		border-radius: 4px;
		&:hover {
			background: #242424;
			border: 1px solid #242424;
			color: $color_15;
		}
	}
}
.settings-form {
	.social-icon {
		width: 50px;
		height: 40px;
		margin-right: 6px;
		color: $color_15;
		background: #4C40ED;
		border: 1px solid #4C40ED;
		border-radius: 5px;
		font-size: 24px;
		display: flex;
		justify-content: space-around;
		align-items: center;
		position: relative;
		padding: 4px;
	}
	margin-top: 20px;
	.trash {
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		height: 40px;
		width: 46px;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		justify-content: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		background-color: $background-color_8;
		color: $color_15;
		position: relative;
		right: -6px;
		font-size: 20px;
		&:hover {
			color: $color_15;
			opacity: 0.8;
		}
	}
	.bootstrap-tagsinput {
		.tag {
			margin-right: 5px;
			color: $color_15;
			background: #4C40ED !important;
			border-radius: 0px;
			font-size: 15px;
			font-weight: 500;
			padding: 12px 12px;
		}
		input {
			line-height: 36px;
			border: 1px solid transparent;
		}
	}
	.links-cont {
		.social-icon {
			&::after {
				content: "";
				border-top: 0.2em solid;
				border-right: 0.2em solid transparent;
				border-bottom: 0;
				border-left: 0.2em solid transparent;
				margin-left: 4px;
			}
		}
	}
}
.change-photo-btn {
	color: $color_76;
	cursor: pointer;
	display: -ms-flexbox;
	display: flex;
	font-size: 13px;
	font-weight: 600;
	padding: 15px;
	position: relative;
	transition: .3s;
	text-align: center;
	height: 120px;
	background: #FAFAFA;
	border: 1px dashed #E1E1E1;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	vertical-align: middle;
	justify-content: center;
	margin: auto;
	margin-bottom: 10px;
	input.upload {
		bottom: 0;
		cursor: pointer;
		filter: alpha(opacity=0);
		left: 0;
		margin: 0;
		opacity: 0;
		padding: 0;
		position: absolute;
		right: 0;
		top: 0;
		height: 100px;
		width: 100%;
	}
	p {
		margin: 0;
		color: $color_93;
	}
}
.add-links {
	background-color: $background-color_8;
	color: $color_15;
	font-size: 16px;
	font-weight: 700;
	padding: 10px 26px;
	border-radius: 4px;
	&:hover {
		color: $color_15;
		opacity: 0.8;
	}
}
.star-red {
	color: $color_62;
}
.report-card {
	border-radius: 5px;
}
.app-listing {
	padding: 0;
	margin: 0 -15px;
	list-style: none;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	li {
		width: 20%;
		padding-left: 10px;
		padding-right: 10px;
	}
}
.multiple-selection {
	position: relative;
	margin-bottom: 20px;
	.selectbox {
		position: relative;
		background: #fff;
		width: 100%;
		padding: 13px 10px;
		font-weight: 400;
		background: #FFFFFF;
		border: 1px solid #F7F8F9;
		border-radius: 7px;
		display: flex;
		display: -ms-flexbox;
		align-items: center;
		justify-content: space-between;
		cursor: pointer;
	}
	#checkboxes {
		display: none;
		position: absolute;
		width: 300px;
		left: 0;
		z-index: 1;
		margin-top: 10px;
		padding: 20px;
		background: #FFFFFF;
		border: 1px solid #F2ECFF;
		box-shadow: 0px 4px 44px rgb(231 234 252 / 75%);
		border-radius: 6px;
		&::before {
			border: 7px solid #F2ECFF;
			border-color: $border-color_22;
			box-shadow: -2px 2px 2px -1px rgb(0 0 0 / 10%);
			content: "";
			left: 30px;
			position: absolute;
			top: 0;
			-webkit-transform-origin: 0 0;
			transform-origin: 0 0;
			-webkit-transform: rotate(135deg);
			transform: rotate(135deg);
		}
	}
	#checkboxes-one {
		display: none;
		position: absolute;
		width: 100%;
		left: 0;
		z-index: 1;
		margin-top: 10px;
		padding: 20px;
		background: #FFFFFF;
		border: 1px solid #F2ECFF;
		box-shadow: 0px 4px 44px rgb(231 234 252 / 75%);
		border-radius: 6px;
	}
}
.selectbox {
	p {
		color: $color_94;
		font-weight: 500;
		.select-icon {
			color: $color_91;
			width: 20px;
			height: 20px;
		}
	}
}
.report-btn {
	margin-bottom: 20px;
	.btn {
		font-weight: 600;
		color: $color_91;
		background: #FFFFFF;
		border: 2px solid #e60073;
		border-radius: 8px;
		width: 100%;
		padding: 11px 0;
	}
}
.checkbox-title {
	font-weight: 600;
	font-size: 16px;
	color: $color_9;
	margin-bottom: 14px;
}
#checkboxes {
	.form-custom {
		margin-bottom: 14px;
		margin-bottom: 14px;
		.form-control {
			padding-left: 14px;
			border: 1px solid rgba(231, 234, 252, 0.75);
			border-radius: 6px;
			padding-left: 14px;
			border: 1px solid rgba(231, 234, 252, 0.75);
			border-radius: 6px;
		}
	}
	.btn-grey {
		background: rgba(231, 234, 252, 0.75);
		color: $color_94;
		margin-top: 10px;
	}
	.btn {
		font-size: 14px;
		font-weight: 500;
		padding: 8px 0px;
	}
	.date-picker {
		.form-custom {
			.form-control {
				padding-left: 10px;
			}
		}
		.bootstrap-datetimepicker-widget {
			table {
				td {
					padding-left: 10px;
					padding-right: 10px;
				}
			}
		}
	}
}
.custom_check {
	color: $color_94;
	display: inline-block;
	position: relative;
	font-size: 14px;
	margin-bottom: 15px;
	padding-left: 30px;
	cursor: pointer;
	font-weight: 400;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		&:checked {
			~ {
				.checkmark {
					background-color: $background-color_9;
					border-color: $border-color_23;
					&:after {
						display: block;
						top: 1px;
    					left: 3px;
					}
				}
			}
		}
	}
	.checkmark {
		position: absolute;
		top: 0;
		left: 0;
		height: 19px;
		width: 21px;
		border: 1px solid #C4C4C4;
		background-color: $background-color_1;
		border-radius: 6px;
		-moz-transition: all 0.3s ease-in-out;
		-o-transition: all 0.3s ease-in-out;
		-webkit-transition: all 0.3s ease-in-out;
		-ms-transition: all 0.3s ease-in-out;
		transition: all 0.3s ease-in-out;
		&::after {
			content: "\f00c";
			font-family: $font-family_3;
			font-weight: 900;
			position: absolute;
			display: none;
			left: 5px;
			top: 2px;
			color: $color_95;
			font-size: 9px;
		}
	}
}
#checkbox {
	.btn {
		font-size: 14px;
		font-weight: 500;
		padding: 8px 0px;
	}
}
.selectbox-cont-one {
	overflow-y: inherit;
}
.selectbox-cont {
	height: 220px;
	overflow-y: auto;
	padding-right: 5px;
}
.date-picker {
	width: 50%;
	float: left;
	padding-right: 10px;
}
.date-list {
	ul {
		padding: 0;
		margin: 0;
		list-style: none;
		text-align: center;
		li {
			width: 100%;
			padding: 0;
			padding-bottom: 5px;
		}
	}
	.date-btn {
		background: #fff;
		color: $color_94;
		border: 1px solid #E7EAFC;
		border-radius: 4px;
		padding: 10px 0 !important;
		width: 100%;
		&:hover {
			background: #4C40ED;
			color: $color_15;
			border: 1px solid #E7EAFC;
		}
	}
}
.table-profileimage {
	display: flex;
	align-items: center;
	img {
		width: 40px;
		height: 40px;
		border-radius: 50px;
		min-width: 40px;
	}
	span {
		color: $color_1;
	}
	&:hover {
		span {
			color: $color_9;
		}
	}
}
.invoices-tabs-card {
	background: transparent;
	box-shadow: unset;
}
.invoices-main-tabs {
	border-bottom: 2px solid #F3F3F3;
	padding-bottom: 10px;
}
.invoices-tabs {
	ul {
		padding: 0;
		margin: 0;
		list-style: none;
		li {
			position: relative;
			display: inline-block;
			margin-right: 36px;
			a.active {
				color: $color_9;
				border-bottom: 2px solid #4C40ED;
			}
			a {
				font-weight: 600;
				font-size: 16px;
				color: $color_94;
				padding-bottom: 24px;
			}
		}
	}
}
.split-check-box {
	.radiossets {
		.checkmark-radio {
			&:after {
				background: #00CC45;
			}
		}
		input {
			&:checked {
				~ {
					.checkmark-radio {
						border: 1px solid #00CC45;
					}
				}
			}
		}
	}
	ul {
		display: flex;
		justify-content: flex-end;
		li {
			min-width: 114px;
		}
	}
}
.split-check-title {
	h6 {
		font-size: 13px;
		font-weight: 500;
		color: $color_7;
		margin: 0;
	}
}
.table-imgname.flag-image {
	img {
		width: 24px;
		height: 18px;
	}
}
.table-action {
	a {
		background: #F5F5F5;
		border-radius: 6px;
		font-weight: 500;
		font-size: 13px;
		padding: 5px 10px;
		color: $color_7;
	}
}
.mod-name {
	color: $color_96 !important;
	font-weight: 600 !important;
}
.role-wrap {
	margin-bottom: 17px;
	h6 {
		font-size: 18px;
		margin-bottom: 15px;
		span {
			font-size: 16px;
			color: $color_9;
		}
	}
	.checkboxs {
		margin-bottom: 15px;
		.check-content {
			font-size: 16px;
			color: $color_97;
		}
	}
}
.role-table {
	max-height: 615px;
	overflow-y: auto;
	thead {
		position: sticky;
		top: 0;
		z-index: 9;
	}
}
.invoices-settings-btn {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: flex-end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: flex-end;
	.btn {
		font-weight: 600;
		color: $color_15;
		padding: 12px 0;
		min-width: 167px;
		background: #4C40ED;
		border: 1px solid #4C40ED;
		border-radius: 6px;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		justify-content: center;
		-webkit-justify-content: center;
		.feather {
			margin-right: 6px;
		}
	}
}
.invoices-settings-icon {
	color: $color_91;
	margin-right: 20px;
	.feather {
		width: 24px;
		height: 24px;
		font-size: 24px;
	}
}
.invoices-links {
	.feather {
		width: 25px;
		height: 25px;
		font-size: 24px;
	}
	color: $color_94;
	margin-right: 20px;
}
.inovices-card {
	border-radius: 5px;
	.card-body {
		padding: 15px;
	}
}
.inovices-widget-header {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
	.inovices-amount {
		font-weight: 600;
		font-size: 24px;
		color: $color_91;
	}
}
.inovices-widget-icon {
	img {
		width: 50px;
		height: 50px;
	}
}
.invoices-links.active {
	color: $color_91;
}
.inovices-all {
	font-weight: 600;
	color: $color_94;
	margin-top: 16px;
	margin-bottom: 0px;
	span {
		font-size: 10px;
		margin-left: 15px;
	}
}
.invoices-grid-card {
	background: #FFFFFF;
	box-shadow: 0px 4px 24px rgb(228 228 228 / 25%);
	border-radius: 10px;
	color: $color_94;
	.card-header {
		padding: 20px;
		border: 0;
		.action-icon {
			color: $color_94;
			display: initial;
		}
	}
	.card-body {
		padding: 20px;
		span {
			font-size: 14px;
		}
		h6 {
			font-size: 15px;
			font-weight: 500;
			color: $color_94;
			margin-top: 4px;
		}
	}
	.card-footer {
		padding: 20px;
		border: 0;
		.badge {
			border-radius: 6px;
			font-size: 13px;
			font-weight: 400;
			padding: 7px 18px;
		}
	}
	.bg-success-dark {
		background: #008F64;
		background: #008F64;
	}
	.bg-danger-dark {
		background: #FF0000;
		background: #FF0000;
	}
	.bg-secondary-dark {
		background: #7638FF;
		background: #7638FF;
	}
	.bg-primary-dark {
		background: #2196f3;
		background: #2196f3;
	}
}
.invoice-grid-link {
	color: $color_94;
}
.card-middle {
	padding: 20px;
	background: #F7F7FF;
}
.card-middle-avatar {
	align-items: center;
	display: inline-flex;
	font-size: 16px;
	margin: 0;
	a {
		color: $color_94;
	}
}
.invoice-load-btn {
	text-align: center;
	margin-top: 30px;
	margin-bottom: 30px;
	.btn {
		font-weight: 700;
		font-size: 16px;
		color: $color_9;
		min-width: 170px;
		padding: 12px 15px;
		background: #FFFFFF;
		border: 2px solid #4C40ED;
		border-radius: 6px;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		span {
			display: inline-block;
			width: 25px;
			height: 25px;
			margin-right: 8px;
			vertical-align: 0;
			border: 3px solid #4C40ED;
			border-right-color: $border-right-color_2;
			border-radius: 50%;
		}
	}
}
.filter-selects {
	.select2-container {
		.select2-selection--single {
			border: 1px solid #E6E9E8;
			height: 46px;
			background-color: $background-color_1;
			width: 140px;
		}
	}
}
.filter-select-set {
	.select2-container {
		.select2-selection--single {
			border: 1px solid #E6E9E8;
			height: 34px;
		}
	}
	.select2-container--default {
		.select2-selection--single {
			.select2-selection__rendered {
				line-height: 34px;
			}
			.select2-selection__arrow {
				height: 34px;
			}
		}
	}
	width: 180px;
	.group-image {
		img {
			top: 8px;
		}
	}
}
.filter-select-se {
	.select2-container--default {
		.select2-selection--single {
			.select2-selection__arrow {
				height: 34px;
				right: 17px;
			}
		}
	}
}
.badge-default {
	background: #F8F8F8;
	font-size: 12px;
	font-weight: 400;
	color: $color_98;
	display: inline-block;
	width: 80px;
	padding: 7px;
	border-radius: 50px;
	text-align: center;
}
.delete-plugin {
	color: $color_9;
	font-weight: 500;
	&:hover {
		color: $color_8;
	}
}
.serv-update {
	display: flex;
	align-items: center;
	justify-content: space-between;
	ul {
		display: flex;
		align-items: center;
		li {
			+ {
				li {
					margin-left: 25px;
				}
			}
		}
	}
	h6 {
		margin: 0;
		font-weight: 700;
		font-size: 19px;
	}
}
.widget-service {
	.card {
		position: relative;
		transition: all 0.5s;
		&:hover {
			position: relative;
			transition: all 0.5s;
		}
	}
}
.preview-plugin {
	color: $color_1;
	font-size: 14px;
	font-weight: 500;
	text-decoration: underline;
}
.invoices-create-btn {
	.delete-invoice-btn {
		background: #4C40ED;
		margin-right: 10px;
	}
	.invoices-preview-link {
		font-weight: 600;
		color: $color_9;
		margin-right: 30px;
	}
	.btn {
		font-weight: 600;
		font-size: 16px;
		color: $color_15;
		border-radius: 8px;
		padding: 10px 0px;
		min-width: 160px;
	}
	.save-invoice-btn {
		background: #fff;
		border: 1px solid #e60073;
		color: $color_91;
		&:hover {
			background: #e60073;
			border: 1px solid #e60073;
			color: $color_15;
		}
	}
}
.invoices-add-card {
	background: #FFFFFF;
	border-radius: 10px;
}
.invoices-main-form {
	padding-bottom: 15px;
	margin-bottom: 30px;
	border-bottom: 1px solid #E5E5E5;
}
.invoices-form {
	label {
		font-weight: 600;
		color: $color_94;
	}
	.multiple-selection {
		.selectbox {
			border: 2px solid #4C40ED;
			border-radius: 6px;
		}
	}
	.form-control {
		height: 50px;
		border: 1px solid #E5E5E5;
		border-radius: 6px;
	}
}
.invoice-details-title {
	font-weight: 500;
	font-size: 20px;
	color: $color_94;
	margin-bottom: 0.5rem;
}
.invoice-details-box {
	background: #FFFFFF;
	border: 1px solid #E5E5E5;
	border-radius: 10px;
}
.invoice-inner-head {
	padding: 26px;
	border-bottom: 1px solid #E5E5E5;
	span {
		color: $color_94;
		a {
			font-weight: 500;
			color: $color_9;
		}
	}
}
.invoice-inner-footer {
	padding: 0;
	span {
		font-weight: 500;
		color: $color_94;
		position: relative;
	}
	.form-control {
		position: absolute;
		color: $color_9;
		background: transparent;
		border: 0;
		display: inline-block;
		width: auto;
		height: auto;
		padding: 0 10px;
	}
}
.invoice-inner-date {
	padding: 26px;
	border-right: 1px solid #E5E5E5;
}
#show-invoices {
	display: none;
}
.invoice-info {
	margin-bottom: 20px;
	margin-bottom: 20px;
	p {
		margin-bottom: 0;
	}
}
.invoice-item {
	.customer-text {
		font-size: 20px;
		color: $color_99;
		font-weight: 600;
		margin-bottom: 16px;
		display: block;
		a {
			font-size: 14px;
			color: $color_9;
			margin-left: 12px;
		}
	}
	.invoice-details-two {
		text-align: left;
		color: $color_99;
		text-align: left;
	}
	.invoice-logo {
		margin-bottom: 30px;
		img {
			width: auto;
			max-height: 40px;
		}
	}
	.invoice-details {
		text-align: right;
		font-weight: 400;
	}
}
.invoice-add-table {
	h4 {
		font-weight: 600;
		font-size: 20px;
		color: $color_9;
		margin-bottom: 30px;
	}
	padding-top: 20px;
	padding-bottom: 30px;
	td {
		border-color: $border-color_24;
		padding: 20px;
	}
	th {
		border-color: $border-color_24;
		padding: 20px;
		background: #F8F9FA;
	}
	.add-btn {
		color: $color_9;
	}
	.remove-btn {
		color: $color_27;
	}
}
.invoice-fields {
	.field-title {
		font-weight: 600;
		font-size: 16px;
		color: $color_94;
		margin-bottom: 15px;
	}
}
.field-box {
	padding: 15px;
	background: #FFFFFF;
	border: 1px solid #E5E5E5;
	border-radius: 4px;
	p {
		font-weight: 500;
		font-size: 14px;
		color: $color_94;
		margin-bottom: 10px;
	}
	.btn {
		min-width: 266px;
		border-radius: 4px;
		padding: 30px 0;
	}
}
.invoice-faq {
	.faq-tab {
		padding-top: 10px;
	}
}
.faq-tab {
	.panel-title {
		background: #FFFFFF;
		border: 1px solid #E5E5E5;
		border-radius: 4px;
		padding: 10px 20px;
		margin-bottom: 0px;
		position: relative;
		a {
			color: $color_100;
			&:not(.collapsed) {
				&::after {
					color: $color_27;
				}
			}
			&::after {
				content: '\f068';
				font-family: $font-family_4;
				position: absolute;
				right: 20px;
				top: 10px;
				color: $color_101;
				font-weight: 600;
				font-size: 14px;
			}
		}
		a.collapsed {
			&::after {
				content: '\f067';
				font-family: $font-family_4;
				position: absolute;
				right: 20px;
				top: 10px;
				color: $color_9;
				font-weight: 600;
				font-size: 14px;
			}
		}
	}
}
.invoice-total-card {
	.invoice-total-title {
		font-weight: 600;
		font-size: 16px;
		color: $color_94;
		margin-bottom: 15px;
	}
}
.invoice-total-box {
	background: #FFFFFF;
	border: 1px solid #E5E5E5;
	border-radius: 4px;
	p {
		color: $color_94;
		margin-bottom: 20px;
		position: relative;
		span {
			float: right;
		}
	}
	.add-links-bg {
		background: transparent;
		padding: 0;
		color: $color_102;
	}
	.add-links-one {
		font-weight: 500;
		color: $color_9;
	}
	.service-trash-one {
		margin-top: 10px;
		color: $color_94;
		i {
			color: $color_103;
		}
	}
	.add-links {
		font-weight: 500;
		color: $color_9;
		margin-bottom: 20px;
		display: block;
	}
	.service-amount {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		justify-content: space-between;
		-webkit-justify-content: space-between;
		-ms-flex-pack: space-between;
	}
	.service-trash {
		margin-bottom: 10px;
		color: $color_94;
		i {
			color: $color_103;
		}
	}
	.service-amount1 {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		justify-content: space-between;
		-webkit-justify-content: space-between;
		-ms-flex-pack: space-between;
	}
}
.change-activity {
	padding: 50px 20px;
	max-width: 980px;
	margin: auto;
}
.change-activity-head {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 25px;
	h6 {
		font-size: 20px;
		color: $color_7;
		margin: 0;
		font-weight: 500;
	}
}
.sort-div {
	display: flex;
	align-items: center;
}
.sort-divset {
	display: flex;
	align-items: center;
	>label {
		margin-right: 10px;
		margin-bottom: 0;
	}
	.dropdown-menu {
		a {
			width: 100%;
			color: $color_8;
			padding: 10px;
			display: block;
		}
	}
}
a.option-sort {
	border: 1px solid #EDF1F1;
	padding: 8px 10px;
	border-radius: 8px;
	color: $color_87;
	font-weight: 500;
	font-size: 14px;
}
.drop-collapse {
	margin-left: 10px;
}
.btn-filters {
	border: 2px solid #EDF1F1;
	padding: 10px;
	border-radius: 8px;
}
.invoice-total-footer {
	border-top: 1px solid #E5E5E5;
	padding: 15px;
	h4 {
		font-size: 20px;
		margin-bottom: 0;
		color: $color_9;
		span {
			float: right;
		}
	}
}
.upload-sign {
	float: right;
	margin-top: 20px;
	.btn {
		border-radius: 8px;
		min-width: 198px;
		padding: 10px 0;
	}
}
.service-upload {
	border: 2px dashed #E5E5E5;
	text-align: center;
	padding: 30px 0;
	background-color: $background-color_1;
	position: relative;
	width: 330px;
	span {
		font-size: 15px;
		color: $color_66;
		display: block;
	}
	input[type="file"] {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
		cursor: pointer;
	}
}
.invoices-page-header {
	.invoices-breadcrumb-item {
		i {
			color: $color_15;
			border-radius: 50%;
			width: 24px;
			height: 24px;
			margin-right: 10px;
			background: #4C40ED;
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			justify-content: center;
			-webkit-justify-content: center;
			-ms-flex-pack: center;
		}
		a {
			font-weight: 600;
			color: $color_9;
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			justify-content: center;
			-webkit-justify-content: center;
			-ms-flex-pack: center;
		}
	}
}
.invoice-item-one {
	border-bottom: 1px solid rgba(231, 234, 252, 0.75);
	.invoice-info {
		margin-bottom: 30px;
		text-align: right;
	}
}
.invoice-head {
	margin-bottom: 30px;
	h2 {
		font-weight: 600;
		font-size: 28px;
		color: $color_94;
		text-transform: uppercase;
		margin-bottom: 0;
	}
	p {
		font-weight: 400;
		font-size: 20px;
		color: $color_94;
		margin-bottom: 0;
	}
}
.invoice-item-bg {
	background: #4C40ED;
	border-radius: 10px;
	margin-top: 30px;
	margin-bottom: 30px;
	.invoice-info {
		border-right: 1px solid #fff;
		padding: 30px;
		margin-top: 20px;
	}
	.customer-text-one {
		color: $color_15;
	}
	.invoice-name {
		color: $color_15;
		font-size: 16px;
		font-weight: 600;
		margin-bottom: 10px;
	}
	.invoice-details-two {
		color: $color_15;
	}
	.invoice-info-one {
		p {
			color: $color_15;
			margin-bottom: 10px;
		}
	}
}
.invoice-circle-img {
	position: relative;
	.invoice-circle1 {
		position: absolute;
		left: 0;
		opacity: 0.1;
	}
	.invoice-circle2 {
		position: absolute;
		right: 0;
		opacity: 0.1;
	}
}
.form-groupheads {
	h2 {
		font-size: 18px;
		font-weight: 500;
		color: $color_7;
	}
	h3 {
		font-size: 14px;
		font-weight: 500;
		color: $color_7;
	}
	margin-bottom: 25px;
}
.upload-div {
	margin-bottom: 50px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.upload-sets-btn {
	input[type="file"] {
		position: absolute;
		opacity: 0;
		width: 75px;
	}
	position: relative;
}
.theme-image-set {
	img {
		width: 100%;
		border-radius: 5px;
		border: 3px solid transparent;
	}
	h2 {
		margin-top: 15px;
		margin-bottom: 0;
		text-align: center;
		font-size: 14px;
		font-weight: 500;
		color: $color_7;
	}
}
.theme-image {
	position: relative;
	margin-bottom: 30px;
}
.active {
	.theme-image-content {
		display: block;
	}
	.theme-image-set {
		img {
			border: 3px solid #4C40ED;
		}
	}
}
.theme-image-content {
	display: none;
	position: absolute;
	top: -15px;
	right: -15px;
	i {
		font-size: 18px;
		z-index: 99;
		position: relative;
		color: $color_9;
		border-radius: 50px;
		border: 3px solid #fff;
	}
}
.themecolor {
	ul {
		display: flex;
		li {
			margin-right: 15px;
			width: 40px;
		}
	}
}
.themecolorset {
	&::after {
		background: #4C40ED;
		width: 34px;
		height: 34px;
		display: block;
		border-radius: 50px;
		position: absolute;
		content: "";
		top: -3px;
		left: -3px;
	}
	position: relative;
	border: 3px solid #4C40ED;
	width: 34px;
	height: 34px;
	display: block;
	border-radius: 50px;
	position: relative;
	cursor: pointer;
}
.active.themecolorset {
	&::after {
		width: 22px;
		height: 22px;
		top: 3px;
		left: 3px;
	}
}
.theme-greens {
	&::after {
		background: #16AF54;
	}
	border: 3px solid #16AF54;
}
.theme-viloets {
	&::after {
		background: #8431D9;
	}
	border: 3px solid #8431D9;
}
.theme-orange {
	&::after {
		background: #D26400;
	}
	border: 3px solid #D26400;
}
.nav-menus {
	background: #F9F9FB;
	border: 2px solid #F9F9FB;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 15px 20px;
	border-radius: 8px;
	&:hover {
		background: #eee;
	}
	h4 {
		font-size: 16px;
		margin: 0;
		font-weight: 500;
	}
}
.form-group-set {
	h4 {
		font-size: 14px;
		font-weight: 500;
		span {
			color: $color_104;
			font-size: 11px;
			font-weight: 400;
			margin-left: 5px;
		}
	}
	h5 {
		font-size: 13px;
		font-weight: 400;
		color: $color_1;
		margin: 0;
	}
	margin-bottom: 25px;
}
.nav-bar-image {
	img {
		border-radius: 8px;
	}
}
.delete-links {
	background: #F5F5F5;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 28px;
	height: 28px;
	border-radius: 50px;
	color: $color_1;
	margin-left: auto;
	&:hover {
		background: #4C40ED;
		color: $color_15;
	}
}
.link-sets {
	color: $color_9;
	font-weight: 500;
}
.upload-sets {
	p {
		margin: 10px 0 0;
		color: $color_105;
		font-size: 13px;
	}
}
.form-uploads-path {
	background: #F9F9FB;
	border: 1px solid #F3F3F3;
	border-radius: 10px;
	padding: 30px;
	text-align: center;
	img {
		margin-bottom: 15px;
	}
	h5 {
		font-size: 13px;
		font-weight: 400;
		margin: 0;
	}
}
.view-img {
	ul {
		li {
			position: relative;
			display: inline-block;
			margin: 0 10px 10px 0;
			img {
				width: 120px;
				height: 92px;
				object-fit: cover;
				border-radius: 5px;
			}
			a {
				position: absolute;
				top: 5px;
				right: 5px;
				color: $color_15;
			}
		}
	}
}
.file-browse {
	display: flex;
	align-items: center;
	justify-content: center;
	h6 {
		font-size: 15px;
		font-weight: 700;
		margin: 0;
	}
}
.file-browse-path {
	input[type="file"] {
		opacity: 0;
		max-width: 60px;
		position: relative;
		z-index: 9;
		cursor: pointer;
	}
	position: relative;
	cursor: pointer;
	a {
		position: absolute;
		left: 8px;
		top: 6px;
		font-size: 14px;
		font-weight: 700;
		color: $color_9;
		text-decoration: underline;
		z-index: 1;
		cursor: pointer;
	}
}
.upload-imgset {
	width: 100px;
	height: 100px;
	background: #F6F8FF;
	display: flex;
	align-items: center;
	justify-content: center;
	img {
		max-width: 100%;
	}
}
.invoice-table {
	>thead {
		>tr {
			>th {
				background: #F5F7FF;
				border-top: 0;
				font-weight: 500;
				font-size: 16px;
				color: $color_94;
			}
		}
	}
}
.invoice-payment-box {
	h4 {
		font-weight: 600;
		font-size: 16px;
		color: $color_94;
		margin-bottom: 16px;
	}
	.payment-details {
		background: transparent;
		border: 2px dashed #4C40ED;
		width: 266px;
		border-radius: 4px;
		padding: 15px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		p {
			font-weight: 600;
			font-size: 16px;
			color: $color_94;
			max-width: 140px;
			margin-bottom: 0;
		}
	}
}
.invoice-sign-box {
	margin-top: 30px;
}
.invoice-terms {
	margin-bottom: 30px;
	h6 {
		font-weight: 500;
		font-size: 20px;
		color: $color_94;
		margin-bottom: 6px;
	}
	p {
		font-weight: 400;
		font-size: 16px;
		color: $color_106;
	}
}
.invoice-sign {
	padding-top: 30px;
	span {
		color: $color_94;
		margin-top: 18px;
	}
}
.form-header {
	text-align: center;
	margin-bottom: 30px;
	h3 {
		color: $color_94;
		font-size: 22px;
		font-weight: 500;
		margin-bottom: 10px;
	}
	p {
		color: $color_107;
		font-size: 15px;
		margin-bottom: 0;
	}
}
.paid-continue-btn {
	background-color: $background-color_9;
	border: 1px solid #e60073;
	border-radius: 5px;
	color: $color_15;
	display: block;
	font-size: 18px;
	font-weight: 600;
	padding: 10px 20px;
	text-align: center;
}
.online-set {
	position: relative;
	display: flex;
	align-items: center;
	h6 {
		margin: 0;
		font-size: 13px;
		color: $color_53;
	}
}
span.online-path {
	width: 10px;
	height: 10px;
	background: #1BA345;
	border-radius: 50px;
	display: block;
	margin-right: 6px;
	&::after {
		position: absolute;
		content: "";
		width: 4px;
		height: 4px;
		background: #fff;
		top: 6px;
		left: 3px;
		border-radius: 50px;
	}
}
.paid-cancel-btn {
	background-color: $background-color_1;
	border: 1px solid #e60073;
	border-radius: 5px;
	color: $color_91;
	display: block;
	font-size: 18px;
	font-weight: 600;
	padding: 10px 20px;
	text-align: center;
}
.bank-details-btn {
	.btn {
		min-width: 160px;
		border-radius: 8px;
		padding: 10px 0;
		color: $color_15;
	}
	.bank-save-btn {
		color: $color_91 !important;
		border-color: $border-color_23;
		&:hover {
			background: #e60073;
			border: 1px solid #e60073;
			color: $color_15 !important;
		}
	}
	.bank-cancel-btn {
		background: #4C40ED;
	}
}
.invoice-setting-btn {
	.cancel-btn {
		background: #4C40ED;
	}
	.btn {
		min-width: 160px;
		padding: 10px 0;
		color: $color_15;
		border-radius: 8px;
	}
}
.btn-primary-save-bg {
	background-color: $background-color_1;
	color: $color_9 !important;
	border-color: $border-color_1;
	&:hover {
		background: #4C40ED;
		border: 1px solid #4C40ED;
		color: $color_15 !important;
	}
}
.settings-menu {
	ul {
		li {
			list-style: none;
			a {
				color: $color_108;
				padding: 0;
				border: 0 !important;
				display: inline-block;
			}
			i {
				margin-right: 10px;
				font-size: 20px;
				min-width: 25px;
			}
			+ {
				li {
					margin-top: 15px;
				}
			}
			a.active {
				color: $color_9 !important;
				border: 0;
			}
		}
		display: block;
		padding: 0;
	}
}
.invoices-upload-btn {
	border: 1px solid #dee2e6;
	border-radius: 6px;
	color: $color_26;
	cursor: pointer;
	font-weight: 400;
	padding: 15px 15px;
	position: relative;
	width: 100%;
	height: 50px;
	.hide-input {
		position: relative;
		z-index: 1;
		cursor: pointer;
		min-height: auto;
		padding-left: 4px;
		padding-top: 0;
		line-height: 10px;
		width: 100%;
		opacity: 0;
	}
	.upload {
		position: absolute;
		background: #4C40ED;
		border-radius: 4px;
		right: 6px;
		top: 8px;
		width: 136px;
		height: 32px;
		font-size: 14px;
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;
		color: $color_15;
	}
}
.payment_trash {
	.fe-trash-2 {
		color: $color_15;
	}
}
.btn-change {
	background: #F1F8FF;
	color: $color_52;
	&:hover {
		background: #007bff;
		color: $color_15;
	}
}
.btn-removes {
	background: #FFF3F3;
	color: $color_54;
	&:hover {
		background: #ff4949;
		color: $color_15;
	}
}
.invoice-item-two {
	padding-top: 30px;
	padding-bottom: 30px;
	.invoice-details {
		color: $color_94;
		font-weight: 400;
	}
}
.invoice-item-box {
	background: #F7F7FF;
	border-radius: 5px;
	padding: 15px;
	width: 200px;
	float: right;
	margin-top: 20px;
	p {
		color: $color_94;
		font-weight: 400;
		margin-bottom: 10px;
	}
}
.invoice-info.invoice-info2 {
	text-align: right;
}
.customer-text-one {
	font-size: 20px;
	color: $color_94;
	font-weight: 600;
	display: block;
	margin-bottom: 10px;
}
.invoice-issues-box {
	background: #4C40ED;
	border-radius: 10px;
	text-align: center;
	padding: 10px;
	margin-bottom: 30px;
}
.invoice-issues-date {
	padding-top: 20px;
	padding-bottom: 20px;
	p {
		font-weight: 600;
		font-size: 16px;
		color: $color_41;
		margin-bottom: 0;
	}
}
.invoice-table-wrap {
	margin-bottom: 30px;
}
.inovices-month-info {
	padding-top: 50px;
	.form-control {
		height: 42px;
	}
}
.poor-active {
	span.active#poor {
		background: #FF0000;
	}
	span.active#poors {
		background: #FF0000;
	}
}
.avg-active {
	span.active#poor {
		background: #FFB54A;
	}
	span.active#weak {
		background: #FFB54A;
	}
	span.active#poors {
		background: #FFB54A;
	}
	span.active#weaks {
		background: #FFB54A;
	}
}
.strong-active {
	span.active#poor {
		background: #1D9CFD;
	}
	span.active#weak {
		background: #1D9CFD;
	}
	span.active#strong {
		background: #1D9CFD;
	}
	span.active#poors {
		background: #1D9CFD;
	}
	span.active#weaks {
		background: #1D9CFD;
	}
	span.active#strongs {
		background: #1D9CFD;
	}
}
.heavy-active {
	span.active#poor {
		background: #159F46;
	}
	span.active#weak {
		background: #159F46;
	}
	span.active#strong {
		background: #159F46;
	}
	span.active#heavy {
		background: #159F46;
	}
	span.active#poors {
		background: #159F46;
	}
	span.active#weaks {
		background: #159F46;
	}
	span.active#strongs {
		background: #159F46;
	}
	span.active#heavys {
		background: #159F46;
	}
}
.invoice-inner-date.invoice-inner-datepic {
	padding-left: 0;
}
.list-btn {
	.btn-filters {
		border: 1px solid #E6E9E8;
		border-radius: 8px;
		color: $color_1;
		width: 40px;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		&:hover {
			box-shadow: 0 50px 0 0 #4C40ED inset;
			color: $color_22;
			border-color: $border-color_1;
			i {
				color: $color_15;
			}
		}
	}
	ul {
		li {
			&:last-child {
				margin-right: 0;
			}
			margin: 0 5px;
		}
		display: flex;
		align-items: center;
	}
	.btn-filters.active {
		box-shadow: 0 50px 0 0 #4C40ED inset;
		color: $color_22;
		border-color: $border-color_1;
		color: $color_15;
	}
}
.menu-set {
	border: 1px solid rgba(41, 136, 230, 0.22);
	margin-bottom: 25px;
	border-radius: 8px;
}
.menu-setheader {
	a {
		background: #F8FAFC;
		padding: 17px;
		font-size: 14px;
		font-weight: 500;
		border-bottom: 1px solid rgba(41, 136, 230, 0.22);
		margin: 0;
		display: block;
		color: $color_7;
		border-radius: 8px 8px 0 0;
	}
	h5 {
		background: #F8FAFC;
		padding: 17px;
		font-size: 14px;
		font-weight: 500;
		border-bottom: 1px solid rgba(41, 136, 230, 0.22);
		margin: 0;
		display: block;
		color: $color_7;
		border-radius: 8px 8px 0 0;
	}
	.collapsed {
		i {
			transform: rotate(0);
		}
	}
	i {
		position: relative;
		top: 1px;
		font-size: 18px;
		transform: rotate(180deg);
		transition: all 0.5s;
	}
}
.menu-setcontent {
	.select2-container {
		.select2-selection--single {
			background-color: $background-color_1;
			height: 34px;
		}
	}
	.select2-container--default {
		.select2-selection--single {
			.select2-selection__arrow {
				height: 34px;
				right: 7px;
			}
			.select2-selection__rendered {
				line-height: 34px;
			}
		}
	}
	.form-control {
		height: 34px;
		background: #FFFFFF;
		padding: 10px;
	}
	padding: 17px;
}
.menu-setcontent-inner {
	border: 1px solid #E4E8EC;
	background: #E4E8EC;
	padding: 11px;
	border-radius: 5px;
	margin-bottom: 15px;
	.menu-setcontent {
		padding: 17px 0 0;
	}
}
.menu-innerhead {
	a {
		color: $color_7;
		width: 100%;
		display: block;
	}
}
.filter-sorting {
	.review-sort {
		.select2-container--default {
			.select2-selection--single {
				border: 0;
				border-radius: 5px;
				height: auto;
				background: #fff;
				.select2-selection__rendered {
					line-height: 36px;
					padding-left: 0;
					margin-left: 15px;
				}
			}
		}
		.select2-container {
			width: auto !important;
			min-width: 100%;
			color: $color_1;
			font-weight: 500;
			z-index: 9;
		}
	}
	ul {
		border: 1px solid #E6E9E8;
		display: flex;
		align-items: center;
		border-radius: 8px;
		li {
			margin: 0;
			padding: 0 5px;
			display: flex;
			display: flex;
			align-items: center;
			&:last-child {
				border-left: 1px solid #E6E9E8;
				width: 60%;
			}
			&:first-child {
				width: 40%;
				display: block;
				padding: 0;
			}
			span {
				img {
					min-width: 15px;
				}
			}
		}
	}
}
.filter-sets {
	color: $color_1;
	font-weight: 500;
	height: 40px;
	display: flex;
	align-items: center;
	padding: 0 10px;
	&:hover {
		background: #eee;
	}
}
.table-select {
	span.select2-selection.select2-selection--single {
		height: 30px;
	}
	.select2-container--default {
		.select2-selection--single {
			.select2-selection__rendered {
				line-height: 30px;
				padding-left: 10px;
				font-size: 14px;
			}
			.select2-selection__arrow {
				height: 30px;
			}
		}
	}
	width: 115px;
	.select2-container {
		font-size: 11px;
	}
}
.table-select.table-selectpayouts {
	width: 225px;
}
.table-namesplit {
	display: flex;
	align-items: center;
}
.table-name {
	span {
		color: $color_1;
		font-weight: 500;
	}
	p {
		color: $color_109;
		margin: 0;
		font-size: 13px;
	}
}
a.table-name {
	&:hover {
		span {
			color: $color_9;
		}
	}
}
.btn-history {
	color: $color_1;
	background: #F7F7FF;
	font-size: 13px;
	padding: 4px 18px;
	&:hover {
		box-shadow: 0 50px 0 0 #74788D inset;
		border: 1px solid;
		color: $color_15;
	}
}
.notification-message {
	img.avatar-img.rounded-circle {
		border-radius: 5px !important;
	}
}
.info-set {
	font-size: 13px;
	font-weight: 400;
	color: $color_1;
	margin: 8px 0 0;
}
.form-icon {
	position: relative;
	position: relative;
	span {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 10px;
	}
	.cus-icon {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 10px;
		height: 20px;
	}
}
.price-card {
	background: #FFFFFF;
	border: 2px solid #F1F1F1;
	border-radius: 8px;
	padding: 15px;
	&:hover {
		background: #EDF3F9;
		border: 2px solid #F1F1F1;
		.btn-choose {
			background-color: $background-color_51;
			color: $color_15;
		}
	}
}
.price-head {
	border-bottom: 1px solid #EFEFEF;
	padding-bottom: 15px;
	h6 {
		font-weight: 500;
		font-size: 14px;
		margin-bottom: 10px;
	}
	h1 {
		font-weight: 700;
		font-size: 40px;
		margin-bottom: 0;
		span {
			font-size: 12px;
			color: $color_110;
		}
	}
}
.price-level {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
}
.price-body {
	padding-top: 15px;
	ul {
		margin-bottom: 35px;
		li {
			position: relative;
			font-size: 14px;
			color: $color_111;
			margin-bottom: 15px;
			padding-left: 25px;
			&:last-child {
				margin-bottom: 0;
			}
			&:before {
				position: absolute;
				content: "";
				top: 2px;
				left: 0;
				width: 100%;
				height: 100%;
				background-repeat: no-repeat;
			}
		}
		li.active {
			&:before {
				background-image: url("../img/icons/check-icon.svg");
			}
		}
		li.inactive {
			&:before {
				background-image: url("../img/icons/close-icon.svg");
			}
		}
	}
	.btn-choose {
		background: #EDF3F9;
		border-radius: 8px;
		font-size: 14px;
		padding: 6px 30px;
		margin: 0 auto;
	}
}
.price-toggle {
	font-weight: 500;
	font-size: 18px;
	color: $color_7;
	text-align: center;
	margin-bottom: 35px;
	.checktoggle {
		margin: 0 23px;
		background: #4C40ED;
	}
	.check {
		&:checked {
			+ {
				.checktoggle {
					background-color: $background-color_8;
					border: 1px solid #4C40ED;
				}
			}
		}
	}
}
#overlayer {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	z-index: 9999;
	background: #fff;
}
.loader {
	display: inline-block;
	width: 40px;
	height: 40px;
	position: fixed;
	z-index: 10000;
	border: 4px solid #4C40ED;
	top: 50%;
	animation: loader 2s infinite ease;
	left: 50%;
	border-radius: 5px;
}
.loader-inner {
	vertical-align: top;
	display: inline-block;
	width: 100%;
	background-color: $background-color_8;
	animation: loader-inner 2s infinite ease-in;
}
.sidbarnews {
	width: 100%;
	height: 100%;
	overflow: auto;
}
.chat-scroll {
	max-height: calc(100vh - 264px);
	overflow-y: auto;
	&::-webkit-scrollbar {
		width: 3px;
		background: #fff;
		height: 3px;
	}
	&::-webkit-scrollbar-track {
		box-shadow: inset 0 0 5px #fff;
		border-radius: 10px;
	}
	&::-webkit-scrollbar-thumb {
		background: #D4D4D4;
		border-radius: 10px;
		&:hover {
			background: #D4D4D4;
		}
	}
}
.chat-cont-left {
	.chat-scroll {
		max-height: calc(100vh - 286px);
		overflow-y: auto;
		&::-webkit-scrollbar {
			width: 3px;
			background: #fff;
			height: 3px;
		}
		&::-webkit-scrollbar-track {
			box-shadow: inset 0 0 5px #fff;
			border-radius: 10px;
		}
		&::-webkit-scrollbar-thumb {
			background: #D4D4D4;
			border-radius: 10px;
			&:hover {
				background: #D4D4D4;
			}
		}
	}
	.chat-header {
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		background-color: $background-color_1;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		justify-content: space-between;
		-webkit-justify-content: space-between;
		-ms-flex-pack: space-between;
		padding: 15px;
		h6 {
			font-size: 20px;
			margin-bottom: 3px;
		}
		p {
			font-size: 14px;
			margin-bottom: 0;
		}
	}
	.chat-header.inner-chat-header {
		padding: 15px 0;
		h6 {
			font-size: 16px;
			font-weight: 500;
			margin-bottom: 0;
		}
	}
	.card {
		border: 2px solid rgba(220, 220, 220, 0.3);
		border-radius: 5px;
	}
	.chat-users-list {
		a.media {
			border: 1px solid #fff;
			padding: 8px;
			transition: all 0.2s ease 0s;
			.media-body {
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				justify-content: space-between;
				-webkit-justify-content: space-between;
				-ms-flex-pack: space-between;
				>div {
					&:first-child {
						.user-name {
							max-width: 100px;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
							color: $color_112;
							font-weight: 500;
							font-size: 15px;
							text-transform: capitalize;
							margin-bottom: 3px;
						}
						.user-last-chat {
							max-width: 100px;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
							color: $color_127;
							font-size: 13px;
						}
					}
					&:last-child {
						.badge {
							width: 15px;
							height: 15px;
							background: #4C40ED;
							border-radius: 50%;
							color: $color_15;
							-webkit-box-align: center;
							-ms-flex-align: center;
							align-items: center;
							display: -webkit-inline-box;
							display: -ms-inline-flexbox;
							display: inline-flex;
							justify-content: center;
							-webkit-justify-content: center;
							-ms-flex-pack: center;
							font-weight: 500;
							font-size: 9px;
							padding: 0;
						}
						text-align: right;
						.last-chat-time {
							color: $color_127;
							font-size: 13px;
						}
					}
				}
			}
			.media-img-wrap {
				margin-right: 10px;
				position: relative;
				.avatar {
					width: 35px;
					height: 35px;
				}
			}
		}
		a.media.active {
			background-color: $background-color_55;
			border-radius: 5px;
		}
	}
}
.contacts_body {
	padding: 0.75rem 0;
	overflow-y: auto;
	white-space: nowrap;
}
.msg_card_body {
	overflow-y: auto;
	padding: 20px;
	.media {
		.media-body {
			.msg-box {
				.chat-file-icon {
					margin-right: 9px;
					i {
						font-size: 25px;
					}
				}
				.chat-file-info {
					h6 {
						font-weight: 500;
						font-size: 14px;
						margin-bottom: 5px;
					}
					p {
						font-size: 12px;
						color: $color_113;
						margin-bottom: 0;
					}
				}
				>div {
					padding: 8px 10px;
					border-radius: 10px;
					display: inline-block;
					position: relative;
					p {
						font-size: 14px;
						margin-bottom: 0;
					}
				}
				+ {
					.msg-box {
						margin-top: 5px;
					}
				}
			}
			margin-left: 6px;
		}
		.avatar {
			height: 25px;
			width: 25px;
		}
		&:last-child {
			margin-bottom: 0;
		}
		.chat-msg-info {
			li {
				.dropdown-item {
					padding: 4px 6px;
					font-size: 12px;
				}
			}
		}
	}
	ul.list-unstyled {
		margin: 0 auto;
		padding: 1rem 0 0;
		width: 100%;
		border-top: 1px solid #E8EBED;
		li {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	.media.received {
		margin-bottom: 20px;
		.chat-msg-info {
			li {
				a {
					color: $color_119;
				}
			}
		}
		.media-body {
			.msg-box {
				>div {
					background-color: $background-color_59;
					.chat-time {
						color: $color_119;
					}
				}
				position: relative;
				&:first-child {
					>div {
						border-radius: 0 15px 15px 15px;
						margin-top: 15px;
					}
				}
			}
		}
	}
	.media.sent {
		margin-bottom: 20px;
		.media-body {
			-webkit-box-align: flex-end;
			-ms-flex-align: flex-end;
			align-items: flex-end;
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-ms-flex-direction: column;
			flex-direction: column;
			justify-content: flex-end;
			-webkit-justify-content: flex-end;
			-ms-flex-pack: flex-end;
			margin-left: 0;
			margin-right: 6px;
			.msg-box {
				>div {
					background: #F7F7F8;
					.chat-time {
						color: $color_122;
					}
					.chat-msg-info {
						flex-direction: row-reverse;
						li {
							padding-left: 16px;
							padding-right: 0;
							position: relative;
							&:last-child {
								padding-left: 0;
							}
						}
					}
					.chat-msg-attachments {
						flex-direction: row-reverse;
					}
					&:hover {
						.chat-msg-actions {
							opacity: 1;
						}
					}
				}
				padding-left: 50px;
				position: relative;
				&:first-child {
					>div {
						border-radius: 15px 0 15px 15px;
						margin-top: 15px;
					}
				}
			}
		}
		.avatar {
			-ms-flex-order: 3;
			order: 3;
		}
	}
	.chat-date {
		font-size: 13px;
		margin: 22px 0;
		overflow: hidden;
		position: relative;
		text-align: center;
		text-transform: capitalize;
		span {
			color: $color_120;
			background: #FFFFFF;
			border: 1px solid #F1F1F1;
			border-radius: 5px;
			padding: 2px 10px;
			display: inline-block;
		}
	}
}
.chat-list {
	margin: 0 auto;
	padding: 0 14px;
	text-align: center;
	li {
		display: inline-block;
		margin-bottom: 5px;
		a {
			padding: 5px 11px;
			font-weight: 500;
			font-size: 12px;
			color: $color_112;
			border-radius: 50px;
			display: -ms-inline-flexbox;
			display: inline-flex;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			&:hover {
				background-color: $background-color_55;
			}
			i {
				margin-right: 5px;
			}
		}
		a.active {
			background-color: $background-color_55;
		}
	}
}
.chat-compose {
	width: 37px;
	height: 37px;
	color: $color_112;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	background: #F9F9F9;
	border-radius: 5px;
	font-size: 18px;
}
.chat-file-attachments {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.chat-file-attach {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
	margin-right: 20px;
}
.chat-file-download {
	width: 28px;
	height: 28px;
	background: #fff;
	border-radius: 50%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	color: $color_2;
}
.search {
	border-radius: 15px 0 0 15px;
	background-color: $background-color_56;
	border: 0;
	color: $color_15;
	&:focus {
		box-shadow: none;
		outline: 0px;
	}
}
.chat-cont-right {
	.card-footer {
		padding: 20px;
	}
	.chat-footer {
		background-color: $background-color_1;
		border-top: 1px solid #f0f0f0;
		padding: 10px 15px;
		position: relative;
		.input-group {
			width: 100%;
			.form-control {
				background-color: $background-color_60;
				border: none;
				border-radius: 50px;
				&:focus {
					background-color: $background-color_60;
					border: none;
					box-shadow: none;
				}
			}
			.input-group-prepend {
				.btn {
					background-color: $background-color_22;
					border: none;
					color: $color_121;
				}
			}
			.input-group-append {
				.btn {
					background-color: $background-color_22;
					border: none;
					color: $color_121;
				}
				.btn.msg-send-btn {
					background-color: $background-color_61;
					border-color: $border-color_26;
					border-radius: 50%;
					color: $color_15;
					margin-left: 10px;
					min-width: 46px;
					font-size: 20px;
				}
			}
		}
	}
	.card {
		border-color: $border-color_27;
		border-radius: 5px;
	}
	.card-header {
		padding: 15px;
		display: flex;
		display: -webkit-flex;
		align-items: center;
		-webkit-align-items: center;
		justify-content: space-between;
		-webkit-justify-content: space-between;
	}
	.img_cont {
		width: auto;
		height: auto;
	}
	.msg_head {
		.back-user-list {
			display: none;
			padding-right: 10px;
			margin-left: -20px;
			padding-left: 10px;
		}
	}
}
.btn-file {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	font-size: 20px;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	overflow: hidden;
	padding: 0;
	vertical-align: middle;
	background: #EDEEF7;
	border: 1px solid #EDEEF7;
	border-radius: 50% !important;
	width: 30px;
	height: 30px;
	position: absolute !important;
	left: 10px;
	top: 50%;
	transform: translateY(-50%);
	input {
		cursor: pointer;
		filter: alpha(opacity=0);
		font-size: 0;
		height: 100%;
		margin: 0;
		opacity: 0;
		position: absolute;
		right: 0;
		top: 0;
		width: 100%;
	}
}
.type_msg {
	background: #FFFFFF;
	border: 1px solid #4C40ED;
	box-shadow: 0px 4px 8px rgba(52, 68, 76, 0.04);
	border-radius: 50px;
	overflow-y: auto;
	min-height: 50px;
	padding: 10px 107px 10px 45px;
	border-radius: 50px !important;
	font-size: 14px;
	font-weight: 400;
	&:focus {
		box-shadow: none;
		outline: 0px;
		z-index: unset !important;
	}
	&::placeholder {
		color: $color_114;
	}
}
.send-action {
	position: absolute;
	right: 10px;
	top: 50%;
	transform: translateY(-50%);
	display: inline-flex;
	display: -webkit-inline-flex;
	align-items: center;
	-webkit-align-items: center;
	a {
		width: 30px;
		height: 30px;
		display: inline-flex;
		display: -webkit-inline-flex;
		align-items: center;
		-webkit-align-items: center;
		justify-content: center;
		-webkit-justify-content: center;
		background: #EDEEF7;
		border: 1px solid #F3F3F3;
		color: $color_115;
		border-radius: 50%;
		margin-right: 7px;
		font-size: 12px;
		&:hover {
			background: #4C40ED;
			color: $color_15;
		}
	}
}
.btn_send {
	width: 30px;
	height: 30px;
	border-radius: 50% !important;
	display: inline-flex;
	display: -webkit-inline-flex;
	align-items: center;
	-webkit-align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	padding: 0;
	font-size: 12px;
}
.search_btn {
	background-color: $background-color_22;
	border: 0;
	line-height: 0;
}
.user_img {
	height: 39px;
	width: 39px;
	min-width: 39px;
}
.img_cont {
	position: relative;
	height: 45px;
	width: 45px;
}
.offline {
	background-color: $background-color_57;
}
.user_info {
	margin-top: auto;
	margin-bottom: auto;
	margin-left: 8px;
	width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	span {
		font-size: 18px;
		font-weight: 600;
		color: $color_7;
	}
	.user-name {
		color: $color_116;
	}
	p {
		font-size: 14px;
		line-height: normal;
	}
	p.active {
		color: $color_117;
	}
	p.inactive {
		color: $color_118;
	}
}
.chat-window {
	.avatar-online {
		&::before {
			background-color: $background-color_58;
		}
	}
}
.msg_head {
	position: relative;
	background-color: $background-color_22;
	border-bottom: 0 !important;
}
.msg-typing {
	width: auto;
	height: 24px;
	padding-top: 8px;
	span {
		height: 8px;
		width: 8px;
		float: left;
		margin: 0 1px;
		background-color: $background-color_1;
		display: block;
		border-radius: 50%;
		opacity: .4;
		&:nth-of-type(1) {
			animation: 1s blink infinite .33333s;
		}
		&:nth-of-type(2) {
			animation: 1s blink infinite .66666s;
		}
		&:nth-of-type(3) {
			animation: 1s blink infinite .99999s;
		}
	}
}
.chat-msg-info {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
	clear: both;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	list-style: none;
	font-size: 10px;
	padding: 0;
	margin: 0 0 5px;
	position: absolute;
	top: -20px;
	left: 0;
	width: 100%;
	li {
		font-size: 13px;
		padding-right: 5px;
		position: relative;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		&:last-child {
			padding-right: 0;
		}
		.chat-time {
			margin-right: 10px;
		}
		.drop-item {
			color: $color_119;
		}
		&:not(:last-child) {
			&:after {
				position: absolute;
				right: 8px;
				top: 50%;
				content: '';
				height: 4px;
				width: 4px;
				background: #d2dde9;
				border-radius: 50%;
				transform: translate(50%, -50%);
			}
		}
		a {
			color: $color_123;
			&:hover {
				color: $color_124;
			}
		}
	}
}
.media.sent {
	.chat-msg-info {
		li {
			.chat-time {
				margin: 0 0 0 10px;
			}
		}
	}
}
.chat-seen {
	i {
		color: $color_125;
		font-size: 16px;
	}
}
.chat-msg-attachments {
	padding: 4px 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	margin: 0 -1px;
	>div {
		margin: 0 1px;
	}
}
.chat-attachment {
	img {
		max-width: 100%;
	}
	position: relative;
	max-width: 130px;
	overflow: hidden;
	border-radius: .25rem;
	&:before {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background: #000;
		content: "";
		opacity: 0.4;
		transition: all .4s;
	}
	&:hover {
		&:before {
			opacity: 0.6;
		}
		.chat-attach-caption {
			opacity: 0;
		}
		.chat-attach-download {
			opacity: 1;
		}
	}
}
.chat-attach-caption {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	color: $color_15;
	padding: 7px 15px;
	font-size: 13px;
	opacity: 1;
	transition: all .4s;
}
.chat-attach-download {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	opacity: 0;
	transition: all .4s;
	color: $color_15;
	width: 32px;
	line-height: 32px;
	background: rgba(255, 255, 255, 0.2);
	text-align: center;
	&:hover {
		color: $color_126;
		background: #fff;
	}
}
.chat-attachment-list {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin: -5px;
	li {
		width: 33.33%;
		padding: 5px;
	}
}
.chat-attachment-item {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	border: 5px solid rgba(230, 239, 251, 0.5);
	height: 100%;
	min-height: 60px;
	text-align: center;
	font-size: 30px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.chat-msg-actions {
	position: absolute;
	left: -30px;
	top: 50%;
	transform: translateY(-50%);
	opacity: 0;
	transition: all .4s;
	z-index: 2;
	>a {
		padding: 0 10px;
		color: $color_126;
		font-size: 24px;
		&:hover {
			color: $color_124;
		}
	}
}
.chat-users-list {
	padding: 15px;
}
.chat-page {
	.footer {
		display: none;
	}
}
.chat-search {
	padding: 0px 15px 24px;
	width: 100%;
	.input-group {
		width: 100%;
		.form-control {
			border: 1px solid #F7F7FF;
			border-radius: 7px !important;
			background-color: $background-color_1;
			padding-left: 36px;
			min-height: 35px;
			font-size: 13px;
			font-weight: 400;
			&:focus {
				border-color: $border-color_28;
				box-shadow: none;
			}
		}
		.input-group-prepend {
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			bottom: 0;
			color: $color_128;
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			left: 10px;
			pointer-events: none;
			position: absolute;
			top: 0;
			z-index: 4;
		}
	}
	.form-control {
		&::placeholder {
			color: $color_128;
		}
	}
}
.chat-options {
	ul {
		list-style: none;
		padding: 0;
		margin: 0;
		display: flex;
		display: -webkit-flex;
		li {
			a {
				width: 30px;
				height: 30px;
				color: $color_1;
				background-color: $background-color_1;
				border-radius: 30px;
				display: inline-block;
				display: flex;
				display: -webkit-flex;
				align-items: center;
				-webkit-align-items: center;
				justify-content: center;
				-webkit-justify-content: center;
				&:hover {
					background-color: $background-color_55;
				}
			}
			&:not(:last-child) {
				margin-right: 5px;
			}
			a.with-bg {
				background-color: $background-color_55;
			}
		}
	}
}
.msg-highlight {
	a {
		font-size: 13px;
		color: $color_9;
		text-decoration: underline;
	}
}
.chat-cont-profile {
	.card {
		border: 2px solid rgba(220, 220, 220, 0.3);
		border-radius: 5px;
	}
	.card-header {
		padding: 14px 14px 0;
		background: #fff;
		border: 0;
	}
	.card-body {
		padding: 14px;
		max-height: calc(100vh - 265px);
		overflow-y: auto;
		&::-webkit-scrollbar {
			width: 3px;
			background: #fff;
			height: 3px;
		}
		&::-webkit-scrollbar-track {
			box-shadow: inset 0 0 5px #fff;
			border-radius: 10px;
		}
		&::-webkit-scrollbar-thumb {
			background: #D4D4D4;
			border-radius: 10px;
			&:hover {
				background: #D4D4D4;
			}
		}
	}
}
.chat-profile {
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin-bottom: 15px;
}
.chat-profile-img {
	margin-right: 16px;
	img {
		width: 61px;
		height: 61px;
		border-radius: 50%;
	}
}
.chat-profile-info {
	h6 {
		font-weight: 500;
		font-size: 18px;
		margin-bottom: 3px;
	}
	p {
		font-size: 11px;
		color: $color_129;
		margin-bottom: 0;
	}
}
.profile-wrap {
	border-bottom: 1px solid #F2F2F2;
	padding-bottom: 14px;
	ul {
		li {
			display: inline-block;
			margin-right: 15px;
			&:last-child {
				margin-right: 0;
			}
			a {
				width: 30px;
				height: 30px;
				background-color: $background-color_1;
				border-radius: 30px;
				display: flex;
				display: -webkit-flex;
				align-items: center;
				-webkit-align-items: center;
				justify-content: center;
				-webkit-justify-content: center;
				background-color: $background-color_55;
				color: $color_130;
				&:hover {
					background: #4C40ED;
					color: $color_15;
				}
			}
		}
	}
}
.chat-pro-list {
	ul {
		padding: 18px 0;
		border-bottom: 1px solid #F2F2F2;
		li {
			font-weight: 500;
			font-size: 15px;
			margin-bottom: 13px;
			.role-info {
				font-weight: 400;
				color: $color_131;
			}
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}
.role-title {
	width: 100px;
	display: inline-block;
	i {
		margin-right: 8px;
	}
}
.media-list {
	text-align: center;
	ul {
		margin-bottom: 10px;
	}
	li {
		display: inline-block;
		margin-bottom: 10px;
		a {
			padding: 5px 12px;
			font-weight: 500;
			font-size: 12px;
			color: $color_112;
			border-radius: 50px;
			display: -ms-inline-flexbox;
			display: inline-flex;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
		}
		a.active {
			background-color: $background-color_55;
		}
	}
}
.photo-list {
	ul {
		li {
			display: inline-block;
			margin: 0 12px 15px 0;
			img {
				width: 80px;
				height: 70px;
				object-fit: cover;
				border-radius: 10px;
			}
		}
	}
}
.chat-notification {
	ul {
		li {
			border-bottom: 1px solid #F2F2F2;
			padding: 14px 0;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			justify-content: space-between;
			-webkit-justify-content: space-between;
			-ms-flex-pack: space-between;
			p {
				font-weight: 500;
				font-size: 14px;
				color: $color_7;
				margin-bottom: 0;
				i {
					color: $color_112;
					margin-right: 5px;
					width: 15px;
				}
			}
		}
	}
}
.chat-media-title {
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
	margin: 18px 0 10px 0;
	h6 {
		font-weight: 500;
		font-size: 16px;
		margin-bottom: 10px;
	}
	span {
		font-size: 12px;
		color: $color_9;
		display: inline-block;
		margin-bottom: 10px;
	}
}
.photo-link {
	li {
		a {
			font-size: 12px;
			&:hover {
				color: $color_9;
			}
		}
	}
}
.photo-file {
	font-size: 12px;
	li {
		margin-bottom: 10px;
	}
}
.clear-list {
	ul {
		padding-top: 18px;
		li {
			font-weight: 500;
			font-size: 14px;
			color: $color_132;
			margin-bottom: 14px;
			&:last-child {
				margin-bottom: 0;
			}
			a {
				color: $color_132;
				i {
					margin-right: 10px;
				}
				&:hover {
					color: $color_9;
				}
			}
		}
	}
}
.service-wrap {
	margin-bottom: 30px;
	p {
		color: $color_1;
		&:last-child {
			margin-bottom: 0;
		}
	}
	h5 {
		font-weight: 600;
		font-size: 20px;
		font-family: $font-family_5;
		margin-bottom: 20px;
	}
	.owl-nav {
		button.owl-next {
			margin-bottom: 20px;
		}
		button.owl-prev {
			margin-bottom: 20px;
		}
	}
}
.provide-box {
	margin-bottom: 22px;
	display: flex;
	>span {
		width: 45px;
		height: 45px;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		align-items: center;
		justify-content: center;
		background: #F4F5F6;
		color: $color_7;
		border-radius: 50%;
		margin-right: 12px;
		flex-shrink: 0;
	}
	>img {
		width: 50px;
		height: 50px;
		border-radius: 50%;
		margin-right: 12px;
	}
}
.provide-service.service-wrap {
	margin-bottom: 15px;
}
.provide-social.social-icon {
	li {
		display: inline-block;
		margin-right: 7px;
		a {
			width: 36px;
			height: 36px;
			background: #F7F7FF;
			border-radius: 40px;
			display: -webkit-inline-box;
			display: -ms-inline-flexbox;
			display: inline-flex;
			border-radius: 50%;
			justify-content: center;
			align-items: center;
			color: $color_7;
			&:hover {
				background-color: $background-color_8;
				color: $color_15;
			}
		}
	}
}
.provide-info {
	h6 {
		font-weight: 500;
		font-size: 14px;
		margin-bottom: 4px;
	}
	p {
		font-size: 14px;
		color: $color_1;
		word-break: break-word;
		margin-bottom: 0;
	}
}
.card-provide {
	border: 2px solid #F5F5F5;
	border-radius: 10px;
	.card-body {
		padding: 15px;
	}
	.contact-map {
		display: block;
		width: 100%;
		height: 250px;
		border-radius: 10px;
		margin: 0;
	}
}
.provide-widget {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
	margin-bottom: 30px;
	.service-amount {
		margin-left: 0;
		line-height: 0;
		color: $color_7;
		h5 {
			font-weight: 600;
			font-size: 24px;
			margin-bottom: 7px;
		}
	}
}
.serv-proimg {
	position: relative;
	img {
		width: 61px;
		height: 61px;
		border-radius: 50%;
	}
	span {
		position: absolute;
		top: 0;
		right: 0;
		font-size: 15px;
		color: $color_134;
		background: #fff;
		border-radius: 50%;
		width: 22px;
		height: 22px;
		display: inline-block;
		text-align: center;
	}
}
.package-widget {
	background: #F7F7FF;
	border-radius: 8px;
	padding: 15px;
	margin-bottom: 20px;
	h5 {
		font-size: 18px;
		margin-bottom: 20px;
	}
	ul {
		li {
			position: relative;
			margin-bottom: 12px;
			padding-left: 24px;
			color: $color_1;
			display: flex;
			display: -webkit-flex;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			justify-content: space-between;
			-webkit-justify-content: space-between;
			-ms-flex-pack: space-between;
			&:last-child {
				margin-bottom: 0;
			}
			&:before {
				position: absolute;
				top: 0;
				left: 0;
				content: "\f058";
				font-weight: 900;
				font-family: $font-family_6;
				width: 100%;
				height: 100%;
				background-repeat: no-repeat;
				color: $color_9;
			}
		}
	}
}
.pack-service.package-widget {
	ul {
		li {
			padding-left: 0;
			&:before {
				content: none;
			}
		}
	}
}
.pack-service {
	.custom_check {
		.checkmark {
			border: 1px solid #C2C9D1;
			background: transparent;
			height: 15px;
			width: 15px;
		}
	}
}
.add-serv-info {
	overflow: hidden;
	h6 {
		font-size: 16px;
		font-weight: 500;
		color: $color_7;
		margin-bottom: 5px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	p {
		font-size: 12px;
		color: $color_1;
		margin-bottom: 0;
	}
}
.add-serv-item {
	overflow: hidden;
	display: flex;
	display: -webkit-flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.add-serving {
	width: calc(100% - 65px);
	display: flex;
	display: -webkit-flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.add-serv-img {
	margin-right: 8px;
	flex-shrink: 0;
	img {
		width: 48px;
		height: 48px;
		border-radius: 5px;
		flex-shrink: 0;
	}
}
.add-serv-amt {
	h6 {
		font-weight: 500;
		font-size: 16px;
		color: $color_7;
		margin-bottom: 0;
	}
}
.serv-profile {
	h2 {
		font-size: 38px;
		margin-bottom: 8px;
	}
	ul {
		li {
			font-size: 14px;
			color: $color_1;
			display: inline-block;
			margin-right: 10px;
		}
	}
	span.badge {
		font-weight: 500;
		font-size: 13px;
		background: #F7F7FF;
		color: $color_9;
		border-radius: 4px;
		margin-bottom: 8px;
		padding: 10px 17px;
		display: inline-block;
	}
}
.service-map {
	i {
		color: $color_135;
	}
}
.card-available {
	background: #F7F7FF;
	border-radius: 8px;
	border: 0;
	.card-body {
		padding: 15px;
	}
}
.available-info {
	ul {
		li {
			color: $color_7;
			font-weight: 500;
			font-size: 14px;
			margin-bottom: 14px;
			padding: 0;
			text-align: left;
			&:last-child {
				margin-bottom: 0;
			}
			span {
				float: right;
				color: $color_1;
			}
		}
	}
}
.big-gallery {
	a.btn-show {
		background: #fff;
		border-radius: 8px;
		color: $color_9;
		font-weight: 500;
		font-size: 13px;
		padding: 5px 12px;
		position: absolute;
		left: 20px;
		bottom: 20px;
	}
}
.gallery-widget {
	img {
		border-radius: 5px;
	}
}
.owl-nav {
	button.owl-next {
		width: 32px;
		height: 32px;
		background: #fff;
		box-shadow: 0px 4px 14px rgb(141 141 141 / 25%);
		text-shadow: none;
		top: 4px;
		cursor: pointer;
		border: 0;
		border-radius: 50%;
		color: $color_7;
		margin-bottom: 30px;
		transition: ease all 0.5s;
		-webkit-transition: ease all 0.5s;
		-ms-transition: ease all 0.5s;
		margin-left: 10px;
		&:hover {
			background-color: $background-color_51;
			color: $color_15;
		}
	}
	button.owl-prev {
		width: 32px;
		height: 32px;
		background: #fff;
		box-shadow: 0px 4px 14px rgb(141 141 141 / 25%);
		text-shadow: none;
		top: 4px;
		cursor: pointer;
		border: 0;
		border-radius: 50%;
		color: $color_7;
		margin-bottom: 30px;
		transition: ease all 0.5s;
		-webkit-transition: ease all 0.5s;
		-ms-transition: ease all 0.5s;
		&:hover {
			background-color: $background-color_51;
			color: $color_15;
		}
	}
}
#progressbar {
	margin-bottom: 30px;
	li {
		display: inline-flex;
		display: -webkit-inline-flex;
		align-items: center;
		-webkit-align-items: center;
		margin: 0 30px 20px 0;
		background: #FBFBFF;
		box-shadow: 0px 1px 2px rgba(55, 65, 81, 0.08);
		padding: 10px;
		position: relative;
		text-align: left;
		opacity: 0.8;
		&:last-child {
			margin-right: 0;
		}
		&::after {
			position: absolute;
			display: block;
			content: '';
			width: 0;
			height: 0;
			left: 100%;
			top: 0;
			border-left: 15px solid #FBFBFF;
			border-right: 15px solid transparent;
			border-top: 31px solid transparent;
			border-bottom: 30px solid transparent;
		}
	}
	li.active {
		background: #F7F7FF;
		opacity: 1;
		&::after {
			#progressbar {
				li.activated {
					&::after {
						border-left: 15px solid #F7F7FF;
					}
				}
			}
		}
		span {
			background: #4C40ED;
			color: $color_15;
		}
	}
	li.activated {
		background: #F7F7FF;
		opacity: 1;
	}
	.multi-step-info {
		margin-right: 10px;
		h6 {
			font-weight: 500;
			font-size: 14px;
			color: $color_7;
			margin-bottom: 2px;
		}
		p {
			font-size: 12px;
			color: $color_1;
			margin-bottom: 0;
		}
	}
	.multi-step-icon {
		span {
			width: 39px;
			height: 39px;
			background: #fff;
			border-radius: 50%;
			display: flex;
			display: -webkit-flex;
			align-items: center;
			-webkit-align-items: center;
			justify-content: center;
		}
	}
}
.schedule-nav {
	margin-bottom: 10px;
	ul {
		li {
			margin: 5px 15px 10px 0;
			display: inline-block;
			&:last-child {
				margin-right: 0;
			}
			a.active {
				background: #4C40ED;
				border: 1px solid #4C40ED;
				color: $color_15;
			}
			a {
				&:hover {
					background: #4C40ED;
					border: 1px solid #4C40ED;
					color: $color_15;
				}
			}
		}
		>li {
			>a {
				background: #F7F7FF;
				border: 1px solid #F7F7FF;
				border-radius: 4px;
				padding: 4px 15px;
				color: $color_9;
				font-weight: 500;
				font-size: 13px;
			}
		}
	}
}
.timepicker-sec {
	display: none;
}
.contact-map {
	border: 0;
	display: block;
	width: 100%;
	height: 450px;
	margin: 0;
}
.file-preview {
	ul {
		margin-bottom: 5px;
		li {
			display: inline-block;
			margin: 0 15px 15px 0;
			position: relative;
			h6 {
				font-weight: 400;
				font-size: 14px;
			}
			.custom_check {
				position: absolute;
				right: 0;
				padding-left: 28px;
				top: 5px;
			}
			a {
				position: absolute;
				top: 3px;
				right: 3px;
				color: $color_136;
			}
			img {
				width: 83px;
				height: 72px;
				object-fit: cover;
				border-radius: 5px;
			}
		}
	}
	.custom_check {
		input {
			&:checked {
				~ {
					.checkmark {
						background-color: $background-color_8;
						border-color: $border-color_1;
					}
				}
			}
		}
	}
}
.field-btns {
	margin-top: 20px;
	text-align: right;
	.btn {
		font-weight: 500;
		font-size: 14px;
		padding: 9px 24px;
	}
}
fieldset#first-field {
	display: block;
}
fieldset {
	display: none;
}
.form-duration {
	position: relative;
	.mins {
		position: absolute;
		background: #fff;
		border-radius: 6px;
		color: $color_9;
		font-weight: 500;
		font-size: 14px;
		padding: 9px 18px;
		top: 50%;
		transform: translateY(-50%);
		right: 4px;
	}
	.form-control {
		padding: 10px 70px 10px 15px;
	}
}
.sml-info {
	font-size: 12px;
	color: $color_107;
}
.authentication-buttons {
	.radiossets {
		.checkmark-radio {
			&::after {
				background: #00CC45;
			}
		}
		input {
			&:checked {
				~ {
					.checkmark-radio {
						border: 1px solid #00CC45;
					}
				}
			}
		}
	}
	.checkboxs {
		input {
			&:checked {
				~ {
					span {
						background: #00CC45;
					}
				}
			}
		}
	}
}
.bank-box {
	background: #F8F8FE;
	border: 1px solid #EBEBEB;
	border-radius: 10px;
	padding: 20px;
	position: relative;
}
.bank-header {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: space-between;
	margin-bottom: 30px;
}
.bank-name {
	h6 {
		font-weight: 500;
		font-size: 18px;
		margin-bottom: 10px;
	}
	p {
		color: $color_1;
		font-weight: 500;
		font-size: 14px;
		margin-bottom: 10px;
	}
}
.bank-info {
	p {
		font-size: 12px;
		color: $color_1;
		margin-bottom: 6px;
	}
	h6 {
		font-size: 14px;
		font-weight: 500;
		color: $color_7;
		margin-bottom: 10px;
	}
}
.bank-action {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: space-between;
	a {
		font-weight: 500;
		font-size: 14px;
		color: $color_9;
		i {
			margin-right: 5px;
		}
	}
}
.bank-box.active {
	border: 1px solid #00CC45;
	&:before {
		position: absolute;
		top: -5px;
		right: -5px;
		content: "\f058";
		font-family: $font-family_7;
		color: $color_72;
	}
}
.seo-list {
	margin-bottom: 20px;
	li {
		display: inline-block;
		margin-right: 10px;
		a.active {
			background: #F1F1F1;
		}
		a {
			display: inline-block;
			background: #FFFFFF;
			border: 2px solid #E1E2E5;
			border-radius: 6px;
			padding: 4px 26px;
			font-weight: 500;
			font-size: 15px;
			color: $color_7;
		}
	}
}
.location-set.soc-active {
	border: 2px solid #4C40ED;
}
// angular css
.table_footer {
  display: flex;
  justify-content: space-between;
  padding: 0rem 1.5rem;
}
.table_footer .dataTables_length label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: -24px;
}
.table_footer .dataTables_length select {
  width: auto;
  display: inline-block;
  margin: 0px 3px;
  -webkit-appearance: none;
  appearance: auto;
  border-radius: 0.375rem;
}
.table_design {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.table_design .pagination_section {
  display: flex;
  justify-content: flex-end;
}
.pagination-main-class {
  display: flex;
  justify-content: space-between;
}
.mat-mdc-select-arrow {
	color: #9597a9;
  }
// angular css
@media only screen and (min-width: 992px) {
	.header.fixed-header {
		#toggle_btn {
			align-items: center;
			color: $color_15;
			display: inline-flex;
			float: left;
			font-size: 30px;
			height: 60px;
			margin-left: 15px;
			justify-content: center;
			padding: 0 20px;
		}
	}
	.mini-sidebar {
		.header-left {
			.logo {
				img {
					height: auto;
					max-height: 24px;
					width: auto;
				}
			}
			.logo.logo-small {
				display: block;
			}
		}
		.header {
			.header-left {
				.logo {
					display: none;
				}
				padding: 0 5px;
				width: 90px;
			}
		}
		.sidebar {
			width: 90px;
			.sidebar-menu {
				ul {
					>li {
						>a {
							span {
								display: none;
								transition: all 0.2s ease-in-out;
								opacity: 0;
							}
						}
					}
				}
			}
		}
		.sidebar-menu {
			padding: 10px;
			>ul {
				>li {
					>a {
						border-radius: 10px;
						padding: 10px 20px;
					}
				}
			}
		}
		.menu-title {
			visibility: hidden;
			white-space: nowrap;
			a {
				visibility: hidden;
			}
		}
		.page-wrapper {
			margin-left: 90px;
		}
		&.main-wrapper {
			.header {
				left: 90px;
			}
		}
		.header.fixed-header {
			left: 90px;
		}
	}
	.mini-sidebar.expand-menu {
		.sidebar {
			width: 270px;
			.sidebar-menu {
				ul {
					>li {
						>a {
							span {
								display: inline;
								opacity: 1;
							}
						}
					}
				}
			}
		}
		.menu-title {
			visibility: visible;
			a {
				visibility: visible;
			}
		}
		.page-wrapper {
			margin-left: 270px;
		}
		&.main-wrapper {
			.header {
				left: 270px;
			}
			.header.fixed-header {
				left: 270px;
			}
		}
	}
	.modal-open.mini-sidebar {
		.sidebar {
			z-index: 1051;
		}
	}
}
@media only screen and (max-width: 1399.98px) {
	.login-logo {
		margin-bottom: 40px;
	}
	.chat-cont-left {
		.chat-users-list {
			a.media {
				.media-body {
					>div {
						&:first-child {
							.user-name {
								max-width: 65px;
							}
							.user-last-chat {
								max-width: 65px;
							}
						}
					}
				}
			}
		}
		.chat-scroll {
			max-height: calc(100vh - 318px);
		}
	}
}
@media only screen and (max-width: 1199.98px) {
	.app-listing {
		flex-wrap: wrap;
		li {
			width: 50%;
		}
	}
	.invoices-tabs {
		ul {
			li {
				a.active {
					border-bottom: 0;
				}
			}
		}
	}
}
@media only screen and (max-width: 991.98px) {
	.header {
		.header-left {
			position: absolute;
			width: 100%;
			display: block;
			height: auto;
			top: 20px;
			left: 0;
			.logo {
				display: none;
				img {
					max-height: 36px;
					height: 36px;
				}
			}
		}
	}
	.header.fixed-header {
		.mobile_btn {
			color: $color_15;
		}
	}
	.hidden-web {
		display: none;
	}
	.connectetapps {
		flex-direction: column;
		padding: 10px;
		border-bottom: 1px solid #eee;
		margin: 0 0 10px;
		align-items: start;
	}
	.connectetappscontent {
		margin-top: 15px;
	}
	.mobile_btn {
		color: $color_8;
		cursor: pointer;
		display: flex;
		font-size: 26px;
		height: 60px;
		left: 0;
		padding: 0 15px;
		position: absolute;
		text-align: center;
		top: 5px;
		z-index: 10;
		justify-content: center;
		align-items: center;
	}
	.home-head-user {
		h2 {
			font-size: 18px;
		}
	}
	#toggle_btn {
		display: none;
	}
	.header-left {
		.logo.logo-small {
			display: block;
		}
	}
	.main-wrapper {
		.header {
			left: 0;
		}
		.header.fixed-header {
			left: 0;
		}
	}
	.animate-circle {
		border: 0px solid #4C40ED;
	}
	.user-img {
		width: 42px;
		height: 42px;
	}
	.logo-small {
		display: block;
	}
	.flag-nav {
		display: none;
	}
	.user-menu.nav {
		>li {
			>a.viewsite {
				display: none;
			}
		}
	}
	.dropdown-heads {
		margin: 0 5px;
		display: none;
	}
	.page-headers {
		display: none;
	}
	.header-split {
		justify-content: flex-end;
	}
	.sidebar {
		margin-left: -270px;
		width: 270px;
		-webkit-transition: all 0.4s ease;
		-moz-transition: all 0.4s ease;
		transition: all 0.4s ease;
		z-index: 1041;
	}
	.page-wrapper {
		margin-left: 0;
		padding-left: 0;
		padding-right: 0;
		-webkit-transition: all 0.4s ease;
		-moz-transition: all 0.4s ease;
		transition: all 0.4s ease;
	}
	.nav-tabs.menu-tabs {
		.nav-item {
			padding-right: 1.25rem;
		}
	}
	.view-btn {
		display: none;
	}
	.select-by {
		display: none;
	}
	.app-listing {
		flex-wrap: wrap;
		li {
			width: 50%;
		}
	}
	.report-list {
		.app-listing {
			justify-content: flex-start;
			li {
				width: 33%;
			}
		}
		.multiple-selection {
			#checkboxes {
				right: 0;
				left: 0 !important;
				width: 100%;
			}
		}
	}
	.sortby {
		#checkbox {
			right: 0;
			left: 0 !important;
			width: 100%;
		}
	}
	.invoice-item {
		.invoice-logo {
			img {
				width: 200px;
			}
		}
	}
	.invoice-head {
		p {
			font-size: 16px;
		}
		h2 {
			font-weight: 600;
			font-size: 20px;
		}
	}
	.invoice-item-bg {
		.invoice-info {
			padding: 20px;
			margin-top: 0px;
			margin-bottom: 0;
		}
	}
	.invoice-payment-box {
		.payment-details {
			width: 100%;
			margin-bottom: 15px;
		}
	}
	.links-cont {
		.form-control {
			margin-bottom: 15px;
		}
	}
	.countset {
		.form-control {
			margin-bottom: 15px;
		}
		.socail-links-set {
			margin-bottom: 15px;
		}
	}
	.links-conts {
		.form-control {
			margin-bottom: 15px;
		}
	}
	.invoices-tabs {
		ul {
			li {
				a.active {
					border-bottom: 0;
				}
			}
		}
	}
	.sidebar-logo {
		.logo-small {
			display: none;
		}
	}
	.content-table {
		padding: 15px 30px;
		padding-right: 15px;
	}
	.content-page-headersplit {
		flex-direction: column;
	}
	.content-page-header {
		h5 {
			font-size: 18px;
		}
	}
	.page-head-btn {
		padding-top: 15px;
	}
	.change-password {
		display: block;
	}
	.login-pages {
		padding: 30px 15px;
	}
	.login-images {
		display: none;
	}
	.login-logo {
		margin-bottom: 30px;
		text-align: center;
	}
	.login-content {
		padding-left: 0px;
	}
	.login-contenthead {
		h5 {
			font-size: 24px;
		}
	}
	.login-input {
		.form-control {
			height: 40px;
		}
	}
	.content-page-header.content-page-headersplit {
		h5 {
			margin-bottom: 15px;
		}
	}
	.tab-contents-count {
		display: none;
	}
	.tab-contents-sets {
		ul {
			flex-wrap: wrap;
			li {
				a.active {
					&::after {
						height: 0;
					}
				}
				a {
					&:hover {
						&::after {
							height: 0;
						}
					}
				}
			}
		}
	}
	.list-btn {
		>ul {
			flex-wrap: wrap;
			justify-content: center;
			>li {
				margin: 5px;
			}
		}
	}
	.chat-cont-left {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
		transition: all .4s;
		width: 100%;
		max-height: 100%;
		z-index: 9;
		.chat-scroll {
			max-height: calc(100vh - 292px);
		}
	}
	.chat-cont-right {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
		transition: all .4s;
		width: 100%;
		max-height: 100%;
		position: absolute;
		right: -100%;
		opacity: 0;
		visibility: hidden;
		z-index: 99;
		.chat-header {
			justify-content: start;
			-webkit-justify-content: start;
			-ms-flex-pack: start;
		}
		.msg_head {
			.back-user-list {
				display: flex;
				align-items: center;
			}
			.chat-options {
				margin-left: auto;
			}
		}
		.chat-scroll {
			max-height: calc(100vh - 257px);
		}
	}
	.chat-window.chat-slide {
		.chat-cont-right {
			right: 0;
			opacity: 1;
			visibility: visible;
		}
	}
	.chat-cont-profile {
		z-index: 9999;
	}
	.msg_card_body {
		.media.sent {
			.media-body {
				.msg-box {
					padding-left: 0px;
				}
			}
		}
	}
	.chat-options {
		ul {
			li {
				a {
					width: 20px;
					height: 20px;
				}
			}
		}
	}
	.serv-profile {
		h2 {
			font-size: 32px;
		}
	}
}
@media only screen and (max-width: 767.98px) {
	body {
		font-size: 14px;
	}
	h1 {
		font-size: 2rem;
	}
	.h1 {
		font-size: 2rem;
	}
	h2 {
		font-size: 1.75rem;
	}
	.h2 {
		font-size: 1.75rem;
	}
	h3 {
		font-size: 1.5rem;
	}
	.h3 {
		font-size: 1.5rem;
	}
	h4 {
		font-size: 1.125rem;
	}
	.h4 {
		font-size: 1.125rem;
	}
	h5 {
		font-size: 1rem;
	}
	.h5 {
		font-size: 1rem;
	}
	h6 {
		font-size: 0.875rem;
	}
	.h6 {
		font-size: 0.875rem;
	}
	.header {
		.has-arrow {
			.dropdown-toggle {
				&:after {
					display: none;
				}
			}
		}
		.header-left {
			padding: 0 15px;
			.logo {
				display: none;
			}
		}
	}
	.user-menu.nav {
		>li {
			>a {
				>span {
					&:not(.user-img) {
						display: none;
					}
				}
			}
		}
	}
	.navbar-nav {
		.open {
			.dropdown-menu {
				float: left;
				position: absolute;
			}
		}
	}
	.navbar-nav.user-menu {
		.open {
			.dropdown-menu {
				left: auto;
				right: 0;
			}
		}
	}
	.header-left {
		.logo.logo-small {
			display: inline-block;
		}
	}
	.login-wrapper {
		.loginbox {
			max-width: 450px;
			min-height: unset;
		}
	}
	.page-settings {
		flex-direction: column;
	}
	.content-sidelink {
		width: 100%;
		padding: 15px;
	}
	.error-box {
		h1 {
			font-size: 6em;
		}
		.btn {
			font-size: 15px;
			min-width: 150px;
			padding: 8px 20px;
		}
	}
	.dash-count {
		font-size: 16px;
	}
	.nav-tabs.menu-tabs {
		margin-top: -15px;
		.nav-item {
			margin-top: 20px;
		}
	}
	.settings-btns {
		.btn-grey {
			font-size: 13px;
		}
		.btn-orange {
			font-size: 13px;
			margin-right: 10px;
		}
	}
	.label {
		display: inline-block;
		padding: 0.35em 0.65em;
		font-size: .75em;
		font-weight: 700;
		line-height: 1;
		color: $color_15;
		text-align: center;
		white-space: nowrap;
		vertical-align: baseline;
		border-radius: 0.25rem;
	}
	.app-listing {
		flex-wrap: wrap;
		li {
			width: 100%;
		}
	}
	.invoices-tabs {
		margin-bottom: 15px;
		ul {
			li {
				margin-right: 25px;
				a.active {
					border-bottom: 0;
				}
			}
		}
	}
	.report-list {
		.app-listing {
			display: block;
			margin: 0;
			li {
				width: 100%;
				padding: 0;
			}
		}
	}
	.field-box {
		.btn {
			min-width: 100%;
			padding: 10px 0;
		}
	}
	.service-upload {
		width: 100%;
	}
	.invoice-total-footer {
		h4 {
			font-size: 16px;
			color: $color_9;
		}
	}
	.invoice-inner-footer {
		.form-control {
			position: relative;
		}
	}
	.invoices-create-btn {
		margin-top: 10px;
		.btn {
			font-size: 14px;
			padding: 8px 0px;
			min-width: 115px;
			margin-bottom: 10px;
		}
	}
	.invoice-setting-btn {
		.btn {
			min-width: 145px;
			padding: 7px 0;
			font-size: 15px;
			margin-bottom: 10px;
		}
		.cancel-btn {
			margin-right: 0 !important;
		}
	}
	.invoice-item {
		.invoice-logo {
			img {
				width: 100%;
			}
		}
	}
	.invoice-head {
		p {
			font-size: 15px;
		}
		h2 {
			font-weight: 600;
			font-size: 20px;
		}
	}
	.invoice-item-bg {
		.invoice-info {
			padding: 20px;
			margin-top: 0px;
			margin-bottom: 0;
		}
	}
	.invoice-payment-box {
		.payment-details {
			width: 100%;
			margin-bottom: 15px;
		}
	}
	.invoice-info-card {
		.card-body {
			padding: 10px;
		}
	}
	.invoice-total-box {
		p {
			font-size: 14px;
		}
	}
	.pagination-tab {
		padding: 10px;
	}
	.pagination {
		flex-wrap: wrap;
		.page-link {
			font-size: 14px;
		}
	}
	.links-cont {
		.form-control {
			margin-bottom: 15px;
		}
	}
	.countset {
		.form-control {
			margin-bottom: 15px;
		}
		.socail-links-set {
			margin-bottom: 15px;
		}
	}
	.links-conts {
		.form-control {
			margin-bottom: 15px;
		}
	}
	.invoices-settings-btn {
		.btn {
			padding: 8px 0;
			min-width: 155px;
		}
	}
	.inovices-widget-header {
		.inovices-amount {
			font-size: 20px;
		}
	}
	.invoice-inner-head {
		padding: 15px;
	}
	.invoice-inner-date {
		padding: 15px;
	}
	.invoice-inner-date.invoice-inner-datepic {
		padding-left: 15px;
	}
	.serv-profile {
		h2 {
			font-size: 20px;
		}
	}
	.service-images {
		margin-bottom: 20px;
	}
}
@media only screen and (max-width: 575.98px) {
	.main-wrapper {
		.header {
			right: 0;
			top: 0;
			left: 0;
			padding: 10px 0;
			height: 60px;
		}
		.header.fixed-header {
			right: 35px;
			top: 0;
			left: 0;
			width: 100%;
		}
	}
	.tab-contents-sets {
		ul {
			li {
				width: 50%;
				padding: 0 !important;
				text-align: center;
				a {
					padding: 10px;
					display: block;
				}
			}
		}
	}
	.list-btn {
		.btn-filters {
			font-size: 15px;
			width: 35px;
			height: 35px;
		}
	}
	.location-setcontent {
		h6 {
			margin-bottom: 10px;
		}
	}
	.page-wrapper {
		padding-top: 60px;
		>.content {
			padding: 0.9375rem 0.9375rem 0;
		}
	}
	.bg-danger-light {
		width: 100%;
	}
	.form-groupbtn {
		.btn {
			min-width: auto;
			padding: 7px 15px;
			font-size: 16px;
			margin-bottom: 15px;
		}
	}
	li.nav-item.dropdown.noti-dropdown {
		display: none;
	}
	.content-page-header {
		h5 {
			display: block;
			font-size: 18px;
		}
		margin-bottom: 20px;
	}
	.card {
		margin-bottom: 0.9375rem;
	}
	.card-body {
		padding: 1.25rem;
	}
	.card-header {
		padding: .75rem 1.25rem;
	}
	.card-footer {
		padding: .75rem 1.25rem;
	}
	.page-header {
		margin-bottom: 0.9375rem;
	}
	.loginbox {
		padding: 1.25rem;
	}
	.notifications {
		.noti-content {
			width: 300px;
		}
	}
	.filter-btn {
		width: 40px;
		height: 40px;
		font-size: 14px;
	}
	.add-button {
		width: 40px;
		height: 40px;
		font-size: 14px;
	}
	.inactive-style {
		margin-top: 15px;
	}
	.profile-upload {
		flex-direction: column;
	}
	.profile-upload-img {
		padding-right: 0;
		padding-bottom: 15px;
	}
	#progressbar {
		li {
			margin-right: 0;
			width: calc(100% - 18px);
			justify-content: space-between;
			-webkit-justify-content: space-between;
			-ms-flex-pack: space-between;
		}
	}
	.schedule-nav {
		ul {
			li {
				display: block;
				-ms-flex: 0 0 100%;
				flex: 0 0 100%;
				margin-right: 0;
			}
		}
	}
}
